import queryString from 'query-string';
import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Error404 from '../../components/Error404';
import MainNavMenu from '../../components/MainNavMenu';
import ScrollToTop from '../../components/ScrollToTop';
import { getMyHome } from '../../shared/my-home';
import useProfile from '../../shared/use-profile';
import useProfileUpdates from '../../shared/use-profile-updates';
import useQueryParams from '../../shared/use-query-params';
import EditProfilePage from '../auth/EditProfilePage';
import ViewProfilePage from '../auth/ViewProfilePage';
import AllHolisticProjectsPage from '../holistic-projects/AllHolisticProjectsPage';
import ProjectLoanAgreementPage from '../holistic-projects/ProjectLoanAgreementPage';
import ViewHolisticProject from '../holistic-projects/ViewHolisticProject';
import AllHolisticProposalsPage from '../holistic-projects/proposals/AllHolisticProposalsPage';
import EditProposal from '../holistic-projects/proposals/EditProposal';
import ViewProposalPage from '../holistic-projects/proposals/ViewProposalPage';
import EditHolisticQuarterlyReportPage from '../holistic-projects/reports/EditHolisticQuarterlyReportPage';
import ViewHolisticQuarterlyReportPage from '../holistic-projects/reports/ViewHolisticQuarterlyReportPage';
import LoanAgreementPage from '../loan-agreements/LoanAgreementPage';
import ListMousPage from '../mous/ListMousPage';
import MouPage from '../mous/MouPage';
import PartnerDashboard from '../partner/PartnerDashboard';
import AdoptAPeopleGroup from '../partner/adopt-a-upg/AdoptAPeopleGroup';
import AdoptAPgSuccess from '../partner/adopt-a-upg/AdoptAPgSuccess';
import AdoptAUpgRedirectToPP from '../partner/adopt-a-upg/AdoptAUpgRedirectToPP';
import SponsorAProject from '../partner/sponsor-a-project/SponsorAProject';
import SponsorAProjectSuccess from '../partner/sponsor-a-project/SponsorAProjectSuccess';
import MyPeopleGroups from '../people-groups/MyPeopleGroups';
import PeopleGroupDetails from '../people-groups/PeopleGroupDetails';
import EditQuarterlyReport from '../people-groups/reports/EditQuarterlyReport';
import ViewQuarterlyReport from '../people-groups/reports/ViewQuarterlyReport';
import Training from '../training';
import ViewTrainingCourse from '../training/courses/ViewCourse/ViewCourse';
import EditTrainingEventReport from '../training/event-reports/EditTrainingEventReport';
import ViewTrainingEventReport from '../training/event-reports/ViewTrainingEventReport';
import EditTrainingQuarterlyReport from '../training/quarterly-reports/EditTrainingReport';
import ViewTrainingQuarterlyReport from '../training/quarterly-reports/ViewTrainingReport';
import EmailVerified from '../verify-email/EmailVerified';
import VerifyEmail from '../verify-email/VerifyMyEmail';
import TrainingReports from '../training/TrainingReports';
import AllLoanAgreementsPage from '../loan-agreements/AllLoanAgreementsPage';

function MainRouter() {
  useProfileUpdates();
  const profile = useProfile();
  const query = useQueryParams();
  const forwardAfterLogin = useCallback(() => {
    const { p, s, c } = query;
    let search = '';
    if (s) {
      try {
        const params = JSON.parse(s);
        search = `?${queryString.stringify({ ...params, c })}`;
      } catch (err) {
        console.error(err);
      }
    }
    if (p) {
      return <Redirect to={`${p}${search}`} />;
    }
    const myHome = getMyHome(profile?.permissions);
    return <Redirect to={myHome} />;
  }, [query, profile]);

  const forwardFromHome = useCallback(() => {
    const myHome = getMyHome(profile?.permissions);
    return <Redirect to={myHome} />;
  }, [profile]);

  return (
    <MainNavMenu>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" render={forwardAfterLogin} />
          <Route exact path="/sign-(in|up)" render={forwardAfterLogin} />
          <Route exact path="/home" render={forwardFromHome} />
          <Route exact path="/upgs" component={MyPeopleGroups} />
          <Route exact path="/upgs/:_id/:tab" component={PeopleGroupDetails} />
          <Route
            exact
            path="/upgs/:peopleGroupId/reports/view/:reportId"
            component={ViewQuarterlyReport}
          />
          <Route
            exact
            path="/upgs/:peopleGroupId/reports/edit/:reportId"
            component={EditQuarterlyReport}
          />
          <Route exact path="/training/:tab" component={Training} />
          <Route
            exact
            path="/training/courses/view/:_id"
            component={ViewTrainingCourse}
          />
          <Route
            exact
            path="/training-reports/:tab"
            component={TrainingReports}
          />
          <Route
            exact
            path="/training-reports/event/view/:reportId"
            component={ViewTrainingEventReport}
          />
          <Route
            exact
            path="/training-reports/event/edit/:reportId"
            component={EditTrainingEventReport}
          />
          <Route
            exact
            path="/training-reports/quarterly/view/:reportId"
            component={ViewTrainingQuarterlyReport}
          />
          <Route
            exact
            path="/training-reports/quarterly/edit/:reportId"
            component={EditTrainingQuarterlyReport}
          />
          <Route
            exact
            path="/holistic-projects"
            component={AllHolisticProjectsPage}
          />
          <Route
            exact
            path="/holistic-projects/:projectId/loan-agreement/view/:loanAgreementId"
            component={ProjectLoanAgreementPage}
          />
          <Route
            exact
            path="/loan-agreements"
            component={AllLoanAgreementsPage}
          />
          <Route
            exact
            path="/holistic-projects/:_id/:tab"
            component={ViewHolisticProject}
          />
          <Route
            exact
            path="/holistic-projects/:projectId/reports/view/:reportId"
            component={ViewHolisticQuarterlyReportPage}
          />
          <Route
            exact
            path="/holistic-projects/:projectId/reports/edit/:reportId"
            component={EditHolisticQuarterlyReportPage}
          />
          <Route
            exact
            path="/holistic-proposals"
            component={AllHolisticProposalsPage}
          />
          <Route
            exact
            path="/holistic-proposals/view/:reportId"
            component={ViewProposalPage}
          />
          <Route
            exact
            path="/holistic-proposals/edit/:reportId"
            component={EditProposal}
          />
          <Route exact path="/my-profile" component={ViewProfilePage} />
          <Route exact path="/my-profile/edit" component={EditProfilePage} />
          <Route exact path="/verify-my-email" component={VerifyEmail} />
          <Route exact path="/email-verified" component={EmailVerified} />
          <Route exact path="/partner" component={PartnerDashboard} />
          <Route
            exact
            path="/partner/adopt-a-upg/success"
            component={AdoptAPgSuccess}
          />
          <Route
            exact
            path="/partner/adopt-a-upg/:pgId?"
            component={AdoptAPeopleGroup}
          />
          <Route
            exact
            path="/partner/adopt-a-upg/:pgId/adopt"
            component={AdoptAPeopleGroup}
          />
          <Route
            exact
            path="/partner/adopt-a-upg/:pgId/redirect"
            component={AdoptAUpgRedirectToPP}
          />
          <Route
            exact
            path="/partner/sponsor-a-project/success"
            component={SponsorAProjectSuccess}
          />
          <Route
            exact
            path="/partner/sponsor-a-project/:projectId?/:page?"
            component={SponsorAProject}
          />
          <Route
            exact
            path="/loan-agreement/sign/:loanAgreementId"
            component={LoanAgreementPage}
          />
          <Route exact path="/mous" component={ListMousPage} />
          <Route exact path="/mous/view/:mouId" component={MouPage} />
          <Route exact path="/mou/sign/:mouId" component={MouPage} />
          <Route component={Error404} />
        </Switch>
      </ScrollToTop>
    </MainNavMenu>
  );
}

export default MainRouter;
