import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../../reports/constants';

const TrainingQuarterlyReportFullAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      fullName
    }
    regionCode
    regionName
    reportType
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    notes
    submittedAt
    createdBy
    createdByUser ${ReportUserShortAttributes}
    isSubmitted
    submittedByUser ${ReportUserShortAttributes}
    isApproved
    status
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    events {
      _id
      city
      country
      host
      date
      numPeopleTrained
    }
    miracles {
      _id
      desc
    }
    prayerRequests {
      _id
      desc
    }
  }
`;

const query = gql`
  query TrainingQuarterlyReportForUser($_id: ID!) {
    trainingQuarterlyReportForUser(_id: $_id) ${TrainingQuarterlyReportFullAttributes}
  }
`;

const extract = (data) => data?.trainingQuarterlyReportForUser;

const TrainingQuarterlyReportUpdateAttributes = gql`
{
  crud
  id
  new ${TrainingQuarterlyReportFullAttributes}
}
`;

export const trainingQuarterlyReportUpdatesSubscription = gql`
  subscription TrainingQuarterlyReportUpdatesForUser($_id: ID!) {
    trainingQuarterlyReportUpdatesForUser(_id: $_id) ${TrainingQuarterlyReportUpdateAttributes}
  }
`;

function useReport(
  _id,
  queryId = 'useTrainingQuarterlyReport',
  showError = true,
) {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    queryId,
    showError,
  });
  const report = useSingleSomethingA10('reports', _id, queryId);

  return {
    report,
    loading,
    refetch,
  };
}

export default useReport;
