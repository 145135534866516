import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ListTrainingEventReports from './event-reports/ListTrainingEventReports';
import useCanCreateTrainingEventReport from './event-reports/use-can-create-report';
import ListTrainingQuarterlyReports from './quarterly-reports/ListTrainingQuarterlyReports';
import useCanCreateTrainingQuarterlyReport from './quarterly-reports/use-can-create-report';

const TabLabels = {
  event: 'Event Reports',
  quarterly: 'Quarterly Reports',
};

function TrainingReports() {
  const history = useHistory();
  const { tab } = useParams();

  const { result: canCreateEventReports } = useCanCreateTrainingEventReport();
  const { result: canCreateQuarterlyReports } =
    useCanCreateTrainingQuarterlyReport();
  return (
    <>
      <CSPage title={`AIMS.org | Training ${TabLabels[tab]}`}>
        <CSPageHeader
          titleComponent={null}
          title={TabLabels[tab]}
          parents={[
            {
              label: 'Training',
            },
          ]}
        />
        <Tabs
          defaultActiveKey="event"
          activeKey={tab}
          onChange={(key) => history.push(`/training-reports/${key}`)}
        >
          {canCreateEventReports && (
            <Tabs.TabPane tab="Training Event Reports" key="event">
              <ListTrainingEventReports />
            </Tabs.TabPane>
          )}
          {canCreateQuarterlyReports && (
            <Tabs.TabPane tab="Training Quarterly Reports" key="quarterly">
              <ListTrainingQuarterlyReports />
            </Tabs.TabPane>
          )}
        </Tabs>
      </CSPage>
    </>
  );
}

export default TrainingReports;
