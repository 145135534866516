import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  message,
  notification,
  Skeleton,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import settings from '../../settings';
import {
  AgreementStatuses,
  initialLoanAgreementSectionMutation,
  signLoanAgreementMutation,
} from './constants';
import triangleLogo from '../../assets/white-square-logo.png';
import { BehaviorSubject, of } from 'rxjs';
import { concatMap, delay, mergeMap } from 'rxjs/operators';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import PartiesElement from './PartiesElement';
import SectionsElement from './SectionsElement';
import AttachmentsElement from './AttachmentsElement';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { updateLoanAgreementAction } from '../../redux-store/loan-agreements-store';
import SignLoanAgreementModal from './SignLoanAgreementModal';
import InitialLoanAgreementModal from './InitialLoanAgreementModal';

const { Title, Text, Paragraph } = Typography;

function ProjectDetails({ project }) {
  const projectType = HolisticProjectTypes[project && project.typeOfProject];
  return (
    <div style={{ textAlign: 'left' }}>
      <Divider />
      {project && (
        <>
          <div style={{ fontSize: 16, color: settings.colors.textGray }}>
            Holistic Project
          </div>
          <Title level={2} style={{ margin: 0 }}>
            {project.projectName}
          </Title>
          {projectType && <div>{projectType.label}</div>}
          <div
            style={{
              marginTop: 8,
              marginBottom: 0,
              fontSize: 16,
              color: settings.colors.textGray,
            }}
          >{`Loan Amount: ${displayMoney(project.loanTotal)}`}</div>
          <div
            style={{
              marginTop: 8,
              marginBottom: 16,
              fontSize: 16,
              color: settings.colors.textGray,
              lineHeight: 1,
            }}
          >{`Loan Term: ${getNumberValue(project.loanTerm)} Months`}</div>
          <Title level={4}>Beneficiaries</Title>
          {project.supportedFieldWorkers &&
            project.supportedFieldWorkers
              .map((fw) => fw.contactName)
              .filter((n) => n)
              .join(', ')}
        </>
      )}
      {!project && (
        <div
          style={{
            marginTop: 8,
            marginBottom: 16,
            fontSize: 16,
            color: settings.colors.textGray,
            lineHeight: 1,
          }}
        >{`Missing Project Details`}</div>
      )}
      <Divider />
    </div>
  );
}

function OneLineElement({ label, text, noLabel, attachments, parties }) {
  const displayText = useMemo(() => {
    if (text) {
      let edited = text;
      const refs = text.matchAll(/{{(.*)}}/g);
      let ref = refs.next();
      while (!ref.done) {
        const party = parties && parties.find((a) => a.ref === ref.value[1]);
        if (party) {
          edited = text.replace(ref.value[0], `<Party: ${party.name}>`);
        }
        const attachment =
          attachments && attachments.find((a) => a.ref === ref.value[1]);
        if (attachment) {
          edited = text.replace(ref.value[0], `Attachment: ${attachment.name}`);
        }
        ref = refs.next();
      }
      return edited;
    }
    return noLabel;
  }, [text, noLabel, attachments, parties]);

  return (
    <div className="line-heading">
      <div style={{ marginRight: 32 }}>
        <div>
          <Text
            style={{ marginRight: 32, color: settings.colors.primary }}
          >{`${label}:`}</Text>
        </div>
        <div>
          <Text>{displayText}</Text>
        </div>
      </div>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;
        }
      `}</style>
    </div>
  );
}

function ViewLoanAgreement({ loanAgreement, queryId, refetch }) {
  const saveQueue$ = useRef();
  const handleSave = useCallback((values) => {
    saveQueue$.current.next(values);
  }, []);
  const [error, setError] = useState();

  const running = useRef(true);
  const currentAgreement = useRef();
  useEffect(() => {
    currentAgreement.current = loanAgreement;
  }, [loanAgreement]);
  const [signLoanAgreement] = useMutation(signLoanAgreementMutation);
  const [initialLoanAgreementSection] = useMutation(
    initialLoanAgreementSectionMutation,
  );
  useEffect(() => {
    async function doSave(_values) {
      if (_values) {
        try {
          if (currentAgreement.current) {
            const { action, ...values } = values;
            if (action === 'sign') {
              await signLoanAgreement({
                variables: {
                  signature: {
                    loanAgreementId: currentAgreement.current._id,
                    signature: {
                      partyId: values.partyId,
                      signature: values.signature,
                      date: values.date,
                      fontName: values.fontName,
                    },
                  },
                },
              });
              refetch();
              notification.success({
                message: 'Success',
                description: 'Loan Agreement Signed',
              });
            } else if (action === 'initial') {
              await initialLoanAgreementSection({
                variables: {
                  initials: {
                    loanAgreementId: currentAgreement.current._id,
                    initials: {
                      sectionId: values.sectionId,
                      partyId: values.partyId,
                      initials: values.initials,
                      fontName: values.fontName,
                      date: values.date,
                    },
                  },
                },
              });
              refetch();
              notification.success({
                message: 'Success',
                description: 'Initials Saved',
              });
            }
          }
        } catch (err) {
          console.error(err);
          message.error(err.message);
        }
      }
    }
    saveQueue$.current = new BehaviorSubject();
    saveQueue$.current.pipe(concatMap((values) => doSave(values))).subscribe();
    return () => (running.current = false);
  }, [signLoanAgreement, initialLoanAgreementSection, refetch]);

  const status = AgreementStatuses[loanAgreement.status];
  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <>
      <div
        id="loanAgreement"
        style={{
          backgroundColor: 'white',
          padding: '32px 16px',
          boxShadow: `0px 0px 4px ${settings.colors.primary}`,
          borderRadius: 2,
        }}
      >
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div
            style={{
              background: settings.colors.primary,
              width: 180,
              marginRight: 32,
              marginLeft: -16,
              marginTop: -32,
              padding: 16,
            }}
          >
            <img
              style={{ width: '100%' }}
              src={triangleLogo}
              alt="Triangle Logo"
            />
          </div>
          <Title>
            Holistic Project
            <br />
            Loan Commitment
          </Title>
        </div>
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <div style={{ fontSize: 18, color: settings.colors.textGray }}>
            Status
          </div>
          {status && (
            <div
              style={{
                color: settings.colors[status.color],
                fontSize: 24,
                margin: 0,
                fontWeight: 600,
              }}
            >
              {status.label}
            </div>
          )}
          <div
            style={{ fontSize: 16 }}
          >{`Version ${loanAgreement.version}`}</div>
        </div>
        <ProjectDetails project={loanAgreement.frozenProjectDetails} />
        <OneLineElement
          label="Country"
          noLabel="No Country"
          text={loanAgreement && loanAgreement.countryName}
          initialValues={{ country: loanAgreement.country }}
          parties={loanAgreement && loanAgreement.parties}
          attachments={loanAgreement && loanAgreement.attachments}
        />
        <OneLineElement
          label="Approval Date"
          noLabel="Not Specified"
          text={
            loanAgreement &&
            loanAgreement.approvalDate &&
            `${new Intl.DateTimeFormat(locale, {
              dateStyle: 'long',
            }).format(new Date(loanAgreement.approvalDate))}`
          }
          parties={loanAgreement && loanAgreement.parties}
          attachments={loanAgreement && loanAgreement.attachments}
        />
        <OneLineElement
          label="Finalized Date"
          noLabel="Not Specified"
          text={
            loanAgreement &&
            loanAgreement.finalizedDate &&
            `${new Intl.DateTimeFormat(locale, {
              dateStyle: 'long',
            }).format(new Date(loanAgreement.finalizedDate))}`
          }
          parties={loanAgreement && loanAgreement.parties}
          attachments={loanAgreement && loanAgreement.attachments}
        />
        <Divider />
        <PartiesElement loanAgreement={loanAgreement} />
        <SectionsElement
          loanAgreement={loanAgreement}
          queryId={queryId}
          InitialModal={InitialLoanAgreementModal}
        />
        <AttachmentsElement
          loanAgreementId={loanAgreement._id}
          loanAgreement={loanAgreement}
        />
        <PartiesElement
          loanAgreement={loanAgreement}
          signing
          queryId={queryId}
          SignatureModal={SignLoanAgreementModal}
        />
      </div>
    </>
  );
}

export default ViewLoanAgreement;
