import React from 'react';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import ProposalList from './ProposalList';

function AllHolisticProposalsPage() {
  return (
    <CSPage title="AIMS.org | Holistic Project Proposals">
      <CSPageHeader titleComponent={null} title="Holistic Project Proposals" />
      <ProposalList />
    </CSPage>
  );
}

export default AllHolisticProposalsPage;
