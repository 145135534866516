import { getQuarterLabel } from '@aims/shared/reports/quarters';
import useApprovedBy from '@aims/shared/reports/use-approved-by';
import useSubmittedBy from '@aims/shared/reports/use-submitted-by';
import { EditOutlined, EyeOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Card, List, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useProfile from '../../../shared/use-profile';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';

const { Title, Text } = Typography;

function ReportListItem({ report, peopleGroupId }) {
  const status = ReportApprovalStatuses[report.status];

  const submittedBy = useSubmittedBy(report);
  const approvedBy = useApprovedBy(report);
  const profile = useProfile();

  const quarterLabel = getQuarterLabel(report && report.quarter);
  return (
    <List.Item style={{ width: '100%' }}>
      <Card
        style={{ width: '100%' }}
        bodyStyle={{ textAlign: 'left' }}
        bordered
      >
        <div className="row1">
          <Title level={4}>
            <Space>
              {quarterLabel}
              {status && (
                <>
                  <MinusOutlined />
                  <Text style={{ color: status.color }}>{status.label}</Text>
                </>
              )}
            </Space>
          </Title>
          <Space>
            <Link to={`/upgs/${peopleGroupId}/reports/view/${report._id}`}>
              <Button icon={<EyeOutlined />}>View</Button>
            </Link>
            {!report.isApproved && report.createdBy == `user:${profile._id}` && (
              <Link to={`/upgs/${peopleGroupId}/reports/edit/${report._id}`}>
                <Button icon={<EditOutlined />}>Edit</Button>
              </Link>
            )}
          </Space>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Churches Planted',
                value: report.numChurchesPlanted,
              },
              {
                label: 'Heard the gospel for the first time',
                value: report.numHeardForFirstTime,
              },
              {
                label: 'Salvations',
                value: report.numSalvations,
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
          <div>
            {[
              {
                label: 'Baptisms',
                value: report.numWaterBaptisms,
              },
              {
                label: 'Miracles',
                value: report.miracles?.length,
              },
              {
                label: 'Prayer Requests',
                value: report.prayerRequests?.length,
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
        </div>
        <div className="row3">
          <Text style={{ fontSize: 14 }}>{submittedBy}</Text>
        </div>
        {approvedBy && (
          <div className="row3">
            <Text style={{ fontSize: 14 }}>{approvedBy}</Text>
          </div>
        )}
      </Card>
      <style jsx>{`
        .row1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 16px;
        }
      `}</style>
    </List.Item>
  );
}

export default ReportListItem;
