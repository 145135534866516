import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { useRef, useState } from 'react';
import settings from '../../../settings';

const query = gql`
  query AllHolisticQuarterlyReportsForUser(
    $first: Int
    $after: String
    $filters: HolisticQuarterlyReportFiltersForUser
    $sortBy: [SortBy]
  ) {
    allHolisticQuarterlyReportsForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          contact {
            _id
            fullName
          }
          quarter
          regionCode
          reportType
          isSubmitted
          isApproved
          submittedAt
          createdBy
          createdByUser {
            _id
            name
          }
          submittedByUser {
            _id
            name
          }
          updatedBy
          createdAt
          updatedAt
          updatedByUser {
            _id
            name
          }
          status
          approval {
            status
            when
            approver {
              _id
              name
            }
          }
          holisticProjectId
          holisticProject {
            _id
            projectName
            typeOfProject
          }
          projectCoordId
          projectCoord {
            _id
            fullName
          }
          currency
          exchangeRate
          projectState
          hasReceivedFunds
          loadPaymentsThisQuarterUsd
          loanPaymentsTotalUsd
          loanTotal
          loanTerm
          expensesThisQuarterForeign
          revenueThisQuarterForeign
          supportedFieldWorkers {
            amountForeign
            contactId
          }
          miracles {
            _id
            desc
          }
          prayerRequests {
            _id
            desc
          }
        }
      }
    }
  }
`;

const extract = (data) => data?.allHolisticQuarterlyReportsForUser;

export function useReports(filters, queryId) {
  const [sortedBy, setSortedBy] = useState('quarter.keyword');
  const [sortOrder, setSortOrder] = useState('DESC');

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const { error, loading, data, search, hasNextPage, next, refetch, reset } =
    useManyRemoteM1({
      query,
      extract,
      first: settings.querySize,
      filters,
      sortBy: sortBy.current,
      fetchPolicy: 'network-only',
      queryId,
    });
  return {
    error,
    loading,
    data,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  };
}
