import enUS from 'antd/es/locale/en_US';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import {
  authState,
  profile,
  accountId,
  accounts,
  SIGN_OUT,
} from './auth-store';
import { simpleLists } from './simple-lists-store';
import { responsiveMode, windowDimensions } from './window-store';
import { peopleGroups } from './people-groups-store';
import { adoptions } from './adoptions-store';
import { courses } from './training-courses-store';
import { trainingFiles } from './training-files-store';
import { countries } from '@aims/shared/redux-store/countries-store';
import { countryRegions } from '@aims/shared/redux-store/country-regions-store';
import { queries } from '@aims/shared/redux-store/queries-store';
import { holisticProjects } from './holistic-projects-store';
import { reports } from '@aims/shared/redux-store/reports-store';
import { loanAgreements } from './loan-agreements-store';
import { mous } from './mous-store';
import { projectSponsorships } from './project-sponsorships-store';
import { things } from './anything-store';
import settings from '../settings';

const appReducer = combineReducers({
  authState,
  profile,
  accountId,
  accounts,
  queries,
  simpleLists,
  reports,
  peopleGroups,
  adoptions,
  courses,
  trainingFiles,
  loanAgreements,
  mous,
  things,
  holisticProjects,
  countries,
  countryRegions,
  windowDimensions,
  responsiveMode,
  projectSponsorships,
  locale: () => enUS,
  settings: () => settings,
});

const rootReducer = (state, action) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('state', state);
    console.log('action', action);
  }
  let newState;
  if (action.type === SIGN_OUT) {
    newState = appReducer(undefined, action);
    localStorage.clear();
  } else {
    newState = appReducer(state, action);
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('new state', newState);
  }
  return newState;
};

export default createStore(rootReducer, applyMiddleware(thunk));
