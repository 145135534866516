import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { DeleteOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Divider, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const deleteTrainingQuarterlyReportMutation = gql`
  mutation DeleteTrainingQuarterlyReportForUser($_id: ID!) {
    deleteTrainingQuarterlyReportForUser(_id: $_id) {
      deletedId
    }
  }
`;

const { Paragraph } = Typography;

function DeleteReportButton({ report }) {
  const [deleting, setDeleting] = useState();
  const onDelete = (o) => setDeleting(o);
  const onCancelDelete = () => setDeleting(undefined);

  const [loading, setLoading] = useState(false);
  const [deleteReport] = useMutation(deleteTrainingQuarterlyReportMutation);
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteReport({
        variables: {
          _id: report._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Report deleted successfully',
      });
      history.push(`/training-reports/quarterly`);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
      setLoading(false);
    }
  }, [report, deleteReport, history]);

  return (
    <>
      <YesNoModalJ10
        title="Delete Report Draft"
        yesText="Delete Report"
        noText="Cancel"
        onYes={onYes}
        onNo={onCancelDelete}
        visible={deleting}
        loading={loading}
      >
        <Paragraph>Are you sure you want to delete this report?</Paragraph>
        <Paragraph>{`${getQuarterLabel(report.quarter)}`}</Paragraph>
      </YesNoModalJ10>
      <div>
        <Divider />
        <div className="delete-box">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={onDelete} icon={<DeleteOutlined />} danger ghost>
              Delete Report
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteReportButton;
