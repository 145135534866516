import { Select, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

export const Fonts = {
  Babylonica: {
    name: 'Babylonica',
    fontFamily: 'Babylonica, cursive',
  },
  'Herr Von Muellerhoff': {
    name: 'Herr Von Muellerhoff',
    fontFamily: 'Herr Von Muellerhoff, cursive',
  },
  'Send Flowers': {
    name: 'Send Flowers',
    fontFamily: 'Send Flowers, cursive',
  },
  'Square Peg': {
    name: 'Square Peg',
    fontFamily: 'Square Peg, cursive',
  },
  Tangerine: {
    name: 'Tangerine',
    fontFamily: 'Tangerine, cursive',
  },
  'Water Brush': {
    name: 'Water Brush',
    fontFamily: 'Water Brush, cursive',
  },
};

export function SelectFontStyles() {
  return (
    <style>
      {`@import url('https://fonts.googleapis.com/css2?family=Babylonica&family=Herr+Von+Muellerhoff&family=Send+Flowers&family=Square+Peg&family=Tangerine&family=Water+Brush&display=swap');`}
    </style>
  );
}

function SelectFont({ disabled, value, onChange }) {
  return (
    <Select
      autoComplete="chrome-off"
      allowClear
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a font"
      value={value}
    >
      {Object.values(Fonts).map((font) => (
        <Select.Option key={font.name} value={font.name} title={font.name}>
          <div style={{ height: 36 }}>
            <Text
              style={{
                fontFamily: font.fontFamily,
                fontSize: 24,
                lineHeight: 1.5,
              }}
            >
              {font.name}
            </Text>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectFont;
