import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  InputNumber,
  message,
  Progress,
  Typography,
} from 'antd';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useHistory } from 'react-router-dom';
import { updateHolisticProjectAction } from '../../../../redux-store/holistic-projects-store';
import settings from '../../../../settings';
import HolisticSteps from '../HolisticSteps';
import {
  extractPublicHolisticProject,
  publicHolisticProjectQuery,
} from '../constants';
import ProjectBanner from './ProjectBanner';
import ProjectBubbles from './ProjectBubbles';

const levels = {
  MONTHLY_30: {
    key: 'MONTHLY_30',
    label: '$30 Monthly',
    amount: 30,
  },
  MONTHLY_50: {
    key: 'MONTHLY_50',
    label: '$50 Monthly',
    amount: 50,
  },
  MONTHLY_100: {
    key: 'MONTHLY_100',
    label: '$100 Monthly',
    amount: 100,
  },
  CUSTOM: {
    key: 'CUSTOM',
    label: 'One-Time Donation',
  },
};

const { Title, Text, Paragraph } = Typography;

function SelectALevel({ value, onChange, disabled }) {
  const handleChoice = useCallback(
    (val) => {
      onChange(val);
    },
    [onChange],
  );
  return (
    <div style={{ textAlign: 'center' }}>
      <Title level={4} style={{ marginBottom: 12 }}>
        Select a Funding Level
      </Title>
      <div
        style={{
          marginRight: -8,
          marginLeft: -8,
        }}
      >
        {Object.values(levels).map((level) => (
          <Button
            key={level.key}
            type={value === level.key ? 'primary' : 'normal'}
            onClick={() => handleChoice(level.key)}
            disabled={disabled}
            style={{
              marginRight: 8,
              marginLeft: 8,
              fontSize: 16,
              letterSpacing: 2,
              marginBottom: 8,
            }}
          >
            {level.label}
          </Button>
        ))}
      </div>
    </div>
  );
}

const queryId = 'sponsorAProject';

function SponsorAProjectForm({ step, title, projectId }) {
  const { loading: projectLoading, refetch } = useOneM1({
    variables: { _id: projectId },
    query: publicHolisticProjectQuery,
    extract: extractPublicHolisticProject,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !projectId,
    showError: true,
  });
  const project = useSingleSomethingA10('holisticProjects', projectId, queryId);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      const link = `/partner/sponsor-a-project/${projectId}/impact?${queryString.stringify(
        {
          amount: values.amount,
          recurring: false,
          startDate: values.startDate,
        },
      )}`;
      history.push(link);
    },
    [projectId, history],
  );

  return (
    <>
      <ProjectBanner project={project} loading={projectLoading} />
      <HolisticSteps step={step} title={title} />
      <div
        className="container"
        style={{ display: 'flex', marginRight: -16, marginLeft: -16 }}
      >
        <div style={{ marginLeft: 16, marginRight: 16 }} className="left">
          <Title level={3} style={{ textAlign: 'center' }}>
            What will this project accomplish?
          </Title>
          <Paragraph
            style={{ textAlign: 'center', fontSize: 16, marginBottom: 32 }}
          >
            {project && project.desc}
          </Paragraph>
          <ProjectBubbles project={project} />
        </div>
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                background: settings.colors.white,
                padding: '24px 32px',
                paddingBottom: 16,
                marginBottom: 32,
                marginLeft: 16,
                marginRight: 16,
              }}
            >
              <div style={{ marginBottom: 20 }}>
                <div style={{ marginBottom: 8 }}>
                  <Text
                    style={{ fontSize: 24, fontWeight: 800, lineHeight: 1.1 }}
                  >
                    {displayMoney(
                      getNumberValue(project?.fundingBalance) / 10000,
                    )}
                  </Text>{' '}
                  <Text style={{ fontSize: 16, lineHeight: 1.1 }}>
                    raised of
                  </Text>{' '}
                  <Text
                    style={{ fontSize: 18, fontWeight: 600, lineHeight: 1.1 }}
                  >
                    {displayMoney(
                      getNumberValue(project?.fundingTarget) / 10000,
                    )}
                  </Text>{' '}
                  <Text style={{ fontSize: 16, lineHeight: 1.1 }}>goal</Text>
                </div>
                <Progress
                  percent={Math.round(project?.fundingPercent / 100)}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeColor={settings.colors.holisticGreen}
                  trailColor={settings.colors.white}
                  strokeWidth={24}
                />
              </div>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a donation amount',
                  },
                ]}
                initialValue={1000}
                style={{
                  marginBottom: 16,
                }}
              >
                <InputNumber
                  addonBefore="$"
                  min={0}
                  step={1}
                  disabled={loading}
                  style={{ width: 300 }}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 16 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{ width: '100%' }}
                >
                  Donate Now
                </Button>
              </Form.Item>
              <Form.Item>
                <CopyToClipboard
                  text={`${settings.apiUrl.replace(
                    'graphql/',
                    'meta/hproject',
                  )}/${projectId}`}
                  onCopy={() => message.success('Link copied to clipboard!')}
                >
                  <Button loading={loading} style={{ width: '100%' }}>
                    Share
                  </Button>
                </CopyToClipboard>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>

      {/* <Paragraph
        style={{
          textAlign: 'center',
          fontSize: 14,
        }}
      >
        Your donation will give you access to quarterly reports, prayer requests
        and testimonies for the Unreached People Groups impacted by the selected
        Holistic Project.
      </Paragraph> */}
      {/* <Divider /> */}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/partner/sponsor-a-project" style={{ marginLeft: -12 }}>
          <Button type="dashed" icon={<ArrowLeftOutlined />} disabled={loading}>
            Back
          </Button>
        </Link>
      </div>
      <style jsx>{`
        .container {
          flex-wrap: nowrap;
        }
        @media screen and (max-width: 1300px) {
          .container {
            flex-wrap: wrap;
            justify-content: center;
          }
          .left {
            margin-bottom: 24px;
          }
        }
        @media screen and (max-width: 600px) {
        }
      `}</style>
      <style jsx global>{`
        .ant-progress-inner {
          border: 1px solid ${settings.colors.textBlack};
        }
      `}</style>
    </>
  );
}

export default SponsorAProjectForm;
