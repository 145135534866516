import { ViewHolisticProjectRequest } from '@aims/shared/reports';
import { EditOutlined } from '@ant-design/icons';
import { Button, Skeleton, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import PrintLoanAgreementBtn from '../../loan-agreements/PrintLoanAgreementBtn';
import useReport from './use-report';

const queryId = 'viewProposalTab';

function ViewProposalTab({ reportId, showButtons = false }) {
  const { report, loading } = useReport(reportId, queryId);

  return (
    <>
      {showButtons && (
        <div
          style={{
            position: 'absolute',
            top: 64,
            right: 0,
            height: '100%',
            zIndex: 10,
          }}
        >
          <div style={{ position: 'sticky', top: 16 }}>
            <div style={{ marginBottom: 8 }}>
              <PrintLoanAgreementBtn title="Print Proposal" />
            </div>
            {report?.canEdit && (
              <Link to={`/holistic-proposals/edit/${reportId}`}>
                <Button icon={<EditOutlined />}>Edit Proposal</Button>
              </Link>
            )}
          </div>
        </div>
      )}
      <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
        {report && <ViewHolisticProjectRequest report={report} />}
        {loading && (
          <Space direction="vertical">
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Space>
        )}
      </div>
    </>
  );
}

export default ViewProposalTab;
