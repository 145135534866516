import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import { updateAnythingAction } from '../../../redux-store/anything-store';

const query = gql`
  query TrainingCourseForUser($_id: ID!) {
    trainingCourseForUser(_id: $_id) {
      _id
      courseName
      desc
      primaryThumbnailUrl
      modules {
        _id
        name
        desc
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

const extract = (data) => data?.trainingCourseForUser;

function useTrainingCourse(
  _id,
  queryId = 'useTrainingCourse',
  showError = true,
) {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    fetchPolicy: 'network-only',
    updateAction: updateAnythingAction,
    queryId,
    showError,
  });
  const data = useSingleSomethingA10('things', _id, queryId);

  return {
    data,
    loading,
    refetch,
  };
}

export default useTrainingCourse;
