import { gql } from '@apollo/client';

export const MouRoles = {
  TRAINER: {
    key: 'TRAINER',
    label: 'Trainer',
  },
  FIELD_WORKER: {
    key: 'FIELD_WORKER',
    label: 'Field Worker',
  },
  REGIONAL_COORDINATOR: {
    key: 'REGIONAL_COORDINATOR',
    label: 'Regional Coordinator',
  },
};

export const extractMous = (data) => data.allMousForUser;
export const extractMou = (data) => data && data.mouForUser;

export const mouListAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    sections {
      _id
      title
    }
    parties {
      _id
      ref
      name
      date
      contactId
      signature
      contactName
    }
    attachments {
      _id
      ref
      name
      fileId
    }
    role
    peopleGroupId
    peopleGroupName
    contactId
    contactName
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allMousQuery = gql`
  query AllMousForUser(
    $first: Int
    $after: String
    $filters: MouFiltersForUser
    $sortBy: [SortBy]
  ) {
    allMousForUser(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${mouListAttributes}
      }
    }
  }
`;

const MouContactDetails = gql`
  {
    _id
    fullName
    primaryThumbnailUrl
    birthday
    primaryEmail
    primaryPhoneNumber
    primaryAddress {
      _id
      label
      address1
      address2
      city
      state
      zipCode
      country
    }
    regionCode
  }
`;

const MouPeopleGroupDetails = gql`
  {
    _id
    continentCode
    continent
    region
    regionCode
    countryCode
    country
    peopleGroupId
    nameInCountry
    nameAcrossCountries
    jpUrl
    latlon
    primaryPhotoId
    primaryThumbnailUrl
  }
`;

export const mouDetailedAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    parties {
      _id
      ref
      name
      contactId
      contactName
      signature
      date
      fontName
    }
    signeeIds
    sections {
      _id
      title
      text
      initials {
        partyId
        initials
        fontName
        date
      }
    }
    attachments {
      _id
      ref
      name
      fileId
      file {
        _id
        size
        filename
        contentType
        url
        uploaded
        processed
      }
    }
    finalizedDate
    role
    peopleGroupId
    peopleGroupName
    peopleGroup ${MouPeopleGroupDetails}
    contactId
    contactName
    contact ${MouContactDetails}
    frozenPeopleGroupDetails ${MouPeopleGroupDetails}
    frozenContactDetails ${MouContactDetails}
    createdAt
    updatedAt
    deletedAt
  }
`;

export const mouQuery = gql`
  query MouForUser($_id: ID!) {
    mouForUser(_id: $_id) ${mouDetailedAttributes}
  }
`;

export const signMouMutation = gql`
  mutation SignMouForUser(
    $signature: MouSignInput!
  ) {
    signMouForUser(signature: $signature) {
      mou ${mouDetailedAttributes}
    }
  }
`;

export const initialMouSectionMutation = gql`
  mutation InitialMouSectionForUser(
    $initials: MouInitialsInput!
  ) {
    initialMouSectionForUser(initials: $initials) {
      mou ${mouDetailedAttributes}
    }
  }
`;
