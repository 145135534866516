import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import AdoptAUpgForm from './AdoptAUpgForm';
import AdoptAUpgSelectAPg from './AdoptAUpgSelectAPg';
import AdoptAUpgAdoptToo from './AdoptAUpgAdoptToo';
import CatalogBannerImage from './CatalogBannerImage';

const { Text, Title, Paragraph } = Typography;

function AdoptAPeopleGroup() {
  const { pgId } = useParams();

  const location = useLocation();
  const [step, title] = useMemo(() => {
    const r = /^\/partner\/adopt-a-upg\/(.*)\/adopt\/?$/;
    if (location.pathname.match(r)) {
      return [2, 'Adopt Your UPG'];
    } else if (pgId) {
      return [1, 'Funding Details'];
    }
    return [0, 'Select UPG'];
  }, [pgId, location]);

  return (
    <CSPage title="AIMS.org | Sponsor a Native Missionary">
      <CSPageHeader
        title="Sponsor a Native Missionary"
        parents={[
          {
            label: 'Partner with Us',
            link: `/partner`,
          },
        ]}
      />
      <div style={{ height: 16 }} />
      <CatalogBannerImage />
      {step === 0 && <AdoptAUpgSelectAPg title={title} step={step} />}
      {step === 1 && <AdoptAUpgForm pgId={pgId} title={title} step={step} />}
      {step === 2 && (
        <AdoptAUpgAdoptToo pgId={pgId} title={title} step={step} />
      )}
      <style jsx>{``}</style>
      <style jsx global>{`
        .ant-steps-item-process
          > .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title:after,
        .ant-steps-item-wait
          > .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title::after {
          background-color: ${settings.colors.borderGray};
        }
        .ant-steps-item-title {
          line-height: 32px;
        }
        .ant-slider-mark-text {
          white-space: nowrap;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .ant-list .ant-row {
            justify-content: center;
          }
        }
        .ant-timeline-item-head {
          background-color: ${settings.colors.ghost};
        }
        .ant-timeline-item-tail {
          border-left: 2px solid transparent;
        }
        .ant-timeline-item-last {
          padding-bottom: 0px;
        }
      `}</style>
    </CSPage>
  );
}

export default AdoptAPeopleGroup;
