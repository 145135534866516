export const READ_TRAINING_FILES = 'READ_TRAINING_FILES';

// eslint-disable-next-line no-shadow
export const readTrainingFilesAction = (files) => ({
  type: READ_TRAINING_FILES,
  payload: {
    files,
  },
});

const initialFiles = [];

export function trainingFiles(state = initialFiles, action) {
  switch (action.type) {
    case READ_TRAINING_FILES: {
      const newState = { ...state };
      action.payload.files.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    default:
      return state;
  }
}
