import { Form } from 'antd';
import React from 'react';
import SelectLanguage from './SelectLanguage';

function LanguagesFormItem({ name, label, saving, style }) {
  return (
    <Form.Item name={name} label={label} style={style}>
      <SelectLanguage disabled={saving} />
    </Form.Item>
  );
}

export default LanguagesFormItem;
