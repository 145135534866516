import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';

const { Text } = Typography;

export const allJPRegionsQuery = gql`
  query AllJPRegions {
    allJPRegions {
      _id
      name
      continentCode
    }
  }
`;

function SelectRegion({ disabled, value, onChange, filters, ...props }) {
  const { loading, error, data } = useQuery(allJPRegionsQuery);
  const regions = useMemo(() => {
    let _data = [];
    if (data) {
      _data = data.allJPRegions;
      if (filters && filters.continentCode) {
        _data = _data.filter((d) => d.continentCode === filters.continentCode);
      }
    }
    return _data;
  }, [data, filters]);
  return (
    <Select
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a region"
      value={value}
      allowClear
      loading={loading}
      {...props}
    >
      {regions.map((region) => (
        <Select.Option key={region._id} value={region._id} title={region.name}>
          <div className="region-option">
            <div>
              <Text>{region.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectRegion;
