import { Select, Typography } from 'antd';
import React from 'react';
import { titleCase } from '../../shared/utils';

function SelectLanguage({ value, onChange, languages, ...props }) {
  return (
    <Select value={value} onChange={onChange} {...props} allowClear>
      {languages.map((lang) => (
        <Select.Option key={lang} value={lang}>
          {titleCase(lang)}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectLanguage;
