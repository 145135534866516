import { gql } from '@apollo/client';

export const userInviteQuery = gql`
  query UserInvite($_id: ID!) {
    userInvite(_id: $_id) {
      _id
      contactId
      contact {
        _id
        fullName
        primaryEmailId
        primaryPhoneNumberId
        emails {
          _id
          email
        }
        phoneNumbers {
          _id
          phone
        }
      }
      roleIds
      roles {
        _id
        name
        label
      }
    }
  }
`;

export const userProfileAttributes = gql`
  {
    _id
    username
    permissions
    email
    name
    emailVerified
    phone
    primaryThumbnailUrl
    createdAt
    updatedAt
    myContact {
      _id
      fullName
    }
    myHousehold {
      _id
      fullName
    }
    myOrganizations {
      _id
      fullName
    }
  }
`;

export const myProfileForUserQuery = gql`
  query MyProfileForUser {
    myProfileForUser ${userProfileAttributes}
  }
`;

export const editMyProfileQuery = gql`
  query MyProfile {
    myProfile ${userProfileAttributes}
  }
`;

export const editProfileMutation = gql`
  mutation EditMyProfileForUser($profile: EditProfileInputForUser!) {
    editMyProfileForUser(profile: $profile)
  }
`;
