import { useMemo } from 'react';
import agriculture from '../../assets/agriculture.jpg';
import building from '../../assets/building.jpg';
import education from '../../assets/education.jpg';
import healthCare from '../../assets/healthCare.jpg';
import manufacturing from '../../assets/manufacturing.jpg';
import media from '../../assets/media.jpg';
import smallBusiness from '../../assets/smallBusiness.jpg';
import translation from '../../assets/translation.jpg';
import water from '../../assets/water.jpg';
import worldImage from '../../assets/world-sm.jpg';

export default function useDefaultProjectPhoto(holisticProject) {
  const defaultPhoto = useMemo(() => {
    let type = holisticProject && holisticProject.typeOfProject;
    if (type == 'AGRICULTURE') return agriculture;
    if (type == 'SMALL_BUSINESS') return smallBusiness;
    if (type == 'CLEAN_WATER') return water;
    if (type == 'MANUFACTURING') return manufacturing;
    if (type == 'MEDIA') return media;
    if (type == 'TRANSLATION') return translation;
    if (type == 'BUILDING') return building;
    if (type == 'EDUCATION') return education;
    if (type == 'HEALTH_CARE') return healthCare;
    else return worldImage;
  }, [holisticProject]);
  return defaultPhoto;
}
