import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import { updatePeopleGroupAction } from '../../redux-store/people-groups-store';

const query = gql`
  query PeopleGroupPhotosForUser($_id: ID!) {
    peopleGroupForUser(_id: $_id) {
      _id
      photoIds
      primaryPhotoId
      primaryThumbnailUrl
      photos {
        _id
        desc
        s3Key
        size
        filename
        contentType
        tags
        url
        uploaded
        processed
        thumbnail {
          s3Key
          size
          filename
          contentType
          url
          height
          width
          sizeCode
        }
        images {
          s3Key
          size
          filename
          contentType
          url
          height
          width
          sizeCode
        }
        attachedTo
        createdAt
        updatedAt
      }
    }
  }
`;

const extract = (data) => data.peopleGroupForUser;

function usePeopleGroupPhotos(_id, queryId = 'default') {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updatePeopleGroupAction,
    skip: !_id,
    showError: true,
  });
  const data = useSingleSomethingA10('peopleGroups', _id, queryId);

  return {
    data,
    loading,
    refetch,
  };
}

export default usePeopleGroupPhotos;
