import { Alert, Button, Empty, List, Typography } from 'antd';
import React, { useState } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import MyPeopleGroupCard from './MyPeopleGroupCard';
import useMyPeopleGroups from './use-my-pgs';
import RecentDonationsAlert from '../../components/RecentDonationsAlert';

const { Text } = Typography;

function MyPeopleGroups() {
  const [numShown, setNumShown] = useState(40);
  const { myPgs: items, loading } = useMyPeopleGroups();

  return (
    <CSPage title="AIMS.org | Unreached People Groups">
      <CSPageHeader title="Unreached People Groups" />
      {!loading && items?.length === 0 && <RecentDonationsAlert />}
      <List
        className="recentList"
        dataSource={items.slice(0, numShown)}
        grid={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
          gutter: 20,
        }}
        rowKey="_id"
        renderItem={(item) => <MyPeopleGroupCard item={item} />}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Looks like you don't have any adopted or sponsored people groups yet."
            />
          ),
        }}
        loadMore={
          items.length > numShown && (
            <div className="load-more">
              <Button onClick={() => setNumShown(numShown + 8)}>
                Load More
              </Button>
            </div>
          )
        }
      />
    </CSPage>
  );
}

export default MyPeopleGroups;
