import {
  LoginOutlined,
  MenuOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Layout, Menu, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import longLogo from '../assets/long-logo-sm.png';
import settings from '../settings';

const { Sider } = Layout;
const { Title, Text } = Typography;

function MainMenu({ currentItem, onMenuClick, horizontal, responsiveMode }) {
  const { t, i18n } = useTranslation();

  return (
    <Menu
      theme="light"
      mode={horizontal ? 'horizontal' : 'inline'}
      defaultSelectedKeys={[currentItem]}
      onClick={onMenuClick}
      className="main-nav-menu"
      style={{ flex: 1 }}
    >
      <Menu.Item key="sign-in" icon={<LoginOutlined />}>
        <Link to="/sign-in">{t('menu.signIn')}</Link>
      </Menu.Item>
      <Menu.Item key="sign-up" icon={<ProfileOutlined />}>
        <Link to="/sign-up">{t('menu.signUp')}</Link>
      </Menu.Item>
    </Menu>
  );
}

function PublicNavMenu() {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(undefined);
  const history = useHistory();
  const currentItem = useMemo(() => {
    const path = location.pathname;
    if (path === '/partner') {
      return 'partner';
    }
    return path;
  }, [location]);
  const onMenuClick = useCallback(({ item, key, keyPath, domEvent }) => {}, []);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  if (responsiveMode === 'mobile') {
    return (
      <>
        <Layout.Header
          className="main-nav-header"
          style={{ position: 'fixed', zIndex: 1, width: '100%' }}
        >
          <a href={settings.publicUrl}>
            <img className="logo-img" src={longLogo} alt="AIMS Logo" />
          </a>
          <Button
            key="1"
            type="primary"
            icon={<MenuOutlined onClick={() => setDrawerVisible(true)} />}
          />
        </Layout.Header>
        <div style={{ height: 72 }} />
        <Drawer
          title="AIMS.org"
          placement="right"
          closable={true}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          className="main-nav-drawer"
        >
          <MainMenu
            onMenuClick={onMenuClick}
            currentItem={currentItem}
            responsiveMode={responsiveMode}
          />
        </Drawer>
        <style jsx global>{`
          .logo-img {
            height: 56px;
            margin-top: 12px;
            margin-bottom: 12px;
            margin-left: 16px;
          }
          .main-nav-menu {
            height: calc(100% - 80px);
          }
          .main-nav-menu-slider .ant-layout-sider-trigger {
            border-right: 1px solid #f0f0f0;
          }
          .main-nav-header {
            background-color: white;
            height: unset;
            padding-left: 0;
            padding-right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0px 0px 16px #e0e0e0;
          }
          .main-nav-drawer .ant-drawer-body {
            padding: 0px;
          }
        `}</style>
      </>
    );
  }

  return (
    <>
      <Layout.Header
        style={{
          background: 'white',
          display: 'flex',
          height: 80,
          alignItems: 'flex-end',
        }}
      >
        <div className="logo">
          <a href={settings.publicUrl}>
            <img className="logo-img" src={longLogo} alt="AIMS Logo" />
          </a>
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          onClick={onMenuClick}
          style={{ minWidth: 200, marginLeft: 'auto' }}
        >
          <Menu.SubMenu
            key="user"
            mode="horizontal"
            className="profile-submenu"
            title="My Account"
          >
            <Menu.Item key="sign-in" icon={<LoginOutlined />}>
              <Link to="/sign-in">{t('menu.signIn')}</Link>
            </Menu.Item>
            <Menu.Item key="sign-up" icon={<ProfileOutlined />}>
              <Link to="/sign-up">{t('menu.signUp')}</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Layout.Header>
      <style jsx>{`
        .logo {
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 32px;
        }
        .logo-img {
          height: 56px;
        }
      `}</style>
      <style jsx global>{`
        .profile-submenu > .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          height: 80px;
        }
      `}</style>
    </>
  );
}

export default PublicNavMenu;
