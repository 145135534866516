import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { useRef, useState } from 'react';
import settings from '../../settings';
import { FileTypes } from '@aims/shared/files/constants';

const FileAttributes = gql`
  {
    _id
    desc
    s3Key
    size
    filename
    contentType
    tags
    url
    uploaded
    processed
    thumbnail {
      s3Key
      size
      filename
      contentType
      url
      height
      width
      sizeCode
    }
    images {
      s3Key
      size
      filename
      contentType
      url
      height
      width
      sizeCode
    }
    type
    attachedTo
    createdAt
    updatedAt
  }
`;

export const allFilesForUserQuery = gql`
  query AllFilesForUser($first: Int, $after: String, $filters: FileFiltersForUser, $sortBy: [SortBy]) {
    allFilesForUser(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${FileAttributes}
      }
    }
  }
`;

export const extractAllFilesForUser = (data) => data.allFilesForUser;

function useHolisticProjectPhotos(_id, queryId = 'default') {
  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const filters = useRef({
    type: FileTypes.H_PHOTO.key,
    attachedTo: _id,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const { loading, data, refetch } = useManyRemoteM1({
    query: allFilesForUserQuery,
    extract: extractAllFilesForUser,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'users',
  });

  return {
    data,
    loading,
    refetch,
  };
}

export default useHolisticProjectPhotos;
