import sharedSettings from '@aims/shared/sharedSettings';
import {
  BoldOutlined,
  CodeOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Space } from 'antd';
import isHotkey from 'is-hotkey';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Editor,
  Element as SlateElement,
  Text,
  Transforms,
  createEditor,
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, useSlate, withReact } from 'slate-react';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  return <span {...attributes}>{children}</span>;
};

export const serializeText = (node) => {
  if (Array.isArray(node)) {
    return node.map((n) => serializeText(n)).join(' ');
  }
  if (Text.isText(node)) {
    // let string = escapeHtml(node.text);
    let string = node.text;
    return string;
  }

  const children = node.children
    ? node.children.map((n) => serializeText(n)).join(' ')
    : '';

  return children;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      size="small"
      type={isBlockActive(editor, format) ? 'primary' : undefined}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={icon}
    />
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      size="small"
      type={isMarkActive(editor, format) ? 'primary' : undefined}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      icon={icon}
    />
  );
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
      ),
    split: true,
  });
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];
export const DefaultContentEditableValue = JSON.stringify(initialValue);

function ContentEditableInput({
  value: parentValue,
  onChange: parentOnChange,
  readOnly,
  disabled,
}) {
  const prev = useRef(parentValue);

  const [editor] = useState(() => withReact(withHistory(createEditor())));
  useEffect(() => {
    if (JSON.stringify(editor.children) != parentValue) {
      let parsed;
      if (!parentValue) {
        parsed = initialValue;
      } else {
        try {
          parsed = JSON.parse(parentValue);
        } catch (err) {
          console.warn(err);
          parsed = initialValue;
        }
      }
      while (editor.children.length > 0) {
        Transforms.removeNodes(editor, { at: [0] });
      }
      Transforms.insertNodes(editor, parsed);
    }
    prev.current = parentValue;
  }, [parentValue, editor]);
  const value = useMemo(() => {
    if (parentValue) {
      try {
        const parsed = JSON.parse(parentValue);
        return parsed;
      } catch (err) {
        console.warn(err);
        return initialValue;
      }
    }
    return initialValue;
  }, [parentValue]);
  const onChange = useCallback(
    (v) => {
      parentOnChange?.(JSON.stringify(v));
    },
    [parentOnChange],
  );

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <div className="content-editable">
      <Slate
        editor={editor}
        value={value}
        onChange={onChange}
        initialValue={value}
      >
        {!readOnly && (
          <Space size="small" style={{ marginBottom: 8 }}>
            <MarkButton format="bold" icon={<BoldOutlined />} />
            <MarkButton format="italic" icon={<ItalicOutlined />} />
            <MarkButton format="underline" icon={<UnderlineOutlined />} />
            <MarkButton format="code" icon={<CodeOutlined />} />
            <MarkButton
              format="strikethrough"
              icon={<StrikethroughOutlined />}
            />
            <BlockButton
              format="heading-one"
              icon={<div className="icon">H1</div>}
            />
            <BlockButton
              format="heading-two"
              icon={<div className="icon">H2</div>}
            />
            <BlockButton
              format="heading-three"
              icon={<div className="icon">H3</div>}
            />
            <BlockButton
              format="block-quote"
              icon={<div className="icon">{`"`}</div>}
            />
            <BlockButton
              format="numbered-list"
              icon={<OrderedListOutlined />}
            />
            <BlockButton
              format="bulleted-list"
              icon={<UnorderedListOutlined />}
            />
          </Space>
        )}
        <Editable
          disabled={disabled}
          readOnly={readOnly}
          spellCheck
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          style={{
            ...(!readOnly
              ? {
                  background: 'white',
                  padding: '8px 11px',
                  border: `1px solid ${sharedSettings.colors.borderGray}`,
                  minHeight: 100,
                }
              : undefined),
          }}
        />
        {/* </div> */}
      </Slate>
      <style global jsx>{`
        .content-editable p {
          margin-bottom: 0;
          line-height: 1.2;
        }
        .content-editable p:not(:last-child) {
          margin-bottom: 4px;
        }
        .content-editable h1:last-child,
        .content-editable h2:last-child,
        .content-editable h3:last-child,
        .content-editable h4:last-child,
        .content-editable h5:last-child {
          margin-bottom: 0px;
        }
        blockquote {
          background: #f9f9f9;
          border-left: 2px solid #336094;
          margin: 1.5em 10px;
          padding: 0.5em 10px;
          color: #336195;
          font-style: italic;
        }
        code {
          background: #f0f0f0;
          padding: 4px 8px;
          border-radius: 4px;
        }
        .read-only {
          background: ${sharedSettings.colors.ghost};
          padding: 12px;
          margin-bottom: 8px;
          border-radius: 4px;
        }
      `}</style>
    </div>
  );
}

export default ContentEditableInput;
