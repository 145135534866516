import React from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import ViewProposalTab from './ViewProposalTab';

function ViewProposalPage() {
  const { reportId } = useParams();
  return (
    <CSPage title="AIMS.org | View Holistic Project Proposal">
      <CSPageHeader
        titleComponent={null}
        title="View Holistic Project Proposal"
        parents={[
          {
            label: 'Holistic Project Proposals',
            link: `/holistic-proposals`,
          },
        ]}
      />
      <ViewProposalTab reportId={reportId} showButtons />
    </CSPage>
  );
}

export default ViewProposalPage;
