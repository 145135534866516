import ReportInfoItem from '@aims/shared/reports/common/ReportInfoItem';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import useApprovedBy from '@aims/shared/reports/use-approved-by';
import useSubmittedBy from '@aims/shared/reports/use-submitted-by';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { EditOutlined, EyeOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Card, List, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ellipsis from 'text-ellipsis';

const { Title, Text } = Typography;

function ProposalListItem({ report, locale, canCreateReports }) {
  const status = useMemo(() => {
    if (report && report.status) {
      return ReportApprovalStatuses[report.status];
    }
    return undefined;
  }, [report]);

  const submittedBy = useSubmittedBy(report);
  const approvedBy = useApprovedBy(report);

  return (
    <List.Item style={{ width: '100%', border: 'none' }}>
      <Card
        style={{ width: '100%' }}
        styles={{ body: { textAlign: 'left' } }}
        bordered
      >
        <div className="row1">
          <Title level={4}>
            <Space>
              {new Intl.DateTimeFormat(locale, {
                dateStyle: 'full',
              }).format(new Date(report.submittedAt || report.createdAt))}
              {canCreateReports && (
                <>
                  <MinusOutlined />
                  <Text style={{ color: status.color }}>{status.label}</Text>
                </>
              )}
            </Space>
          </Title>
          <Space>
            <Link to={`/holistic-proposals/view/${report._id}`}>
              <Button icon={<EyeOutlined />}>View</Button>
            </Link>
            {report?.canEdit && (
              <Link to={`/holistic-proposals/edit/${report._id}`}>
                <Button icon={<EditOutlined />}>Edit</Button>
              </Link>
            )}
          </Space>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Project Name',
                value: report.projectName,
              },
              {
                label: 'People Group IDs',
                value: report.peopleGroupIds.join(' '),
              },
              {
                label: 'Description',
                value: report.desc && ellipsis(report.desc, 160),
              },
              {
                label: 'Loan Total',
                value: displayMoney(getNumberValue(report.loanTotalUsd)),
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
        </div>
        <div className="row3">
          {submittedBy && <Text style={{ fontSize: 14 }}>{submittedBy}</Text>}
        </div>
        {approvedBy && (
          <div className="row3">
            <Text style={{ fontSize: 14 }}>{approvedBy}</Text>
          </div>
        )}
      </Card>
      <style jsx>{`
        .row1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 16px;
        }
        .info {
          margin-bottom: 16px;
          display: flex;
          flex-wrap: wrap;
          margin-left: -16px;
          margin-right: -16px;
          max-width: 600px;
        }
      `}</style>
    </List.Item>
  );
}

export default ProposalListItem;
