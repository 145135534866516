import React, { useContext } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { Button, Card, Typography } from 'antd';
import PhotoCircle from '@aims/shared/components/PhotoCircle';
import useProfile from '../../shared/use-profile';
import sharedSettings from '@aims/shared/sharedSettings';
import { formatPhoneNumberForDisplay } from '@aims/shared/shared/utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { LogoutOutlined } from '@ant-design/icons';
import { AuthContext } from '../../shared/auth-context';

const { Title, Text } = Typography;

function ViewProfilePage() {
  const profile = useProfile();
  console.log('profile', profile);
  const authContext = useContext(AuthContext);
  return (
    <CSPage title="AIMS.org | My Profile">
      <CSPageHeader title="My Profile" />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card
          style={{
            maxWidth: 600,
            paddingRight: 48,
            paddingLeft: 48,
            paddingTop: 24,
            paddingBottom: 24,
          }}
          bordered={false}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 16,
            }}
          >
            <PhotoCircle
              name={profile.name}
              photoUrl={profile.primaryThumbnailUrl}
              styles={{
                container: { height: 160, width: 160 },
                placeholder: {
                  backgroundColor: sharedSettings.colors.primary,
                  fontSize: 32,
                },
              }}
            />
          </div>
          <div style={{ textAlign: 'center', marginBottom: 16 }}>
            <Title level={2} style={{ margin: 0, marginBottom: 4 }}>
              {profile.name}
            </Title>
            {profile.email && (
              <div style={{ marginBottom: 4 }}>
                <Text style={{ fontSize: 16 }}>{profile.email}</Text>
              </div>
            )}
            {profile.phone && (
              <div style={{ marginBottom: 4 }}>
                <Text>{formatPhoneNumberForDisplay(profile.phone)}</Text>
              </div>
            )}
          </div>
          <div style={{ textAlign: 'center' }}>
            <Link to="/my-profile/edit">
              <Button type="primary">Edit Profile</Button>
            </Link>
          </div>
        </Card>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
        <Button
          danger
          ghost
          icon={<LogoutOutlined />}
          style={{ paddingRight: 32, paddingLeft: 32 }}
          onClick={() => authContext.signOut()}
        >
          Sign Out
        </Button>
      </div>
    </CSPage>
  );
}

export default ViewProfilePage;
