import { Steps, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import useResponsiveMode from '../../../shared/use-reponsive-mode';
import CatalogBannerImage from './HolisticBannerImage';
import SponsorAProjectForm from './step-2-details/SponsorAProjectForm';
import SponsorAProjectSelectProject from './step-1-select/SponsorAProjectSelectProject';
import SponsorAProjectPeoples from './step-3-peoples/SponsorAProjectPeoples';
import SponsorAProjectRedirectToPP from './SponsorAProjectRedirectToPP';

const { Text, Title, Paragraph } = Typography;

function SponsorAProject() {
  const { projectId, page } = useParams();
  const responsive = useResponsiveMode();

  const [step, title] = useMemo(() => {
    if (!projectId) {
      return [0, 'Select a Holistic Project'];
    } else if (projectId && !page) {
      return [1, 'Sponsorship Details'];
    } else if (page === 'impact') {
      return [2, 'People Group Impact'];
    } else if (page === 'redirect') {
      return [3, 'Redirect'];
    }
    return [0, 'Select a Holistic Project'];
  }, [projectId, page]);

  return (
    <CSPage title="AIMS.org | Fund a Holistic Project">
      <CSPageHeader
        title="Fund a Holistic Project"
        parents={[
          {
            label: 'Partner with Us',
            link: `/partner`,
          },
        ]}
      />
      <div style={{ height: 16 }} />
      {step === 0 && <CatalogBannerImage />}
      {step === 0 && <SponsorAProjectSelectProject step={step} title={title} />}
      {step === 1 && (
        <SponsorAProjectForm step={step} title={title} projectId={projectId} />
      )}
      {step === 2 && (
        <SponsorAProjectPeoples
          step={step}
          title={title}
          projectId={projectId}
        />
      )}
      {step === 3 && (
        <SponsorAProjectRedirectToPP
          step={step}
          title={title}
          projectId={projectId}
        />
      )}
      <style jsx>{``}</style>
      <style jsx global>{`
        .ant-steps-item-process
          > .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title:after,
        .ant-steps-item-wait
          > .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title::after {
          background-color: ${settings.colors.borderGray};
        }
        .ant-steps-item-title {
          line-height: 32px;
        }
        .ant-slider-mark-text {
          white-space: nowrap;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .ant-list .ant-row {
            justify-content: center;
          }
        }
        .ant-timeline-item-head {
          background-color: ${settings.colors.ghost};
        }
        .ant-timeline-item-tail {
          border-left: 2px solid transparent;
        }
        .ant-timeline-item-last {
          padding-bottom: 0px;
        }
        h2.ant-typography {
          color: ${settings.colors.textBlack};
          letter-spacing: 2px;
          font-weight: 600;
        }
        h3.ant-typography {
          color: ${settings.colors.textBlack};
          letter-spacing: 1.5px;
          font-weight: 600;
        }
        h4.ant-typography {
          color: ${settings.colors.textBlack};
          letter-spacing: 1.5px;
          font-weight: 600;
        }
        h5.ant-typography {
          color: ${settings.colors.textBlack};
        }
        .ant-steps-item-process
          > .ant-steps-item-container
          > .ant-steps-item-icon {
          background-color: ${settings.colors.holisticGreen};
        }
        .ant-steps-item-process .ant-steps-item-icon {
          border-color: ${settings.colors.holisticGreen};
        }
        .ant-steps-item-process
          > .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title {
          color: ${settings.colors.holisticDarkGreen};
        }
        .ant-form-item-label > label {
          color: ${settings.colors.holisticDarkGreen};
        }
        // .ant-btn-background-ghost.ant-btn-primary {
        //   color: ${settings.colors.holisticDarkGreen};
        //   border-color: ${settings.colors.holisticDarkGreen};
        // }
        .ant-progress-text {
          font-weight: 500;
          font-size: 16px;
          margin-left: 8px;
          text-align: right;
        }
        .ant-progress-show-info .ant-progress-outer {
          margin-right: calc(-2em - 14px);
          padding-right: calc(2em + 4px);
        }
        .ant-progress-bg {
          background-color: ${settings.colors.holisticGreen};
        }
        .ant-steps-item-finish .ant-steps-item-icon {
          border-color: ${settings.colors.holisticGreen};
        }
        .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
          color: ${settings.colors.holisticGreen};
        }
        .ant-btn.ant-btn-normal {
          background-color: #dcdcdc;
          border-color: #dcdcdc;
        }
        .ant-btn.ant-btn-normal:hover {
          color: ${settings.colors.holisticDarkGreen};
        }
        .ant-btn.ant-btn-primary {
          background-color: ${settings.colors.holisticGreen};
          border-color: ${settings.colors.holisticGreen};
        }
        :where(.css-dev-only-do-not-override-yrrde3).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
          background-color: ${settings.colors.holisticDarkGreen};
          border-color: ${settings.colors.holisticDarkGreen};
        }
        :where(.css-dev-only-do-not-override-yrrde3).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
          border-color: ${settings.colors.holisticLightGreen};
          color: ${settings.colors.holisticGreen};
        }
      `}</style>
    </CSPage>
  );
}

export default SponsorAProject;
