import { Layout, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

function MyFooter() {
  const { i18n } = useTranslation();
  return (
    <Layout.Footer>
      <Text>AIMS ©2024</Text>
    </Layout.Footer>
  );
}

export default MyFooter;
