export const READ_HOLISTIC_PROJECTS = 'READ_HOLISTIC_PROJECTS';
export const CREATE_HOLISTIC_PROJECT = 'CREATE_HOLISTIC_PROJECT';
export const UPDATE_HOLISTIC_PROJECT = 'UPDATE_HOLISTIC_PROJECT';
export const DELETE_HOLISTIC_PROJECT = 'DELETE_HOLISTIC_PROJECT';

export const readHolisticProjectsAction = (
  // eslint-disable-next-line no-shadow
  holisticProjects,
  queryId = 'default',
) => ({
  type: READ_HOLISTIC_PROJECTS,
  payload: {
    holisticProjects,
    queryId,
  },
});

export const createHolisticProjectAction = (
  holisticProject,
  queryId = 'default',
) => ({
  type: CREATE_HOLISTIC_PROJECT,
  payload: {
    holisticProject,
    queryId,
  },
});

export const updateHolisticProjectAction = (
  holisticProject,
  queryId = 'default',
) => ({
  type: UPDATE_HOLISTIC_PROJECT,
  payload: {
    holisticProject,
    queryId,
  },
});

export const deleteHolisticProjectAction = (
  holisticProjectId,
  queryId = 'default',
) => ({
  type: DELETE_HOLISTIC_PROJECT,
  payload: {
    holisticProjectId,
    queryId,
  },
});

const initialHolisticProjects = { default: {} };

export function holisticProjects(state = initialHolisticProjects, action) {
  switch (action.type) {
    case READ_HOLISTIC_PROJECTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.holisticProjects.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_HOLISTIC_PROJECT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.holisticProject._id]: action.payload.holisticProject,
        },
      };
    case UPDATE_HOLISTIC_PROJECT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.holisticProject._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.holisticProject._id]: {
            ...current,
            ...action.payload.holisticProject,
          },
        },
      };
    }
    case DELETE_HOLISTIC_PROJECT: {
      const { holisticProjectId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [holisticProjectId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
