import settings from '../settings';

export const WINDOW_DIMS_CHANGED = 'WINDOW_DIMS_CHANGED';

// eslint-disable-next-line no-shadow
export const windowDimsChangedAction = (dimensions) => ({
  type: WINDOW_DIMS_CHANGED,
  payload: {
    dimensions,
  },
});

const initialWindowDimensions = {
  height: process.browser ? window.innerHeight : undefined,
  width: process.browser ? window.innerWidth : undefined,
};

export function windowDimensions(state = initialWindowDimensions, action) {
  switch (action.type) {
    case WINDOW_DIMS_CHANGED: {
      return action.payload.dimensions;
    }
    default:
      return state;
  }
}

const initialResponsiveMode =
  process.browser && window.innerWidth < settings.screenSizes.lg992
    ? 'mobile'
    : 'desktop';

export function responsiveMode(state = initialResponsiveMode, action) {
  switch (action.type) {
    case WINDOW_DIMS_CHANGED: {
      return action.payload.dimensions.width < settings.screenSizes.lg992
        ? 'mobile'
        : 'desktop';
    }
    default:
      return state;
  }
}
