export const READ_TRAINING_COURSES = 'READ_TRAINING_COURSES';
export const UPDATE_TRAINING_COURSE = 'UPDATE_TRAINING_COURSE';

// eslint-disable-next-line no-shadow
export const readTrainingCoursesAction = (courses) => ({
  type: READ_TRAINING_COURSES,
  payload: {
    courses,
  },
});

export const updateTrainingCourseAction = (course) => ({
  type: UPDATE_TRAINING_COURSE,
  payload: {
    course,
  },
});

const initialCourses = [];

export function courses(state = initialCourses, action) {
  switch (action.type) {
    case READ_TRAINING_COURSES: {
      const newState = { ...state };
      action.payload.courses.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case UPDATE_TRAINING_COURSE:
      return {
        ...state,
        [action.payload.course._id]: action.payload.course,
      };
    default:
      return state;
  }
}
