import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import { updateHolisticProjectAction } from '../../redux-store/holistic-projects-store';

const query = gql`
  query HolisticProjectForUser($_id: ID!) {
    holisticProjectForUser(_id: $_id) {
      _id
      applicationId
      regionCode
      region
      peopleGroupIds
      peopleGroups {
        _id
        population
        continentCode
        continent
        region
        regionCode
        countryCode
        country
        nameAcrossCountries
        primaryLanguage
        officialLanguage
        primaryReligion
        religionSubdivision
        primaryThumbnailUrl
        updatedAt
      }
      projectName
      typeOfProject
      desc
      stage
      status
      projectNo
      tags {
        key
        color
      }
      primaryThumbnailUrl
      createdAt
      updatedAt
      deletedAt
      cumulativeStats {
        numMiraclesReported
        numProgressReports
      }
    }
  }
`;

const extract = (data) => data.holisticProjectForUser;

function useHolisticProjectForAdopter(_id, queryId = 'default') {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !_id,
    showError: true,
  });
  const data = useSingleSomethingA10('holisticProjects', _id, queryId);

  return {
    data,
    loading,
    refetch,
  };
}

export default useHolisticProjectForAdopter;
