import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialMouSectionMutation } from './constants';
import { updateMouAction } from '../../redux-store/mous-store';
import SelectFont from '../loan-agreements/SelectFont';

const { Title, Text, Paragraph } = Typography;

const InitialMouModal = ({ visible, queryId, onSuccess, onCancel }) => {
  const { section, party, loanAgreementId: mouId } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [initialMouSection] = useMutation(initialMouSectionMutation);
  const dispatch = useDispatch();

  const handleFinish = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        const result = await initialMouSection({
          variables: {
            initials: {
              mouId,
              initials: {
                sectionId: section._id,
                partyId: party._id,
                initials: values.initials,
                fontName: values.fontName,
                date: new Date().toISOString(),
              },
            },
          },
        });
        dispatch(
          updateMouAction(result.data.initialMouSectionForUser.mou, queryId),
        );
        notification.success({
          message: 'Success',
          description: 'Initials Saved',
        });
        localStorage.setItem('signatureFont', values.fontName);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [
      setError,
      dispatch,
      queryId,
      initialMouSection,
      mouId,
      onSuccess,
      section,
      party,
    ],
  );

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={!!visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        bodyStyle={{ paddingTop: 48 }}
      >
        {visible && (
          <>
            <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
              {`Initial Section "${section && section.title}"`}
            </Title>
            <Form onFinish={handleFinish} layout="vertical">
              <Form.Item
                label="Font"
                name="fontName"
                rules={[{ required: true, message: 'This field is required ' }]}
                initialValue={localStorage.getItem('signatureFont')}
              >
                <SelectFont disabled={loading} />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.fontName !== curValues.fontName
                }
                noStyle
              >
                {({ getFieldValue }) => (
                  <Form.Item
                    label="Type your initials here"
                    name="initials"
                    rules={[
                      { required: true, message: 'This field is required ' },
                    ]}
                  >
                    <Input
                      style={{
                        fontFamily: getFieldValue('fontName') || undefined,
                        fontSize: getFieldValue('fontName') ? 24 : undefined,
                      }}
                      placeholder="Your initials here"
                      disabled={loading}
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item>
                <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
                  {`By adding your initials you are declaring that you are ${party.contactName} and that you have read and
                  agree with this section in its entirety.`}
                </Paragraph>
                <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
                  {`You can not undo this action.`}
                </Paragraph>
                <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
                  {`E-signatures are completely legal and binding.`}
                </Paragraph>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  style={{
                    marginRight: -8,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={onCancel}
                    htmlType="button"
                    disabled={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Initial
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {error && (
              <Text type="danger" style={{ marginTop: 16 }}>
                {error}
              </Text>
            )}
          </>
        )}
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default InitialMouModal;
