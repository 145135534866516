import { gql } from '@apollo/client';
import settings from '../../../settings';

export const UpgQuarterlyReportStatuses = {
  APPROVED: {
    key: 'APPROVED',
    label: 'Approved',
    color: settings.colors.success,
  },
  REJECTED: {
    key: 'REJECTED',
    label: 'Rejected',
    color: settings.colors.error,
  },
  PENDING: {
    key: 'PENDING',
    label: 'Pending',
    color: settings.colors.warning,
  },
  DRAFT: {
    key: 'DRAFT',
    label: 'Draft',
    color: settings.colors.error,
  },
  HOLD: {
    key: 'HOLD',
    label: 'Pending',
    color: settings.colors.warning,
  },
};

export const createUpgQuarterlyReportMutation = gql`
  mutation CreateUpgQuarterlyReportForUser(
    $report: UpgQuarterlyReportCreateInputForUser!
  ) {
    createUpgQuarterlyReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateUpgQuarterlyReportMutation = gql`
  mutation UpdateUpgQuarterlyReportForUser(
    $report: UpgQuarterlyReportUpdateInputForUser!
  ) {
    updateUpgQuarterlyReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitUpgQuarterlyReportMutation = gql`
  mutation SubmitUpgQuarterlyReportForUser($reportId: ID!) {
    submitUpgQuarterlyReportForUser(reportId: $reportId)
  }
`;

export const unsubmitUpgQuarterlyReportMutation = gql`
  mutation UnsubmitUpgQuarterlyReportForUser($reportId: ID!) {
    unsubmitUpgQuarterlyReportForUser(reportId: $reportId)
  }
`;
