import { PrinterOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';

const { Title, Text, Paragraph } = Typography;

const PrintMouBtn = () => {
  return (
    <>
      <Button icon={<PrinterOutlined />} onClick={() => window.print()}>
        Print Agreement
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default PrintMouBtn;
