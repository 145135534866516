import { gql } from '@apollo/client';
import React from 'react';
import { Typography } from 'antd';
import { userProfileAttributes } from '../../auth/constants';

const { Text } = Typography;

export const protectedPeopleGroupAttributes = gql`
  {
    _id
    population
    continentCode
    continent
    region
    regionCode
    countryCode
    country
    affinityBloc
    affinityBlocId
    peopleCluster
    peopleClusterId
    peopleGroupId
    nameInCountry
    nameAcrossCountries
    primaryLanguage
    primaryLanguageId
    officialLanguage
    officialLanguageId
    bibleStatus
    primaryReligion
    primaryReligionId
    religionSubdivision
    religionSubdivisionId
    jpScaleId
    jpScale
    jpUrl
    jpPhotoUrl
    jpSummary
    latlon
    summary
    summaryText
    primaryPhotoId
    primaryThumbnailUrl
    focusLevelNo
    numAdoptionsAvailable
    suggestedAdoptionLevel
    createdAt
    updatedAt
    deletedAt
  }
`;

export const publicPeopleGroupAttributes = gql`
  {
    _id
    population
    region
    nameAcrossCountries
    peopleGroupId
    primaryReligion
    primaryReligionId
    jpScale
    jpUrl
    jpPhotoUrl
    jpSummary
    primaryPhotoId
    primaryThumbnailUrl
    numAdoptionsAvailable
    suggestedAdoptionLevel
    createdAt
    updatedAt
    deletedAt
  }
`;

export const selectAUpgToAdoptQuery = gql`
  query AllPublicPeopleGroups(
    $first: Int
    $after: String
    $filters: PeopleGroupFiltersForPublic
    $sortBy: [SortBy]
  ) {
    allPeopleGroupsForPublic(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${publicPeopleGroupAttributes}
      }
    }
  }
`;

export const extractPublicPeopleGroups = (data) =>
  data && data.allPeopleGroupsForPublic;

export const publicPeopleGroupQuery = gql`
  query PublicPeopleGroup($_id: ID!) {
    peopleGroupForPublic(_id: $_id) ${publicPeopleGroupAttributes}
  }
`;

export const extractPublicPeopleGroup = (data) =>
  data && data.peopleGroupForPublic;

export const completeOnlineAdoptionMutation = gql`
  mutation CompleteOnlineAdoption($paymentId: ID!) {
    completeOnlineAdoption(paymentId: $paymentId) {
      adoption {
        _id
        contactId
        adopterName
        peopleGroupId
        regionCode
        level
        hasFieldWorker
        hasRegionalCoord
        status
        amount
        donationFrequency
        startDate
        createdAt
        updatedAt
        deletedAt
      }
      peopleGroup ${protectedPeopleGroupAttributes}
      payment {
        _id
        status
        usdCentsAmount
        paymentMethodType
        payerId
        payerName
        payerEmail
        fundId
        fundName
        ppCreatedOn
        ppUpdatedOn
        ppReceivedOn
        givenOn
        createdAt
        updatedAt
        deletedAt
      }
      recurringPayment {
        _id
        payerId
        payerName
        payerEmail
        amount
        usdCentsAmount
        fundId
        fundName
        startDate
        frequency
        createdAt
        updatedAt
        deletedAt
      }
      userInvite {
        _id
      }
    }
  }
`;

export const PaymentFrequencyTypes = {
  FirstAndFifteenth: {
    key: 'FirstAndFifteenth',
    label: 'every 1st and 15th',
  },
  FiveMinutes: {
    key: 'FiveMinutes',
    label: 'every 5 minutes',
  },
  Fortnightly: {
    key: 'Fortnightly',
    label: 'every 2 weeks',
  },
  FourHours: {
    key: 'FourHours',
    label: 'every 4 hours',
  },
  Monthly: {
    key: 'Monthly',
    label: '/ Month',
  },
  OneMinute: {
    key: 'OneMinute',
    label: '/ Minute',
  },
  Other: {
    key: 'Other',
    label: '',
  },
  Quarterly: {
    key: 'Quarterly',
    label: '/ Quarter',
  },
  SemiYearly: {
    key: 'SemiYearly',
    label: 'every 6 months',
  },
  Weekly: {
    key: 'Weekly',
    label: '/ Week',
  },
  Yearly: {
    key: 'Yearly',
    label: '/ Year',
  },
};

export const FundingLevels = {
  ADOPTER_LV1: {
    value: 1,
    key: 'ADOPTER_LV1',
    label: '$30 per month',
    longLabel: function LongLabel(recurring) {
      return (
        <>
          <Text strong>
            {recurring ? `$30 per month ($360 TOTAL)` : `$360 one-time`}
          </Text>{' '}
          - Partially Sponsor 1 Missionary
        </>
      );
    },
    defaultMonthlyAmount: 30,
  },
  ADOPTER_LV2: {
    value: 2,
    key: 'ADOPTER_LV2',
    label: '$100 per month',
    longLabel: function LongLabel(recurring) {
      return (
        <>
          <Text strong>
            {recurring ? `$100 per month ($1200 TOTAL)` : `$1200 one-time`}
          </Text>{' '}
          - Sponsor 1 Missionary
        </>
      );
    },
    defaultMonthlyAmount: 100,
  },
  ADOPTER_LV3: {
    value: 3,
    key: 'ADOPTER_LV3',
    label: '$300 per month',
    longLabel: function LongLabel(recurring) {
      return (
        <>
          <Text strong>
            {recurring ? `$300 per month ($3600 TOTAL)` : `$3600 one-time`}
          </Text>{' '}
          - Sponsor 3 Missionaries
        </>
      );
    },
    defaultMonthlyAmount: 300,
  },
};

export const publicAdoptionAttributes = gql`
  {
    _id
    peopleGroupId
    regionCode
    level
    hasFieldWorker
    hasRegionalCoord
    status
    amount
    unfundedAt
    createdAt
    updatedAt
    deletedAt
  }
`;

export const aUpgFormAllAdoptionsQuery = gql`
  query AUpgFormAllAdoptions(
    $first: Int
    $after: String
    $filters: AdoptionFiltersForPublic
    $sortBy: [SortBy]
  ) {
    allAdoptionsForPublic(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${publicAdoptionAttributes}
      }
    }
  }
`;

export const extractPublicAdoptions = (data) =>
  data && data.allAdoptionsForPublic;
