import { Breadcrumb, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ChooseAccount from './ChooseAccount';

const { Title, Text } = Typography;

function CSPageHeader({
  title,
  parents,
  topActions = null,
  titleComponent = null,
  style = null,
}) {
  return (
    <div id="csPageHeader" className="header" style={style}>
      <div className="breadcrumb-row">
        <Breadcrumb style={{ margin: '16px 0' }}>
          {parents &&
            parents.map((parent) => (
              <Breadcrumb.Item key={parent.label}>
                {parent.link ? (
                  <Link to={parent.link}>{parent.label}</Link>
                ) : (
                  <Text>{parent.label}</Text>
                )}
              </Breadcrumb.Item>
            ))}
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <ChooseAccount />
      </div>
      {titleComponent}
      {titleComponent === undefined && (
        <Title className="cs-header-title" style={{ marginBottom: 8 }}>
          {title}
        </Title>
      )}
      <div className="top-actions">{topActions}</div>
      <style jsx>{`
        .header {
          margin-bottom: 16px;
          padding-top: 16px;
        }
        .breadcrumb-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        }
        .back-actions {
          height: 50px;
          margin-left: -15px;
        }
        .top-actions {
          margin-left: -15px;
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
          .breadcrumb-row {
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      `}</style>
      <style jsx global>{`
        .cs-header-title {
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
        }
      `}</style>
    </div>
  );
}

export default CSPageHeader;
