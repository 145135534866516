import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { Card, List, Skeleton, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ellipsis from 'text-ellipsis';

import settings from '../../settings';
import useDefaultProjectPhoto from './use-default-project-photo';

const { Title, Text } = Typography;

function AllProjectsListItem({ holisticProject, loading }) {
  const defaultPhoto = useDefaultProjectPhoto(holisticProject);
  const photoUrl =
    (holisticProject && holisticProject.primaryThumbnailUrl) || defaultPhoto;

  return (
    <List.Item>
      {holisticProject && (
        <Link to={`/holistic-projects/${holisticProject._id}/info`}>
          <Card
            className="pg-card"
            cover={
              <img
                src={photoUrl || defaultPhoto}
                alt={holisticProject.projectName}
              />
            }
          >
            <Title level={5}>
              {HolisticProjectTypes[holisticProject.typeOfProject].label}
            </Title>
            <Title level={3} style={{ marginBottom: 0, marginTop: 0 }}>
              {holisticProject.projectName}
            </Title>
            <div className="subtitle">
              {holisticProject.peopleGroups &&
                holisticProject.peopleGroups.map((pg, index) => (
                  <Text
                    key={index}
                    style={{
                      color: settings.colors.darkGray,
                      display: 'block',
                    }}
                  >
                    {pg.nameAcrossCountries} - {pg.country}
                  </Text>
                ))}
            </div>
            {loading && <Skeleton avatar active />}
            <Text>{ellipsis(holisticProject.desc, 200)}</Text>
          </Card>
        </Link>
      )}
      <style jsx>{`
        .subtitle {
          margin-bottom: 16px;
        }
      `}</style>
      <style jsx global>{`
        .pg-card {
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
        }
      `}</style>
    </List.Item>
  );
}

export default AllProjectsListItem;
