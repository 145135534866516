import React from 'react';
import { Helmet } from 'react-helmet';

function AuthPageTemplate({ title, children }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '10vh',
        paddingBottom: '10vh',
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}

export default AuthPageTemplate;
