import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { authStateChangedAction } from '../redux-store/auth-store';
import { userProfileAttributes } from '../pages/auth/constants';

const myProfileUpdatesForUserSubscription = gql`
subscription MyProfileUpdatesForUser {
  myProfileUpdatesForUser {
    crud
    id
    new ${userProfileAttributes}
  }
}`;

function useProfileUpdates() {
  const dispatch = useDispatch();
  const result = useSubscription(myProfileUpdatesForUserSubscription, {
    fetchPolicy: 'network-only',
    onSubscriptionData: ({ client, subscriptionData: resp }) => {
      const update = resp.data?.myProfileUpdatesForUser;
      try {
        if (update) {
          switch (update.crud) {
            case 'update':
              dispatch(authStateChangedAction(update.new));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return result;
}

export default useProfileUpdates;
