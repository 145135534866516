import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { EyeOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Card, Empty, List, Skeleton, Typography } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import useAccount from '../../shared/use-account';
import { MouRoles, allMousQuery, extractMous } from './constants';

const { Text } = Typography;

function MouListItem({ mou, locale, myContactId }) {
  const signed = useMemo(() => {
    const myParty = mou.parties.find((p) => p.contactId === myContactId);
    if (myParty && myParty.signature && myParty.date) {
      return true;
    }
    return false;
  }, [mou, myContactId]);
  return (
    <List.Item>
      <Card
        style={{
          width: '100%',
          boxShadow: `0px 0px 8px ${settings.colors.darkGray}`,
        }}
        bodyStyle={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ flex: 1 }}>
          <div>
            <Text
              style={{
                color: settings.colors[signed ? 'success' : 'blue'],
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              {signed ? 'Signed' : 'Ready to Sign'}
            </Text>
          </div>
          <div>
            <Text style={{ fontSize: 14 }}>{mou.contactName}</Text>
          </div>
          {MouRoles[mou.role] && (
            <div>
              <Text style={{ fontSize: 16, fontWeight: 600 }}>
                {MouRoles[mou.role].label}
              </Text>
            </div>
          )}
          <div>
            <Text style={{ fontSize: 16 }}>{mou.peopleGroupName}</Text>
          </div>
        </div>
        <div>
          <Link to={`/mous/view/${mou._id}`}>
            <Button icon={signed ? <EyeOutlined /> : <FormOutlined />}>
              {signed ? 'View' : 'Sign'}
            </Button>
          </Link>
        </div>
      </Card>
    </List.Item>
  );
}

const queryId = 'listMousPage';

function ListMousPage() {
  const account = useAccount();
  const [numShown, setNumShown] = useState(settings.pageSize);
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    isTemplate: false,
    signeeId: account._id,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: mous,
    search: mousSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allMousQuery,
    extract: extractMous,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <>
      <CSPage title="AIMS.org | Partnership Commitment Forms">
        <CSPageHeader title="Partnership Commitment Forms" />
        {loading && !mous.length ? (
          <List
            dataSource={[0, 0, 0, 0]}
            grid={{ column: 1, gutter: 8 }}
            renderItem={(item) => (
              <List.Item>
                <Card>
                  <Skeleton active />
                </Card>
              </List.Item>
            )}
          />
        ) : (
          <List
            dataSource={mous.slice(0, numShown)}
            renderItem={(item) => (
              <MouListItem
                mou={item}
                locale={locale}
                myContactId={account._id}
              />
            )}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="We couldn't find any active Partnership Commitment Forms."
                />
              ),
            }}
            loadMore={
              (numShown < mous.length || hasNextPage) && (
                <div className="load-more">
                  <Button
                    onClick={() => {
                      setNumShown(numShown + settings.smallPage);
                      if (numShown + settings.smallPage > mous.length) {
                        next();
                      }
                    }}
                  >
                    Show More
                  </Button>
                </div>
              )
            }
          />
        )}
      </CSPage>
    </>
  );
}

export default ListMousPage;
