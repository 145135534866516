import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { Table, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import settings from '../../settings';
import useUpgStats from './use-upg-stats';

const { Title, Text } = Typography;

function PeopleGroupAdoptionStats({ peopleGroupId }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);

  const { loading, data: adoptionStats } = useUpgStats(
    { peopleGroupId },
    `${peopleGroupId}UpgStats`,
  );

  return (
    <>
      <div style={{ marginBottom: 16 }} />
      <Table
        dataSource={adoptionStats}
        loading={!adoptionStats.length && loading}
        pagination={{ ...pagination, total: adoptionStats.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1000 }}
      >
        <Table.Column
          title="Quarter"
          key="quarter"
          render={(text, record) => {
            return (
              <Text style={{ whiteSpace: 'nowrap' }}>
                {getQuarterLabel(record.quarter)}
              </Text>
            );
          }}
        />
        <Table.Column
          title="Holistic Projects"
          dataIndex="numHolisticProject"
          key="numHolisticProject"
        />
        <Table.Column
          title="Active Adoptions"
          dataIndex="numActiveAdoptions"
          key="numActiveAdoptions"
        />
        <Table.Column
          title="Field Workers"
          dataIndex="numFieldWorkers"
          key="numFieldWorkers"
        />
        <Table.Column
          title="Churches Planted"
          dataIndex="numChurchesPlanted"
          key="numChurchesPlanted"
        />
        <Table.Column
          title="First Time Hearers"
          dataIndex="numHeardForFirstTime"
          key="numHeardForFirstTime"
        />
        <Table.Column
          title="Salvations"
          dataIndex="numSalvations"
          key="numSalvations"
        />
        <Table.Column
          title="Water Baptisms"
          dataIndex="numWaterBaptisms"
          key="numWaterBaptisms"
        />
        <Table.Column
          title="Testimonies"
          dataIndex="numMiracles"
          key="numMiracles"
        />
        <Table.Column
          title="Prayer Requests"
          dataIndex="numPrayerRequests"
          key="numPrayerRequests"
        />
      </Table>
    </>
  );
}

export default PeopleGroupAdoptionStats;
