import { getFileIcon } from '@aims/shared/shared/icons';
import {
  DownloadOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Form,
  Input,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useRef, useState } from 'react';
import settings from '../../../settings';
import { titleCase } from '../../../shared/utils';
import LanguagesFormItem from '../training-languages/LanguagesFormItem';
import useTrainingFiles from './use-training-files';

const { Text, Title } = Typography;

const ListTrainingFiles = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const [form] = Form.useForm();

  const filters = useRef({ courseId: 'unassigned', language: 'english' });
  const {
    data: trainingFiles,
    refetch,
    search,
    loading,
  } = useTrainingFiles(filters.current, 'listTrainingFiles');

  const onSearchChange = useCallback(
    (changed) => {
      console.log('changed', changed);
      if (changed && changed.search !== undefined) {
        search(changed.search);
      } else {
        filters.current = {
          ...filters.current,
          ...changed,
        };
        refetch();
      }
    },
    [search, refetch],
  );

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);

  const handleDownload = (file) => {
    const link = document.createElement('a');
    link.download = file.file.filename;
    link.href = file.file.url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ paddingTop: 16 }}>
      <div className="top-actions">
        <Form
          layout="inline"
          form={form}
          onValuesChange={onSearchChange}
          initialValues={filters.current}
        >
          <Form.Item name="search" style={{ marginBottom: 8 }}>
            <Input
              placeholder="Search"
              suffix={<SearchOutlined />}
              style={{ maxWidth: 500, minWidth: 300 }}
            />
          </Form.Item>
          <LanguagesFormItem
            name="language"
            style={{ maxWidth: 400, minWidth: 200, marginBottom: 8 }}
          />
        </Form>
        <Button
          icon={<ReloadOutlined />}
          onClick={refetch}
          style={{ marginBottom: 8 }}
        >
          Refresh
        </Button>
      </div>
      <Table
        dataSource={trainingFiles}
        pagination={{ ...pagination, total: trainingFiles.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 800 }}
        locale={{
          emptyText:
            !trainingFiles.length && loading ? (
              <Spin spinning />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Files"
              />
            ),
        }}
      >
        <Column
          title="File Name"
          dataIndex="fileName"
          sorter={{
            compare: (a, b) => {
              return a.fileName.localeCompare(b.fileName);
            },
            multiple: 2,
          }}
          sortDirections={['ascend', 'descend']}
          render={(text, record) => {
            const icon = getFileIcon(record.file.filename, {
              fontSize: 18,
              marginRight: 8,
            });
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {icon}
                <Text>{record.fileName}</Text>
              </div>
            );
          }}
        />
        <Column
          title="Language"
          dataIndex="language"
          sorter={{
            compare: (a, b) => {
              return (a.language || '').localeCompare(b.language || '');
            },
            multiple: 1,
          }}
          render={(text, record) => {
            return record.language && titleCase(record.language);
          }}
        />
        <Column
          title="Description"
          dataIndex="desc"
          render={(text, record) => {
            return record.desc;
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {record.file && record.file.url && (
                <Tooltip title="Download">
                  <Button
                    onClick={() => handleDownload(record)}
                    icon={<DownloadOutlined />}
                  />
                </Tooltip>
              )}
            </Space>
          )}
        />
      </Table>
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
};

export default ListTrainingFiles;
