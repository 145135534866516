import ContentEditableInput from '@aims/shared/components/ContentEditableInput';
import PeopleGroupPieChart from '@aims/shared/people-groups/PeopleGroupPieChart';
import { List, Typography } from 'antd';
import React, { useMemo } from 'react';
import settings from '../../settings';
import useResponsiveMode from '../../shared/use-reponsive-mode';
import RecentMiracles from './RecentMiracles';
import RecentPrayerRequests from './RecentPrayerRequests';

const { Title, Text, Paragraph } = Typography;

const PeopleGroupInfo = ({ peopleGroupId, details }) => {
  const cumulativeStats = useMemo(() => {
    if (details && details.cumulativeStats) {
      return [
        {
          label: 'Field Workers',
          value: details.numFieldWorkers,
        },
        {
          label: 'Holistic Projects',
          value: details.numHolisticProjects,
        },
        {
          label: 'Churches Planted',
          value: details.cumulativeStats.numChurchesPlanted,
        },
        {
          label: 'Salvations',
          value: details.cumulativeStats.numSalvations,
        },
        {
          label: 'Water Baptisms',
          value: details.cumulativeStats.numWaterBaptisms,
        },
        {
          label: 'Heard the gospel for the first time',
          value: details.cumulativeStats.numHeardForFirstTime,
        },
        {
          label: 'Miracles Reported',
          value: details.cumulativeStats.numMiraclesReported,
        },
      ];
    }
    return [
      {
        label: 'Field Workers',
        value: (details && details.numFieldWorkers) || 0,
      },
      {
        label: 'Holistic Projects',
        value: (details && details.numHolisticProjects) || 0,
      },
      {
        label: 'Churches Planted',
        value: 0,
      },
      {
        label: 'Salvations',
        value: 0,
      },
      {
        label: 'Water Baptisms',
        value: 0,
      },
      {
        label: 'Heard the gospel for the first time',
        value: 0,
      },
      {
        label: 'Miracles Reported',
        value: 0,
      },
    ];
  }, [details]);

  const responsiveMode = useResponsiveMode();

  return (
    <div>
      {details?.summary && (
        <>
          <Title level={3}>Description</Title>
          <ContentEditableInput
            readOnly
            value={details?.summary}
            style={{ backgroundColor: 'white', padding: 24 }}
          />
          <div style={{ height: 32 }} />
        </>
      )}
      <Title level={3} style={{ marginBottom: 32 }}>
        Current Progress
      </Title>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div
          style={{
            minWidth: responsiveMode === 'mobile' ? '100%' : 450,
            marginBottom: 32,
            marginRight: responsiveMode === 'mobile' ? 0 : 16,
          }}
        >
          <PeopleGroupPieChart
            peopleGroup={details}
            style={{
              height: responsiveMode === 'mobile' ? 160 : 200,
              marginBottom: 0,
            }}
            labelStyle={{ fontSize: '6px' }}
          />
        </div>
        <div
          style={{
            backgroundColor: 'white',
            padding: 24,
            paddingBottom: 8,
            marginBottom: 48,
            flex: 1,
          }}
        >
          <Paragraph>
            Cumulative progress reported by AIMS field workers
          </Paragraph>
          <List
            dataSource={cumulativeStats}
            grid={{
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 3,
              gutter: 8,
            }}
            rowKey="label"
            renderItem={(item) => (
              <List.Item>
                <Text
                  style={{
                    fontWeight: 'bold',
                    color: settings.colors.primary,
                    marginRight: 8,
                  }}
                >
                  {`${item.label}: `}
                </Text>
                <Text>{item.value}</Text>
              </List.Item>
            )}
          />
        </div>
      </div>

      <RecentPrayerRequests
        filters={{ peopleGroupId }}
        queryId={`${peopleGroupId}PrayerRequests`}
      />
      <RecentMiracles
        filters={{ peopleGroupId }}
        queryId={`${peopleGroupId}Miracles`}
      />
    </div>
  );
};

export default PeopleGroupInfo;
