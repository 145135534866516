import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

const query = gql`
  query CanCreateHolisticQuarterlyReport($holisticProjectId: ID!) {
    canCreateHolisticQuarterlyReport(holisticProjectId: $holisticProjectId)
  }
`;

function useCanCreateHolisticQuarterlyReport(holisticProjectId) {
  const { data, loading, error } = useQuery(query, {
    variables: { holisticProjectId },
  });
  const result = data?.canCreateHolisticQuarterlyReport;
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
  return { loading, result };
}

export default useCanCreateHolisticQuarterlyReport;
