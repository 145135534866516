import { gql } from '@apollo/client';
import { useCallback, useMemo, useRef, useState } from 'react';
import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import { readAnythingsAction } from '../../user-ui/src/redux-store/anything-store';
import settings from '../../user-ui/src/settings';

const query = gql`
  query AllMiraclesForUser(
    $first: Int
    $after: String
    $filters: MiracleFilters
    $sortBy: [SortBy]
  ) {
    allMiraclesForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          desc
          reportId
          reportType
          peopleGroupId
          peopleGroup {
            _id
            country
            regionCode
            nameAcrossCountries
            primaryThumbnailUrl
          }
          holisticProjectId
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const extract = (data) => data.allMiraclesForUser;

function useMiracles(filters, queryId = 'useMiracles') {
  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading, refetch } = useManyLocalM20({
    query,
    extract,
    readAction: readAnythingsAction,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const defaultSort = useMemo(() => {
    return (a, b) => {
      return b.createdAt?.localeCompare(a.createdAt);
    };
  }, []);
  const filterFunc = useCallback(
    (a) => {
      if (filters.peopleGroupId && filters.peopleGroupId !== a.peopleGroupId) {
        return false;
      }
      if (
        filters.holisticProjectId &&
        filters.holisticProjectId !== a.holisticProjectId
      ) {
        return false;
      }
      return true;
    },
    [filters],
  );
  const { data, search } = useManySomethingsM20({
    table: 'things',
    sortByFunc: defaultSort,
    searchKeys: ['_id', 'desc'],
    queryId,
    filterFunc,
  });
  return {
    loading,
    search,
    data,
    refetch,
  };
}

export default useMiracles;
