import { Button, Empty, List } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import settings from '../../settings';
import ViewPhotoModal from './ViewPhotoModal';
import usePeopleGroupPhotos from './use-people-group-photos';

function PhotoCard({ photo, setShowViewPhotoModel }) {
  return (
    <div>
      <Button
        onClick={() => setShowViewPhotoModel(photo)}
        style={{ padding: 0, height: 'unset', width: '100%' }}
      >
        <div className="container">
          <div className="card-default">
            <div className="card-default-letter">
              {photo.name && photo.name[0].toUpperCase()}
            </div>
          </div>
          <div
            className="card-image"
            style={{ backgroundImage: `url("${photo.photoUrl}")` }}
          />
        </div>
      </Button>
      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          position: relative;
        }
        .card-default {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          background-color: ${settings.colors.darkGray};
          display: flex;
          justify-content: center;
        }
        .card-default-letter {
          height: 100%;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          color: white;
        }
        .card-image {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
      `}</style>
    </div>
  );
}

function PeopleGroupPhotos() {
  const [showViewPhotoModel, setShowViewPhotoModel] = useState(null);
  const { _id } = useParams();

  const { data: details, loading } = usePeopleGroupPhotos(
    _id,
    'peopleGroupPhotos',
  );

  const photos = useMemo(() => {
    if (details) {
      const _photos = [];
      if (details.photos) {
        details.photos.forEach((p) => {
          let photoUrl = (p.thumbnail && p.thumbnail.url) || p.url;
          _photos.push({
            _id: p._id,
            isJp: false,
            isPrimary: details.primaryPhotoId === p._id,
            photoUrl,
            originalUrl: p.url,
            name: details.nameAcrossCountries,
          });
        });
      }
      return _photos;
    }
    return [];
  }, [details]);

  return (
    <div className="container">
      <List
        dataSource={photos}
        renderItem={(item) => (
          <List.Item>
            <PhotoCard
              photo={item}
              loading={loading}
              setShowViewPhotoModel={setShowViewPhotoModel}
            />
          </List.Item>
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Photos"
            />
          ),
        }}
        grid={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
          gutter: 8,
        }}
      />
      <ViewPhotoModal
        visible={showViewPhotoModel}
        setVisible={setShowViewPhotoModel}
      />
    </div>
  );
}

export default PeopleGroupPhotos;
