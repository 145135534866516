import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, List, Skeleton, Typography } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import settings from '../../../../settings';
import SelectAProjectModal from './SelectAProjectModal';
import SelectProjectCard from './SelectProjectCard';

const { Paragraph } = Typography;

function ProjectList({
  projects,
  loading,
  resetFilters,
  showFilters,
  hasNextPage,
  next,
}) {
  const [numShown, setNumShown] = useState(settings.pageSize);
  const location = useLocation();
  const [showModal, setShowModal] = useState();

  return (
    <>
      {(!!projects.length || !loading) && (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={projects.slice(0, numShown)}
          renderItem={(project) => (
            <SelectProjectCard project={project} setShowModal={setShowModal} />
          )}
          locale={{
            emptyText: (
              <>
                <Paragraph style={{ fontSize: 16, marginBottom: 32 }}>
                  {`There are currently no available Holistic Projects with these specifications.`}
                </Paragraph>
                <div>
                  <Button type="primary" onClick={resetFilters}>
                    View available Holistic Projects
                  </Button>
                </div>
              </>
            ),
          }}
          rowKey="_id"
          loadMore={
            <>
              {projects.length > numShown && (
                <div className="bottom-actions">
                  <Button
                    onClick={() => setNumShown(numShown + settings.pageSize)}
                  >
                    Show More
                  </Button>
                </div>
              )}
              {projects.length <= numShown && hasNextPage && (
                <div className="bottom-actions">
                  <Button onClick={next}>Load More</Button>
                </div>
              )}
            </>
          }
        />
      )}
      {!projects.length && loading && (
        <List
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          dataSource={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
          renderItem={() => {
            return (
              <List.Item>
                <Card style={{ width: '100%' }}>
                  <Skeleton avatar active />
                </Card>
              </List.Item>
            );
          }}
        />
      )}
      <SelectAProjectModal visible={showModal} setVisible={setShowModal} />
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default ProjectList;
