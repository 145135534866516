import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import useApprovedBy from '@aims/shared/reports/use-approved-by';
import useSubmittedBy from '@aims/shared/reports/use-submitted-by';
import { getNumberValue } from '@aims/shared/shared/utils';
import { EditOutlined, EyeOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Card, List, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

function QuarterlyReportListItem({ report }) {
  const status = ReportApprovalStatuses[report.status];

  const submittedBy = useSubmittedBy(report);
  const approvedBy = useApprovedBy(report);

  const quarterLabel = getQuarterLabel(report && report.quarter);
  return (
    <List.Item style={{ width: '100%', border: 'none' }}>
      <Card
        style={{ width: '100%' }}
        styles={{ body: { textAlign: 'left' } }}
        bordered
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginBottom: 8,
          }}
        >
          <Title level={4}>
            <Space>
              {quarterLabel}
              <MinusOutlined />
              <Text style={{ color: status.color }}>{status.label}</Text>
            </Space>
          </Title>
          <Space>
            <Link to={`/training-reports/quarterly/view/${report._id}`}>
              <Button icon={<EyeOutlined />}>View</Button>
            </Link>
            {report.canEdit && (
              <Link to={`/training-reports/quarterly/edit/${report._id}`}>
                <Button icon={<EditOutlined />}>Edit</Button>
              </Link>
            )}
          </Space>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Training Events',
                value: getNumberValue(report.events?.length),
              },
              {
                label: 'Total People Trained',
                value: report.events?.reduce(
                  (prev, curr) => prev + curr.numPeopleTrained,
                  0,
                ),
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Testimonies',
                value: getNumberValue(report.miracles?.length),
              },
              {
                label: 'Prayer Requests',
                value: getNumberValue(report.prayerRequests?.length),
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
        </div>
        <div className="row3">
          <Text style={{ fontSize: 14 }}>{submittedBy}</Text>
        </div>
        {approvedBy && (
          <div className="row3">
            <Text style={{ fontSize: 14 }}>{approvedBy}</Text>
          </div>
        )}
      </Card>
    </List.Item>
  );
}

export default QuarterlyReportListItem;
