import { Card, Form, List, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import settings from '../../settings';
import AttachmentFormItem from './AttachmentFormItem';

const { Paragraph, Title, Text } = Typography;

function ReadOnlyAttachment({ attachment, parentId }) {
  const retrieveAttachments = useRef();
  return (
    <Card>
      <div
        style={{
          fontSize: 18,
          color: settings.colors.primary,
          marginBottom: 8,
        }}
      >
        {attachment.name}
      </div>
      <div>
        {attachment.file ? (
          <Form layout="vertical">
            <Form.Item>
              <AttachmentFormItem
                existingAttachments={attachment.file ? [attachment.file] : []}
                parentId={parentId}
                parentType={'loan-agreement'}
                retrieveAttachments={retrieveAttachments}
                readOnly
                name="fileIds"
              />
            </Form.Item>
          </Form>
        ) : (
          <Text style={{ fontSize: 14 }}>No File</Text>
        )}
      </div>
    </Card>
  );
}

function AttachmentsElement({ loanAgreement, loanAgreementId, handleSave }) {
  const [form] = Form.useForm();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    setAttachments((loanAgreement && loanAgreement.attachments) || []);
  }, [loanAgreement, form]);

  return (
    <>
      <div className="line-heading">
        <Title level={2}>Attachments</Title>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={attachments}
        renderItem={(attachment) => {
          return (
            <List.Item>
              <ReadOnlyAttachment
                attachment={attachment}
                parentId={loanAgreementId}
              />
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 16 }}>
                {`Attachments can be reference in the body of this agreement`}
              </Paragraph>
            </>
          ),
        }}
        rowKey="_id"
      />
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: center;
          background-color: ${settings.colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default AttachmentsElement;
