import React from 'react';
import AllLoanAgreementsList from '../loan-agreements/AllLoanAgreementsList';

function ProjectLoanAgreementsTab({ projectId }) {
  return (
    <AllLoanAgreementsList parentFilters={{ projectId }} skip={!projectId} />
  );
}

export default ProjectLoanAgreementsTab;
