import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

const query = gql`
  query CanCreateHolisticProposal {
    canCreateHolisticProposal
  }
`;

function useCanCreateHolisticProposal(peopleGroupId) {
  const { data, loading, error } = useQuery(query, {
    variables: { peopleGroupId },
  });
  const result = data?.canCreateHolisticProposal;
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
  return { loading, result };
}

export default useCanCreateHolisticProposal;
