import { useWindowDimensions } from './window-dimensions';

export default function useResponsiveMode(callback = undefined) {
  const dimensions = useWindowDimensions(callback);
  if (!dimensions) {
    return 'mobile';
  }
  if (dimensions.width < 992) {
    return 'mobile';
  }
  return 'desktop';
}
