import { gql } from '@apollo/client';

export const createHolisticQuarterlyReportMutation = gql`
  mutation CreateHolisticQuarterlyReportForUser(
    $report: HolisticQuarterlyReportCreateInputForUser!
  ) {
    createHolisticQuarterlyReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateHolisticQuarterlyReportMutation = gql`
  mutation UpdateHolisticQuarterlyReportForUser(
    $report: HolisticQuarterlyReportUpdateInputForUser!
  ) {
    updateHolisticQuarterlyReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitHolisticQuarterlyReportMutation = gql`
  mutation SubmitHolisticQuarterlyReportForUser($reportId: ID!) {
    submitHolisticQuarterlyReportForUser(reportId: $reportId)
  }
`;

export const unsubmitHolisticQuarterlyReportMutation = gql`
  mutation UnsubmitHolisticQuarterlyReportForUser($reportId: ID!) {
    unsubmitHolisticQuarterlyReportForUser(reportId: $reportId)
  }
`;
