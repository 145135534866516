import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import { updatePeopleGroupAction } from '../../redux-store/people-groups-store';

const query = gql`
  query PeopleGroupForUser($_id: ID!) {
    peopleGroupForUser(_id: $_id) {
      _id
      population
      continentCode
      continent
      region
      regionCode
      countryCode
      country
      affinityBloc
      affinityBlocId
      peopleCluster
      peopleClusterId
      peopleGroupId
      nameInCountry
      nameAcrossCountries
      primaryLanguage
      primaryLanguageId
      officialLanguage
      officialLanguageId
      bibleStatus
      primaryReligion
      primaryReligionId
      religionSubdivision
      religionSubdivisionId
      jpScaleId
      jpScale
      jpUrl
      jpPhotoUrl
      jpSummary
      latlon
      summary
      summaryText
      primaryThumbnailUrl
      focusLevelNo
      aimsStatusAdopted
      aimsStatusCoordinator
      aimsStatusFieldWorker
      aimsStatusFunding
      aimsStatusHolisticProject
      aimsStatusSalvations
      aimsStatusChurches
      aimsStatusMovements
      aimsStatusPercent
      cumulativeStats {
        numChurchesPlanted
        numHeardForFirstTime
        numSalvations
        numWaterBaptisms
        numMiraclesReported
      }
      numAdopters
      numAdoptionsAvailable
      numFieldWorkers
      numHolisticProjects
      numRegionalCoords
      suggestedAdoptionLevel
      createdAt
      updatedAt
    }
  }
`;

const extract = (data) => data.peopleGroupForUser;

export default function usePeopleGroup(_id, queryId = 'default') {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updatePeopleGroupAction,
    skip: !_id,
    showError: true,
  });
  const data = useSingleSomethingA10('peopleGroups', _id, queryId);

  return {
    data,
    loading,
    refetch,
  };
}
