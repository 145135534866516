import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { autocomplete } from '@aims/shared/shared/utils';
import { FilterOutlined } from '@ant-design/icons';
import { Button, Form, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BehaviorSubject, of } from 'rxjs';
import settings from '../../../settings';
import useResponsiveMode from '../../../shared/use-reponsive-mode';
import CatalogSteps from './CatalogSteps';
import PeopleGroupFilters from './PeopleGroupFilters';
import PeopleGroupList from './PeopleGroupList';
import { selectAUpgToAdoptQuery } from './constants';

const { Text, Title, Paragraph } = Typography;

const extractPeopleGroups = (data) => data.allPeopleGroupsForPublic;
const queryId = 'adoptionAUpgSelectAPg';

function AdoptAUpgSelectAPg({ step, title }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const responsive = useResponsiveMode();

  const filters = useRef({
    hasAdoptionsAvailable: true,
  });
  const nextFilters = useRef(filters.current);
  const sortBy = useRef([
    { key: '_score', order: 'DESC' },
    { key: 'numAdoptionsAvailable', order: 'DESC' },
  ]);
  const [showFilters, setShowFilters] = useState(false);

  const {
    error,
    data: peopleGroups,
    loading,
    search: peopleGroupSearch,
    hasNextPage,
    next,
    refetch,
  } = useManyRemoteM1({
    query: selectAUpgToAdoptQuery,
    extract: extractPeopleGroups,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    queryId,
    fetchPolicy: 'network-only',
  });

  const { data: featuredPeopleGroups, loading: featuredLoading } =
    useManyRemoteM1({
      query: selectAUpgToAdoptQuery,
      extract: extractPeopleGroups,
      first: settings.querySize,
      filters: {
        hasAdoptionsAvailable: true,
        focusLevelNo: 5,
      },
      sortBy: sortBy.current,
      queryId,
      fetchPolicy: 'network-only',
    });

  const term$ = useRef(null);
  useEffect(() => {
    term$.current = new BehaviorSubject(nextFilters.current);
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          let minPop;
          let maxPop;
          if (term.populationRange) {
            minPop = 10 ** (term.populationRange[0] / 10);
            maxPop = 10 ** (term.populationRange[1] / 10);
          }
          if (term.populationMax) {
            minPop = 0;
            maxPop = term.populationMax;
          }
          filters.current = {
            ...term,
            populationMax: undefined,
            populationRange:
              minPop !== undefined && maxPop !== undefined
                ? [minPop, maxPop]
                : undefined,
          };
          refetch();
          return of();
        }),
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        peopleGroupSearch(search);
      } else {
        nextFilters.current = {
          ...nextFilters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        if (term$ !== undefined) {
          term$.current.next(nextFilters.current);
        }
      }
    },
    [peopleGroupSearch],
  );

  const resetFilters = useCallback(() => {
    nextFilters.current = {
      hasAdoptionsAvailable: true,
    };
    if (term$ !== undefined) {
      term$.current.next(nextFilters.current);
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="filters">
          <div className="filters-inner">
            <Title level={2}>Filters</Title>
            <PeopleGroupFilters
              form={form}
              onFiltersChanged={onFiltersChanged}
              filters={filters}
            />
          </div>
        </div>
        <div className="pg-list">
          <CatalogSteps step={step} title={title} />
          {error && (
            <div>
              <Title type="danger" level={4}>
                Error
              </Title>
              <Text type="danger">{error}</Text>
            </div>
          )}
          <Title
            level={2}
            style={{
              // color: settings.colors.textBlack,
              textAlign: 'center',
            }}
          >
            {`SPONSOR A NATIVE MISSIONARY TO GO TO ONE OF THESE UNREACHED PEOPLE GROUPS`}
          </Title>
          {!loading && (
            <>
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: 24,
                  marginTop: responsive === 'mobile' ? 0 : 16,
                }}
              >
                {/* <Text>Or</Text>{' '} */}
                <Link to={`/partner/adopt-a-upg/__aims__${location.search}`}>
                  <Button size="small" type="primary" ghost>
                    Skip
                  </Button>
                </Link>{' '}
                <Text>{`If you'd like AIMS.org to select an Unreached People Group for you`}</Text>
              </div>
              <div
                className="filters-btn"
                style={{
                  justifyContent: 'center',
                  marginBottom: showFilters ? 16 : 32,
                }}
              >
                <Button
                  type="dashed"
                  onClick={() => setShowFilters(!showFilters)}
                  icon={<FilterOutlined />}
                  style={{ marginLeft: 16 }}
                >
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>
              </div>
            </>
          )}
          {showFilters && (
            <PeopleGroupFilters
              form={form}
              onFiltersChanged={onFiltersChanged}
              filters={filters}
            />
          )}
          {featuredLoading ||
            (!featuredLoading && featuredPeopleGroups.length > 0 && (
              <div className="featured">
                <Title
                  level={3}
                  style={{
                    color: settings.colors.textBlack,
                    textAlign: 'center',
                  }}
                >
                  {`Give where it's needed most`}
                </Title>
                <PeopleGroupList
                  peopleGroups={featuredPeopleGroups}
                  loading={featuredLoading}
                  oneRow
                  randomize
                />
              </div>
            ))}
          <PeopleGroupList
            peopleGroups={peopleGroups}
            loading={loading}
            next={next}
            resetFilters={resetFilters}
            hasNextPage={hasNextPage}
          />
        </div>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          margin-left: -16px;
          margin-right: -16px;
        }
        .filters {
          margin-left: 16px;
          margin-right: 16px;
          flex: 1;
          min-width: 300px;
        }
        .mini-steps {
          display: none;
        }
        .big-steps {
          display: block;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .mini-steps {
            display: block;
          }
          .big-steps {
            display: none;
          }
        }
        @media screen and (max-width: ${settings.screenSizes.xl1200}px) {
          .filters {
            display: none;
          }
        }
        .filters-inner {
          background: white;
          padding: 16px;
        }
        .pg-list {
          margin-left: 16px;
          margin-right: 16px;
          flex: 4;
        }
        .filters-btn {
          display: none;
        }
        @media screen and (max-width: ${settings.screenSizes.xl1200}px) {
          .filters-btn {
            display: flex;
          }
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .bottom-actions {
          display: flex;
          justify-content: center;
        }
        .featured {
          background-color: #e5bc86;
          margin-right: -16px;
          margin-left: -16px;
          padding-right: 16px;
          padding-left: 16px;
          padding-top: 16px;
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
}

export default AdoptAUpgSelectAPg;
