import { generateUuid } from '@aims/shared/shared/utils';
import { EditOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Empty, List } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import ReportListItem from './ReportListItem';
import { useReports } from './use-reports';

const queryId = 'holisticQuarterlyReportsList';

function ReportList({ projectId, user }) {
  const {
    loading,
    data: reports,
    refetch,
  } = useReports(
    {
      holisticProjectId: projectId,
    },
    queryId,
  );

  const [numShown, setNumShown] = useState(settings.pageSize);
  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <Link
          to={`/holistic-projects/${projectId}/reports/edit/${generateUuid()}`}
        >
          <Button icon={<EditOutlined />}>New Progress Report</Button>
        </Link>
        <div style={{ flex: 1 }} />
        <Button icon={<RedoOutlined />} onClick={refetch}>
          Refresh List
        </Button>
      </div>
      <List
        dataSource={reports.slice(0, numShown)}
        rowKey="_id"
        renderItem={(item) => (
          <ReportListItem
            report={item}
            holisticProjectId={projectId}
            locale={locale}
            canCreateReports
            user={user}
          />
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No reports"
            />
          ),
        }}
        loadMore={
          reports.length > numShown && (
            <div className="load-more">
              <Button onClick={() => setNumShown(numShown + settings.pageSize)}>
                Load More
              </Button>
            </div>
          )
        }
      />
    </>
  );
}

export default ReportList;
