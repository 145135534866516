import queryString from 'query-string';
import React, { useCallback } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import useQueryParams from '../../shared/use-query-params';
import ForgotPassword from './ForgotPassword';
import PublicPartnerRouter from './PublicPartnerRouter';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';
import SignUp from './SignUp';

function AuthRouter() {
  const query = useQueryParams();
  const location = useLocation();
  const redirectFromCatchAll = useCallback(() => {
    const { c, ...params } = query;
    const search = `?${queryString.stringify({
      c,
      p: location.pathname,
      s: JSON.stringify(params),
    })}`;
    return <Redirect to={`/${search}`} />;
  }, [query, location]);
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={`/sign-up${location.search}`} />}
      />
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password/:code" component={ResetPassword} />
      <Route exact path="/partner" component={PublicPartnerRouter} />
      <Route
        exact
        path="/partner/adopt-a-upg/:pgId?"
        component={PublicPartnerRouter}
      />
      <Route
        exact
        path="/partner/adopt-a-upg/:pgId/adopt"
        component={PublicPartnerRouter}
      />
      <Route
        exact
        path="/partner/sponsor-a-project/:projectId?/:page?"
        component={PublicPartnerRouter}
      />
      <Route
        exact
        path="/partner/adopt-a-upg/:pgId/redirect"
        component={PublicPartnerRouter}
      />
      {/* <Route
        exact
        path="/partner/adopt-a-upg/success"
        component={PublicPartnerRouter}
      /> */}
      <Route render={redirectFromCatchAll} />
    </Switch>
  );
}

export default AuthRouter;
