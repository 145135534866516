import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ReloadOutlined, UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space, Typography } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ChooseAccount from '../../components/ChooseAccount';
import { updateLoanAgreementAction } from '../../redux-store/loan-agreements-store';
import useQueryParams from '../../shared/use-query-params';
import ReviewButtons from './ReviewButtons';
import { SelectFontStyles } from './SelectFont';
import ViewLoanAgreement from './ViewLoanAgreement';
import { extractLoanAgreement, projectLoanAgreementQuery } from './constants';
import useAccount from '../../shared/use-account';

const { Title, Text, Paragraph } = Typography;

function DisplayError({ error }) {
  if (error && error.message.includes('Insufficient permissions')) {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Paragraph style={{ fontSize: 14 }}>
          {`It looks like you don't have permission to view this loan agreement.`}
        </Paragraph>
        <Paragraph style={{ fontSize: 14 }}>
          Try changing your account and refreshing the page.
        </Paragraph>
        <ChooseAccount layout="vertical" />
        <div style={{ marginTop: 16 }}>
          <Button onClick={() => location.reload()} icon={<ReloadOutlined />}>
            Refresh
          </Button>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Title type="danger" level={4}>
          Error
        </Title>
        <Text type="danger">{error.message}</Text>
      </div>
    );
  }
  return null;
}

function LoanAgreementPage() {
  const { loanAgreementId } = useParams();
  const queryId = 'sign-loan-agreement';

  const account = useAccount();

  const { loading, refetch, error } = useOneM1({
    variables: { _id: loanAgreementId },
    query: projectLoanAgreementQuery,
    extract: extractLoanAgreement,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateLoanAgreementAction,
    skip: !loanAgreementId,
    showError: true,
  });
  const loanAgreement = useSingleSomethingA10(
    'loanAgreements',
    loanAgreementId,
    queryId,
  );

  const _account = useRef();
  useEffect(() => {
    if (account && _account.current === undefined) {
      _account.current = account;
    }
    if (_account.current !== account) {
      _account.current = account;
      refetch();
    }
  }, [account, refetch]);

  return (
    <>
      <CSPage title="AIMS.org | Sign Loan Agreement">
        <CSPageHeader title="Sign Loan Agreement" />
        <DisplayError error={error} />
        <div className="container">
          {loanAgreement && (
            <ViewLoanAgreement
              loanAgreement={loanAgreement}
              queryId={queryId}
              refetch={refetch}
            />
          )}
          {loading && (
            <Space direction="vertical">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <SelectFontStyles />
      {!error && <ReviewButtons />}
      <style jsx>{`
        .container {
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
        }
        @media print {
          .container {
            max-width: unset;
            margin-left: unset;
            margin-right: unset;
          }
        }
      `}</style>
      <style jsx global>{`
        @media print {
          * {
            visibility: hidden;
          }
          #loanAgreement {
            visibility: visible;
            box-shadow: none !important;
          }
          #loanAgreement * {
            visibility: visible;
          }
          main.ant-layout-content.page {
            margin: 0px !important;
            padding: 0px !important;
            width: unset !important;
          }
          #verifyMyEmail,
          #mainNavHeader,
          #csPageHeader {
            display: none !important;
          }
        }
      `}</style>
    </>
  );
}

export default LoanAgreementPage;
