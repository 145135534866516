import { GoogleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import longLogo from '../../assets/long-logo-sm.png';
import settings from '../../settings';
import { AuthContext } from '../../shared/auth-context';
import useQueryParams from '../../shared/use-query-params';
import useScript from '../../shared/use-script';
import AuthPageTemplate from './AuthPageTemplate';

const { Title, Text, Paragraph } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const authContext = useContext(AuthContext);
  const location = useLocation();

  const onFinish = async (values) => {
    setLoading(true);
    setServerError(null);
    let error;
    try {
      error = await authContext.signIn(values);
    } catch (err) {
      error = err.message;
    }
    if (error) {
      setServerError(error);
      setLoading(false);
    }
  };

  const handleGoogleResponse = useCallback(
    async (response) => {
      // console.log('response', response);
      setLoading(true);
      let error;
      try {
        error = await authContext.signIntoUserWithGoogle(response.code);
      } catch (err) {
        error = err.message;
      }
      if (error) {
        console.error(error);
        setServerError(error);
        setLoading(false);
      }
    },
    [authContext],
  );

  const [googleClient, setGoogleClient] = useState();
  const [_, googleClientLoading] = useScript(
    'https://accounts.google.com/gsi/client',
    () => {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: settings.googleOAuthClientId,
        scope:
          'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        ux_mode: 'popup',
        callback: handleGoogleResponse,
      });
      setGoogleClient(client);
    },
  );

  const query = useQueryParams();
  const once = useRef(false);
  useEffect(() => {
    async function doAsyncStuff(code) {
      setLoading(true);
      setServerError(null);
      let error;
      try {
        error = await authContext.signInWithMagicLink(code);
      } catch (err) {
        error = err.message;
      }
      if (error) {
        setServerError(error);
        setLoading(false);
      }
    }
    const { c } = query;
    if (c && !once.current) {
      once.current = true;
      doAsyncStuff(c);
    }
  }, [authContext, query]);

  return (
    <AuthPageTemplate>
      <div style={{ position: 'fixed', top: 0, right: 0, paddingRight: 16 }}>
        <Link to={`/sign-up${location.search}`}>
          <Button type="link">Create an Account</Button>
        </Link>
      </div>
      <div style={{ height: 56, marginBottom: 16 }}>
        <img
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
          src={longLogo}
          alt="AIMS Logo"
        />
      </div>
      <Card
        className="signin-form"
        style={{
          borderRadius: 8,
          boxShadow: `0px 0px 8px ${settings.colors.borderGray}`,
        }}
      >
        <Title level={2} style={{ marginTop: 16, textAlign: 'center' }}>
          Welcome back!
        </Title>
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Username or Email"
              name="username"
              rules={[
                { required: true, message: 'Please enter your username' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
              ]}
              extra={<Link to="/forgot-password">Forgot Password?</Link>}
              style={{ marginBottom: 8 }}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              {/* <Button
                type="link"
                htmlType="button"
                onClick={() => history.push('/sign-up')}
              >
                Sign Up
              </Button> */}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Sign In
                </Button>
              </div>
            </Form.Item>
            {serverError && (
              <div
                className="server-error ant-form-item-has-error"
                style={{ marginTop: 16 }}
              >
                <div className="ant-form-item-explain">{serverError}</div>
              </div>
            )}
            <Divider>Or</Divider>
            <Form.Item>
              <Space
                align="center"
                style={{
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={() => {
                    googleClient.requestCode();
                  }}
                  icon={<GoogleOutlined />}
                  style={{ marginBottom: 8 }}
                  loading={googleClientLoading}
                  disabled={loading}
                >
                  Sign in with Google
                </Button>
                {/* <Button
                  onClick={() => authContext.signInWithApple()}
                  icon={<AppleOutlined />}
                  style={{ marginBottom: 8 }}
                >
                  Sign in with Apple
                </Button> */}
              </Space>
            </Form.Item>
            <div style={{ textAlign: 'center' }}>
              <Link
                to={`/sign-up${location.search}`}
              >{`I don't have an account yet.`}</Link>
            </div>
          </Form>
        </Spin>
      </Card>
      <style jsx>{`
        .page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 10vh;
          padding-bottom: 10vh;
        }
        .subtitle {
          font-size: 24px;
        }
        .server-error {
          text-align: center;
        }
        .logo-img {
          height: 56px;
        }
        .logo {
          display: flex;
          justify-content: center;
          margin-bottom: 16px;
        }
        @media screen and (max-width: 600px) {
          .page {
            padding-top: 32px;
            padding-top: 64px;
          }
        }
      `}</style>
      <style jsx global>{`
        .qr-gen-title {
          text-align: center;
        }
        .signin-form {
          width: 90vw;
          max-width: 500px;
          padding-left: 8px;
          padding-right: 8px;
        }
        @media screen and (max-width: 992px) {
        }
        @media screen and (max-width: 600px) {
        }
      `}</style>
    </AuthPageTemplate>
  );
};

export default SignIn;
