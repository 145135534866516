import { Form, Select, Slider } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../settings';
import { useWindowDimensions } from '../../../shared/window-dimensions';
import SelectRegion from '../shared/SelectRegion';
import SelectReligion from './SelectReligion';

function PeopleGroupFilters({ form, onFiltersChanged, filters }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const numberFormatter = new Intl.NumberFormat(locale, {
    notation: 'compact',
  });
  const dimensions = useWindowDimensions();
  return (
    <div
      style={{
        marginBottom: 48,
        maxWidth: 800,
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <Form layout="vertical" form={form} onValuesChange={onFiltersChanged}>
        <Form.Item name="regionCode" label="Select a Region">
          <SelectRegion filters={{}} />
        </Form.Item>
        {/* <Form.Item label="Select a language" name="primaryLanguageId">
          <SelectLanguage />
        </Form.Item> */}
        {dimensions.width > settings.screenSizes.lg992 ? (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.populationRange !== curValues.populationRange
            }
            noStyle
          >
            {({ getFieldValue }) => (
              <Form.Item
                name="populationRange"
                label={
                  filters.current.populationRange
                    ? `Population Range (${numberFormatter.format(
                        10 ** (getFieldValue('populationRange')[0] / 10),
                      )} to ${numberFormatter.format(
                        10 ** (getFieldValue('populationRange')[1] / 10),
                      )} People)`
                    : 'Population Range'
                }
                initialValue={[0, Math.log10(1.5e9) * 10]}
              >
                <Slider
                  style={{ marginLeft: 8, marginRight: 8 }}
                  range
                  marks={{
                    0: `${numberFormatter.format(10 ** 0)}`,
                    // 10: `${numberFormatter.format(10 ** 1)} People`,
                    // 20: `${numberFormatter.format(10 ** 2)} People`,
                    30: `${numberFormatter.format(10 ** 3)} People`,
                    // 40: `${numberFormatter.format(10 ** 4)} People`,
                    // 50: `${numberFormatter.format(10 ** 5)} People`,
                    60: `${numberFormatter.format(10 ** 6)} People`,
                    // 70: `${numberFormatter.format(10 ** 7)} People`,
                    // 80: `${numberFormatter.format(10 ** 8)} People`,
                    90: `${numberFormatter.format(10 ** 9)}`,
                    // 100: `${numberFormatter.format(10 ** 10)} People`,
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        ) : (
          <Form.Item name="populationMax" label="Population Range">
            <Select placeholder="Select a Population Range" allowClear>
              <Select.Option key={0} value={1e3}>
                Less than 1,000 people
              </Select.Option>
              <Select.Option key={1} value={1e4}>
                Less than 10,000 people
              </Select.Option>
              <Select.Option key={2} value={1e5}>
                Less than 100,000 people
              </Select.Option>
              <Select.Option key={3} value={1e6}>
                Less than 1 million people
              </Select.Option>
              <Select.Option key={4} value={1e9}>
                Less than 1 billion people
              </Select.Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item label="Select a Religion" name="primaryReligionId">
          <SelectReligion />
        </Form.Item>
        {/* <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.primaryReligionId !== curValues.primaryReligionId
          }
          noStyle
        >
          {({ getFieldValue }) =>
            !!getFieldValue('primaryReligionId') && (
              <Form.Item
                label="Select a subdivision"
                name="religionSubdivisionId"
              >
                <SelectReligionSubdivision
                  filters={{
                    primaryReligionId: getFieldValue('primaryReligionId'),
                  }}
                />
              </Form.Item>
            )
          }
        </Form.Item> */}
      </Form>
    </div>
  );
}

export default PeopleGroupFilters;
