import { autocomplete } from '@aims/shared/shared/utils';
import { Button, Form, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BehaviorSubject, of } from 'rxjs';
import settings from '../../../../settings';
import HolisticSteps from '../HolisticSteps';
import {
  extractPublicHolisticProjects,
  selectAProjectToSponsorQuery,
} from '../constants';
import HolisticProjectFilters from './HolisticProjectFilters';
import ProjectList from './ProjectList';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import useSafeStateA10 from '@aims/shared/shared/use-safe-state-a10';
import { FilterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useResponsiveMode from '../../../../shared/use-reponsive-mode';
import { gql, useQuery } from '@apollo/client';

const { Text, Title, Paragraph } = Typography;

const queryId = 'sponsorAProjectSelect';

const holisticFundIdQuery = gql`
  query HolisticFundId {
    holisticFundId
  }
`;

function SponsorAProjectSelectProject({ step, title }) {
  const [form] = Form.useForm();

  const [filters, setFilters, _filters] = useSafeStateA10({});
  // const filters = useRef({});
  const nextFilters = useRef(filters.current);
  const sortBy = useRef([
    { key: 'updatedAt', order: 'ASC' },
    { key: '_score', order: 'DESC' },
  ]);
  const [showFilters, setShowFilters] = useState(false);

  const {
    error,
    loading,
    data: projects,
    search: projectsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: selectAProjectToSponsorQuery,
    extract: extractPublicHolisticProjects,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const term$ = useRef(null);
  useEffect(() => {
    term$.current = new BehaviorSubject(nextFilters.current);
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          setFilters({
            ...term,
          });
          // filters.current = {
          //   ...term,
          // };
          console.log('filters.current', filters.current);
          refetch();
          return of();
        }),
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        projectsSearch(search);
      } else {
        nextFilters.current = {
          ...nextFilters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        console.log('nextFilters.current', nextFilters.current);
        term$.current.next(nextFilters.current);
      }
    },
    [projectsSearch],
  );

  const resetFilters = useCallback(() => {
    form.resetFields();
    nextFilters.current = {};
    if (term$ !== undefined) {
      term$.current.next(nextFilters.current);
    }
  }, [form]);

  const responsive = useResponsiveMode();
  const result = useQuery(holisticFundIdQuery, { fetchPolicy: 'network-only' });
  const holisticFundId = result?.data?.holisticFundId;

  return (
    <div className="container">
      <div className="filters">
        <div className="filters-inner">
          <Title level={2}>Filters</Title>
          <HolisticProjectFilters
            form={form}
            onFiltersChanged={onFiltersChanged}
          />
        </div>
      </div>
      <div className="pg-list">
        <HolisticSteps step={step} title={title} />
        {error && (
          <div>
            <Title type="danger" level={4}>
              Error
            </Title>
            <Text type="danger">{error}</Text>
          </div>
        )}
        <Title
          level={2}
          style={{
            textAlign: 'center',
          }}
        >
          {`Select a Holistic Project`}
        </Title>
        {showFilters && (
          <div
            style={{
              maxWidth: 800,
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          >
            <HolisticProjectFilters
              form={form}
              onFiltersChanged={onFiltersChanged}
            />
          </div>
        )}
        <div
          style={{
            textAlign: 'center',
            marginBottom: 24,
            marginTop: responsive === 'mobile' ? 0 : 16,
          }}
        >
          <a
            href={`${settings.pushpayGivingUrl}?fnd=${holisticFundId}&fndv=Lock&r=Monthly&rsd=2023-11-03&ret=Never&lang=en&src=pcgl`}
          >
            <Button
              size="small"
              type="primary"
              ghost
              style={{ background: 'none' }}
            >
              Skip
            </Button>
          </a>{' '}
          <Text>{`If you'd like AIMS.org to select a Holistic Project for for you`}</Text>
        </div>
        <div
          className="filters-btn"
          style={{ justifyContent: 'center', marginBottom: 16 }}
        >
          <Button
            className="filters-btn"
            onClick={() => setShowFilters(!showFilters)}
            type="dashed"
            icon={<FilterOutlined />}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </div>
        <ProjectList
          projects={projects}
          loading={loading}
          resetFilters={resetFilters}
          showFilters={showFilters}
          hasNextPage={hasNextPage}
          next={next}
        />
      </div>
      <style jsx>{`
        .container {
          display: flex;
          margin-left: -16px;
          margin-right: -16px;
        }
        .filters {
          margin-left: 16px;
          margin-right: 16px;
          flex: 1;
          min-width: 300px;
        }
        @media screen and (max-width: ${settings.screenSizes.xl1200}px) {
          .filters {
            display: none;
          }
        }
        .filters-inner {
          background: white;
          padding: 16px;
        }
        .pg-list {
          margin-left: 16px;
          margin-right: 16px;
          flex: 4;
        }
        .filters-btn {
          display: none;
        }
        @media screen and (max-width: ${settings.screenSizes.xl1200}px) {
          .filters-btn {
            display: flex;
          }
        }
        .featured {
          background-color: ${settings.colors.holisticLightGreen};
          margin-right: -16px;
          margin-left: -16px;
          padding-right: 16px;
          padding-left: 16px;
          padding-top: 16px;
          margin-bottom: 16px;
        }
      `}</style>
    </div>
  );
}

export default SponsorAProjectSelectProject;
