import { Steps, Typography } from 'antd';
import React from 'react';
import settings from '../../../settings';
import useResponsiveMode from '../../../shared/use-reponsive-mode';

const { Text, Title, Paragraph } = Typography;

function HolisticSteps({ step, title }) {
  const responsive = useResponsiveMode();
  return (
    <>
      <div
        className="mini-steps"
        style={{
          color: settings.colors.primary,
          fontSize: 16,
          textAlign: 'center',
        }}
      >
        {step === 0 && <Text>Step 1 of 4</Text>}
        {step === 1 && <Text>Step 2 of 4</Text>}
        {step === 2 && <Text>Step 3 of 4</Text>}
        {step === 3 && <Text>Step 4 of 4</Text>}
        <Title
          level={3}
          style={{
            marginTop: responsive === 'mobile' ? 0 : 24,
            marginBottom: 8,
            textAlign: 'center',
          }}
        >
          {title}
        </Title>
      </div>
      <div className="big-steps">
        <Steps current={step}>
          <Steps.Step
            title="Select Project"
            description="Choose a holistic project"
          />
          <Steps.Step
            title="Project Details"
            description="View details and select donation amount"
          />
          <Steps.Step
            title="People Group Impact"
            description="Adopt the Unreached People Groups"
          />
          <Steps.Step title="Payment" description="Setup payment details" />
        </Steps>
      </div>
      <style jsx>{`
        .mini-steps {
          display: none;
          margin-bottom: 16px;
        }
        .big-steps {
          display: block;
          margin-bottom: 16px;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .mini-steps {
            display: block;
          }
          .big-steps {
            display: none;
          }
        }
      `}</style>
    </>
  );
}

export default HolisticSteps;
