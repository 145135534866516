import { Card, List, Typography } from 'antd';
import React from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import adoptUpgPic from '../../assets/adopt-upg-pic.jpeg';
import holisticPic from '../../assets/holistic-pic.jpeg';
import { RightOutlined } from '@ant-design/icons';
import settings from '../../settings';
import { Link } from 'react-router-dom';
import useResponsiveMode from '../../shared/use-reponsive-mode';
import { useWindowDimensions } from '../../shared/window-dimensions';
const { Title, Paragraph } = Typography;

function PartnerDashboard() {
  const responsive = useResponsiveMode();
  const dimensions = useWindowDimensions();
  const data = [
    {
      title: 'Fund Native Missionaries',
      desc: 'Impact a nation for eternity',
      img: adoptUpgPic,
      lnk: '/partner/adopt-a-upg',
    },
    {
      title: 'Sponsor a Holistic Project',
      desc: 'Help keep missionaries on the field',
      img: holisticPic,
      lnk: '/partner/sponsor-a-project',
    },
  ];
  return (
    <CSPage title="AIMS.org | Partner with Us">
      <CSPageHeader title="Partner with Us" />
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        style={{
          paddingLeft: dimensions.width < 1200 ? undefined : '10vw',
          paddingRight: dimensions.width < 1200 ? undefined : '10vw',
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Link to={item.lnk}>
              <Card cover={<img src={item.img} />}>
                <Title
                  style={{
                    fontSize: 18,
                    textAlign: 'center',
                  }}
                >
                  {item.title}
                </Title>
                <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
                  {item.desc}
                </Paragraph>
              </Card>
            </Link>
          </List.Item>
        )}
      />
    </CSPage>
  );
}

export default PartnerDashboard;
