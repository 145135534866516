import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  message,
  notification,
  Skeleton,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import settings from '../../settings';
import {
  initialMouSectionMutation,
  MouRoles,
  signMouMutation,
} from './constants';
import triangleLogo from '../../assets/white-square-logo.png';
import { BehaviorSubject, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { useCountries } from '@aims/shared/shared/use-countries';
import PartiesElement from '../loan-agreements/PartiesElement';
import SectionsElement from '../loan-agreements/SectionsElement';
import AttachmentsElement from '../loan-agreements/AttachmentsElement';
import { formatPhoneNumberForDisplay } from '@aims/shared/shared/utils';
import SignMouModal from './SignMouModal';
import InitialMouModal from './InitialMouModal';
import { AgreementStatuses } from '../loan-agreements/constants';
import { updateMouAction } from '../../redux-store/mous-store';

const { Title, Text, Paragraph } = Typography;

function ContactDetails({ contact }) {
  const { countries } = useCountries();
  const getCountry = useCallback(
    (shortCode) => {
      if (countries) {
        const country = countries.find((c) => c.shortCode === shortCode);
        return country && country.name;
      }
      return '';
    },
    [countries],
  );
  const address = contact?.primaryAddress;
  const email = contact?.primaryEmail;
  const phoneNumber = contact?.primaryPhoneNumber;
  const extraAddressData =
    address &&
    [address.city, address.state, address.zipCode].filter((i) => !!i);
  return (
    <div style={{ textAlign: 'left' }}>
      <Divider />
      {contact && (
        <>
          <div style={{ fontSize: 16, color: settings.colors.textGray }}>
            Contact
          </div>
          <Text style={{ fontWeight: 600, fontSize: 18 }}>
            {contact.fullName}
          </Text>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ marginBottom: 8, marginRight: 16 }}>
              <Title level={5} style={{ margin: 0 }}>
                Primary Address
              </Title>
              {address ? (
                <>
                  {address.address1 && (
                    <div>
                      <Text>{address.address1}</Text>
                    </div>
                  )}
                  {address.address2 && (
                    <div>
                      <Text>{address.address2}</Text>
                    </div>
                  )}
                  {extraAddressData.length ? (
                    <div>
                      <Text>{extraAddressData.join(', ')}</Text>
                    </div>
                  ) : null}
                  <div style={{ marginBottom: 8 }}>
                    <Text>{getCountry(address.country)}</Text>
                  </div>
                </>
              ) : (
                <div>
                  <Text>Not Specified</Text>
                </div>
              )}
            </div>
            <div style={{ marginBottom: 8, marginRight: 16 }}>
              <Title level={5} style={{ margin: 0 }}>
                Primary Phone Number
              </Title>
              <div>
                <Text>
                  {phoneNumber
                    ? formatPhoneNumberForDisplay(phoneNumber)
                    : 'Not Specified'}
                </Text>
              </div>
            </div>
            <div style={{ marginBottom: 8 }}>
              <Title level={5} style={{ margin: 0 }}>
                Primary Email
              </Title>
              <div>
                <Text>{email || 'Not Specified'}</Text>
              </div>
            </div>
          </div>
        </>
      )}
      {!contact && <Skeleton active />}
    </div>
  );
}

function PeopleGroupDetails({ peopleGroup }) {
  return (
    <div style={{ textAlign: 'left' }}>
      <Divider />
      {peopleGroup && (
        <>
          <div style={{ fontSize: 16, color: settings.colors.textGray }}>
            People Group
          </div>
          <Text style={{ fontWeight: 600, fontSize: 18 }}>
            {`${peopleGroup.nameAcrossCountries} / ${peopleGroup.country} (${peopleGroup._id})`}
          </Text>
        </>
      )}
      {!peopleGroup && <Skeleton active />}
    </div>
  );
}

function OneLineElement({ label, text, noLabel, attachments, parties }) {
  const displayText = useMemo(() => {
    if (text) {
      let edited = text;
      const refs = text.matchAll(/{{(.*)}}/g);
      let ref = refs.next();
      while (!ref.done) {
        const party = parties && parties.find((a) => a.ref === ref.value[1]);
        if (party) {
          edited = text.replace(ref.value[0], `<Party: ${party.name}>`);
        }
        const attachment =
          attachments && attachments.find((a) => a.ref === ref.value[1]);
        if (attachment) {
          edited = text.replace(ref.value[0], `Attachment: ${attachment.name}`);
        }
        ref = refs.next();
      }
      return edited;
    }
    return noLabel;
  }, [text, noLabel, attachments, parties]);

  return (
    <div className="line-heading">
      <div style={{ marginRight: 32 }}>
        <div>
          <Text
            style={{ marginRight: 32, color: settings.colors.primary }}
          >{`${label}:`}</Text>
        </div>
        <div>
          <Text>{displayText}</Text>
        </div>
      </div>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;
        }
      `}</style>
    </div>
  );
}

function ViewMou({ mou, queryId }) {
  const saveQueue$ = useRef();
  const running = useRef(true);
  const currentAgreement = useRef();
  useEffect(() => {
    currentAgreement.current = mou;
  }, [mou]);
  const dispatch = useDispatch();
  const [signMou] = useMutation(signMouMutation);
  const [initialMouSection] = useMutation(initialMouSectionMutation);
  useEffect(() => {
    async function doSave(_values) {
      if (_values) {
        try {
          if (currentAgreement.current) {
            const { action, ...values } = values;
            if (action === 'sign') {
              const result = await signMou({
                variables: {
                  signature: {
                    mouId: currentAgreement.current._id,
                    signature: {
                      partyId: values.partyId,
                      signature: values.signature,
                      date: values.date,
                      fontName: values.fontName,
                    },
                  },
                },
              });
              dispatch(
                updateMouAction(result.data.signMouForUser.mou, queryId),
              );
              notification.success({
                message: 'Success',
                description: 'Partnership Commitment Form Signed',
              });
            } else if (action === 'initial') {
              const result = await initialMouSection({
                variables: {
                  initials: {
                    mouId: currentAgreement.current._id,
                    initials: {
                      sectionId: values.sectionId,
                      partyId: values.partyId,
                      initials: values.initials,
                      fontName: values.fontName,
                      date: values.date,
                    },
                  },
                },
              });
              dispatch(
                updateMouAction(result.data.initialMouSection.mou, queryId),
              );
              notification.success({
                message: 'Success',
                description: 'Initials Saved',
              });
            }
          }
        } catch (err) {
          console.error(err);
          message.error(err.message);
        }
      }
    }
    saveQueue$.current = new BehaviorSubject();
    saveQueue$.current.pipe(concatMap((values) => doSave(values))).subscribe();
    return () => (running.current = false);
  }, [signMou, initialMouSection, dispatch, queryId]);

  const status = AgreementStatuses[mou.status];
  return (
    <>
      <div
        id="mou"
        style={{
          backgroundColor: 'white',
          padding: '32px 16px',
          boxShadow: `0px 0px 4px ${settings.colors.primary}`,
          borderRadius: 2,
        }}
      >
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div
            style={{
              background: settings.colors.primary,
              width: 180,
              marginRight: 32,
              marginLeft: -16,
              marginTop: -32,
              padding: 16,
            }}
          >
            <img
              style={{ width: '100%' }}
              src={triangleLogo}
              alt="Triangle Logo"
            />
          </div>
          <Title>Partnership Commitment Form</Title>
        </div>
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <div style={{ fontSize: 18, color: settings.colors.textGray }}>
            Status
          </div>
          {status && (
            <div
              style={{
                color: settings.colors[status.color],
                fontSize: 24,
                margin: 0,
                fontWeight: 600,
              }}
            >
              {status.label}
            </div>
          )}
          <div style={{ fontSize: 16 }}>{`Version ${mou.version}`}</div>
        </div>
        <div style={{ textAlign: 'left' }}>
          <Divider />
          {MouRoles[mou.role] && (
            <>
              <div style={{ fontSize: 16, color: settings.colors.textGray }}>
                Role
              </div>
              <Text style={{ fontWeight: 600, fontSize: 18 }}>
                {MouRoles[mou.role].label}
              </Text>
            </>
          )}
        </div>
        <ContactDetails contact={mou.contact} />
        <PeopleGroupDetails peopleGroup={mou.peopleGroup} />
        <Divider />
        <PartiesElement loanAgreement={mou} />
        <SectionsElement
          loanAgreement={mou}
          queryId={queryId}
          InitialModal={InitialMouModal}
        />
        <AttachmentsElement loanAgreementId={mou._id} loanAgreement={mou} />
        <PartiesElement
          loanAgreement={mou}
          signing
          queryId={queryId}
          SignatureModal={SignMouModal}
        />
      </div>
    </>
  );
}

export default ViewMou;
