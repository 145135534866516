import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import useAccount from '../../shared/use-account';
import { readPeopleGroupsAction } from '../../redux-store/people-groups-store';

const query = gql`
  query AllMyUpgXContactsForUser {
    allMyUpgXContactsForUser {
      _id
      type
      peopleGroupId
      contactId
      startDate
      peopleGroup {
        _id
        country
        regionCode
        region
        population
        primaryReligion
        primaryLanguage
        nameAcrossCountries
        primaryThumbnailUrl
        createdAt
        updatedAt
      }
    }
  }
`;

const extract = (data) => data.allMyUpgXContactsForUser;

function useMyUpgXContacts(accountId) {
  const queryId = useMemo(() => `useMyUpgXContacts.${accountId}`, []);
  const account = useAccount();
  const { loading, refetch } = useOneM1({
    variables: {},
    query,
    extract,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: readPeopleGroupsAction,
    skip: !account,
    showError: true,
  });
  const defaultSort = useMemo(() => {
    return (a, b) => {
      return b._id?.localeCompare(a._id);
    };
  }, []);
  const filterFunc = useCallback((a) => {
    return true;
  }, []);
  const { data, search } = useManySomethingsM20({
    table: 'peopleGroups',
    sortByFunc: defaultSort,
    searchKeys: [
      '_id',
      'peopleGroup.nameAcrossCountries',
      'peopleGroup.country',
      'peopleGroup.region',
      'peopleGroup.summaryText',
    ],
    queryId,
    filterFunc,
  });
  return {
    loading,
    search,
    data,
    refetch,
  };
}

export default useMyUpgXContacts;
