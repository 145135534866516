import { gql } from '@apollo/client';

export const AgreementStatuses = {
  PREPARING: {
    key: 'PREPARING',
    label: 'Preparing',
    color: 'warning',
  },
  SIGNING: {
    key: 'SIGNING',
    label: 'Signing',
    color: 'blue',
  },
  SIGNED: {
    key: 'SIGNED',
    label: 'Signed',
    color: 'success',
  },
  CANCELLED: {
    key: 'CANCELLED',
    label: 'Cancelled',
    color: 'red',
  },
  PREVIEW: {
    key: 'PREVIEW',
    label: 'Preview',
    color: 'warning',
  },
};

export const projectLoanAgreementDetailedAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    projectId
    frozenProjectDetails {
      _id
      peopleGroupIds
      projectName
      typeOfProject
      desc
      status
      loanTotal
      loanTerm
      loanStartDate
      supportedFieldWorkers {
        contactId
        contactName
      }
    }
    parties {
      _id
      ref
      name
      contactId
      contactName
      signature
      date
      fontName
    }
    signeeIds
    sections {
      _id
      title
      text
      initials {
        partyId
        initials
        fontName
        date
      }
    }
    attachments {
      _id
      ref
      name
      fileId
      file {
        _id
        size
        filename
        contentType
        url
        uploaded
        processed
      }
    }
    projectId
    country
    countryName
    amount
    terms
    paySchedule
    beneficiaries {
      contactId
      fullName
    }
    approvalDate
    finalizedDate
    createdAt
    updatedAt
    deletedAt
  }
`;

export const projectLoanAgreementQuery = gql`
  query ProjectLoanAgreement($_id: ID!) {
    loanAgreementForUser(_id: $_id) ${projectLoanAgreementDetailedAttributes}
  }
`;

export const signLoanAgreementMutation = gql`
mutation SignLoanAgreement(
  $signature: LoanAgreementSignInput!
) {
  signLoanAgreement(signature: $signature) {
    loanAgreement ${projectLoanAgreementDetailedAttributes}
  }
}
`;

export const initialLoanAgreementSectionMutation = gql`
mutation InitialLoanAgreementSection(
  $initials: LoanAgreementInitialsInput!
) {
  initialLoanAgreementSection(initials: $initials) {
    loanAgreement ${projectLoanAgreementDetailedAttributes}
  }
}
`;

export const extractLoanAgreement = (data) => data && data.loanAgreementForUser;

export const projectLoanAgreementListAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    projectId
    sections {
      _id
      title
    }
    parties {
      _id
      ref
      name
      contactName
      date
    }
    attachments {
      _id
      ref
      name
      fileId
    }
    terms
    paySchedule
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allLoanAgreementsQuery = gql`
  query AllLoanAgreementsForUser(
    $first: Int
    $after: String
    $filters: LoanAgreementFiltersForUser
    $sortBy: [SortBy]
  ) {
    allLoanAgreementsForUser(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${projectLoanAgreementListAttributes}
      }
    }
  }
`;

export const extractLoanAgreements = (data) => data.allLoanAgreementsForUser;
