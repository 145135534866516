import { SendOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import useProfile from '../../shared/use-profile';
import { gql, useMutation } from '@apollo/client';

const { Title, Text, Paragraph } = Typography;

const requestVerifyEmailLinkMutation = gql`
  mutation RequestVerifyEmailLink {
    requestVerifyEmailLink
  }
`;

function VerifyEmail() {
  const profile = useProfile();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [requestVerifyEmailLink] = useMutation(requestVerifyEmailLinkMutation);

  const sendEmail = useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      await requestVerifyEmailLink();
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [requestVerifyEmailLink]);

  useEffect(() => {
    sendEmail();
  }, [sendEmail]);

  return (
    <CSPage title="AIMS.org | Verify Email">
      <Title style={{ textAlign: 'center', marginTop: 32 }}>
        Verify Your Email
      </Title>
      <div className="body">
        <Paragraph
          style={{ fontSize: 15, textAlign: 'center' }}
        >{`We've just sent you an email at ${profile.email}.  Please check your inbox and click the "Verify Email" button contained in the email.`}</Paragraph>
        <Divider />
        <Paragraph
          style={{ fontSize: 15, textAlign: 'center', marginBottom: 24 }}
        >{`Click below to resend.`}</Paragraph>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={sendEmail}
            loading={loading}
            type="primary"
            icon={<SendOutlined />}
          >
            Resend
          </Button>
        </div>
      </div>
      {error && (
        <div style={{ textAlign: 'center' }}>
          <Title type="danger" level={4}>
            Error
          </Title>
          <Text type="danger">{error}</Text>
        </div>
      )}
      <style jsx>{`
        .body {
          width: 600px;
          margin-left: auto;
          margin-right: auto;
        }
      `}</style>
    </CSPage>
  );
}

export default VerifyEmail;
