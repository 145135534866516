import { gql } from '@apollo/client';
import { useCallback, useMemo, useRef, useState } from 'react';
import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import { readAnythingsAction } from '../../redux-store/anything-store';
import settings from '../../settings';

const query = gql`
  query AllUpgStatsRecordsForUser(
    $first: Int
    $after: String
    $filters: UpgStatsRecordFiltersForUser
    $sortBy: [SortBy]
  ) {
    allUpgStatsRecordsForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          peopleGroupId
          regionCode
          nameAcrossCountries
          country
          quarter
          numHolisticProject
          numActiveAdoptions
          numAdopters
          numRegionalCoords
          numFieldWorkers
          numChurchesPlanted
          numHeardForFirstTime
          numSalvations
          numWaterBaptisms
          numMiracles
          numPrayerRequests
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const extract = (data) => data.allUpgStatsRecordsForUser;

function useUpgStats(filters, queryId = 'useAdoptionStats') {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading, refetch } = useManyLocalM20({
    query,
    extract,
    readAction: readAnythingsAction,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const defaultSort = useMemo(() => {
    return (a, b) => {
      return b.createdAt?.localeCompare(a.createdAt);
    };
  }, []);
  const filterFunc = useCallback(
    (a) => {
      if (filters.peopleGroupId && filters.peopleGroupId !== a.peopleGroupId) {
        return false;
      }
      return true;
    },
    [filters],
  );
  const { data, search } = useManySomethingsM20({
    table: 'things',
    sortByFunc: defaultSort,
    searchKeys: ['_id', 'peopleGroupId', 'nameAcrossCountries'],
    queryId,
    filterFunc,
  });
  return {
    loading,
    search,
    data,
    refetch,
  };
}

export default useUpgStats;
