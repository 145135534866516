import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

const query = gql`
  query CanCreateTrainingEventReport {
    canCreateTrainingEventReport
  }
`;

function useCanCreateTrainingEventReport() {
  const { data, loading, error } = useQuery(query);
  const result = data?.canCreateTrainingEventReport;
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
  return { loading, result };
}

export default useCanCreateTrainingEventReport;
