export const READ_PROFILE = 'READ_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SIGN_OUT = 'SIGN_OUT';
export const AUTH_STATE_CHANGE = 'AUTH_STATE_CHANGE';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';

// eslint-disable-next-line no-shadow
export const changeAccountAction = (accountId) => async (dispatch) => {
  localStorage.setItem('contactId', accountId);
  dispatch({
    type: CHANGE_ACCOUNT,
    payload: {
      accountId,
    },
  });
};

export const signOutAction = () => ({
  type: SIGN_OUT,
});

// eslint-disable-next-line no-shadow
export const authStateChangedAction = (profile) => async (dispatch) => {
  dispatch({
    type: AUTH_STATE_CHANGE,
    payload: {
      profile,
    },
  });
};

const initialAuthState = {
  isLoading: true,
  isSignout: false,
};

export function authState(state = initialAuthState, action) {
  switch (action.type) {
    case AUTH_STATE_CHANGE: {
      return {
        isLoading: false,
        isSignout: false,
      };
    }
    case SIGN_OUT:
      return {
        isLoading: false,
        isSignout: true,
      };
    default:
      return state;
  }
}

const initialProfile = null;

export function profile(state = initialProfile, action) {
  switch (action.type) {
    case AUTH_STATE_CHANGE:
    case READ_PROFILE:
      return action.payload.profile;
    case UPDATE_PROFILE:
      return { ...state, ...action.payload.profile };
    default:
      return state;
  }
}

export function accountId(state = null, action) {
  switch (action.type) {
    case AUTH_STATE_CHANGE: {
      const contactId = localStorage.getItem('contactId');
      return contactId;
    }
    case CHANGE_ACCOUNT:
      return action.payload.accountId;
    default:
      return state;
  }
}

export function accounts(state = [], action) {
  switch (action.type) {
    case AUTH_STATE_CHANGE: {
      // eslint-disable-next-line no-shadow
      const profile = action.payload.profile;
      if (profile) {
        return [
          {
            ...profile.myContact,
            label: 'Me',
          },
          {
            ...profile.myHousehold,
            label: 'My Household',
          },
          ...profile.myOrganizations.map((o) => ({
            ...o,
            label: 'My Organization',
          })),
        ].filter((a) => a._id);
      }
      return [];
    }
    default:
      return state;
  }
}
