import { gql } from '@apollo/client';

export const createTrainingEventReportMutation = gql`
  mutation CreateTrainingEventReportForUser(
    $report: TrainingEventReportCreateInputForUser!
  ) {
    createTrainingEventReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateTrainingEventReportMutation = gql`
  mutation UpdateTrainingEventReportForUser(
    $report: TrainingEventReportUpdateInputForUser!
  ) {
    updateTrainingEventReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitTrainingEventReportMutation = gql`
  mutation SubmitTrainingEventReportForUser($reportId: ID!) {
    submitTrainingEventReportForUser(reportId: $reportId)
  }
`;

export const unsubmitTrainingEventReportMutation = gql`
  mutation unsubmitTrainingEventReportForUser($reportId: ID!) {
    unsubmitTrainingEventReportForUser(reportId: $reportId)
  }
`;
