import React from 'react';
import AllLoanAgreementsList from './AllLoanAgreementsList';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';

function AllLoanAgreementsPage() {
  return (
    <CSPage title="AIMS.org | Loan Agreements">
      <CSPageHeader title="Loan Agreements" />
      <AllLoanAgreementsList />
    </CSPage>
  );
}

export default AllLoanAgreementsPage;
