export const READ_ADOPTIONS = 'READ_ADOPTIONS';
export const CREATE_ADOPTION = 'CREATE_ADOPTION';
export const UPDATE_ADOPTION = 'UPDATE_ADOPTION';
export const DELETE_ADOPTION = 'DELETE_ADOPTION';

// eslint-disable-next-line no-shadow
export const readAdoptionsAction = (adoptions, queryId = 'default') => ({
  type: READ_ADOPTIONS,
  payload: {
    adoptions,
    queryId,
  },
});

export const createAdoptionAction = (adoption, queryId = 'default') => ({
  type: CREATE_ADOPTION,
  payload: {
    adoption,
    queryId,
  },
});

export const updateAdoptionAction = (adoption, queryId = 'default') => ({
  type: UPDATE_ADOPTION,
  payload: {
    adoption,
    queryId,
  },
});

export const deleteAdoptionAction = (adoptionId, queryId = 'default') => ({
  type: DELETE_ADOPTION,
  payload: {
    adoptionId,
    queryId,
  },
});

const initialAdoptions = { default: {} };

export function adoptions(state = initialAdoptions, action) {
  switch (action.type) {
    case READ_ADOPTIONS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.adoptions.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_ADOPTION:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.adoption._id]: action.payload.adoption,
        },
      };
    case UPDATE_ADOPTION: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.adoption._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.adoption._id]: {
            ...current,
            ...action.payload.adoption,
          },
        },
      };
    }
    case DELETE_ADOPTION: {
      const { adoptionId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [adoptionId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
