import { Button, Card, Empty, List, Skeleton, Typography } from 'antd';
import useMiracles from '@aims/shared/miracles/use-miracles';
import React, { useState } from 'react';
import settings from '../../settings';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Paragraph } = Typography;

const RecentMiracles = ({ filters, queryId }) => {
  const [numMiraclesShown, setNumMiraclesShown] = useState(settings.smallPage);
  const { data: miracles, loading } = useMiracles(filters, queryId);
  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <>
      <Title level={3} style={{ marginBottom: 16 }}>
        Recent Testimonies
      </Title>
      {loading && (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      )}
      {!loading && (
        <List
          dataSource={miracles.slice(0, numMiraclesShown)}
          grid={{ column: 1, sm: 1, lg: 1, gutter: 12 }}
          rowKey="label"
          renderItem={(item) => (
            <List.Item>
              <Card>
                <Paragraph
                  style={{ fontWeight: 'bold', color: settings.colors.primary }}
                >
                  {`Created ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(item.createdAt))}`}
                </Paragraph>
                <Paragraph>{item.desc}</Paragraph>
              </Card>
            </List.Item>
          )}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Recent Testimonies"
              />
            ),
          }}
          loadMore={
            miracles.length > numMiraclesShown && (
              <div className="load-more">
                <Button
                  onClick={() =>
                    setNumMiraclesShown(numMiraclesShown + settings.smallPage)
                  }
                >
                  View More
                </Button>
              </div>
            )
          }
        />
      )}
      <div style={{ height: 32 }} />
      <style jsx>{``}</style>
    </>
  );
};

export default RecentMiracles;
