import { useMemo } from 'react';
import useAccount from '../../shared/use-account';
import useMyUpgXContacts from './use-my-upg-x-contacts';

function useMyPeopleGroups() {
  const account = useAccount();
  const { data: upgXContacts, loading } = useMyUpgXContacts(account._id);

  const myPgs = useMemo(() => {
    const peopleGroups = {};
    upgXContacts.forEach((upgXContact) => {
      if (peopleGroups[upgXContact.peopleGroupId] === undefined) {
        peopleGroups[upgXContact.peopleGroupId] = {
          _id: upgXContact.peopleGroupId,
          roles: [],
          peopleGroup: upgXContact.peopleGroup,
        };
      }
      peopleGroups[upgXContact.peopleGroupId].roles.push(upgXContact.type);
    });
    return Object.values(peopleGroups);
  }, [upgXContacts]);

  return { myPgs, loading };
}

export default useMyPeopleGroups;
