import { ViewTrainingQuarterlyReport as ViewItems } from '@aims/shared/reports';
import React from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useReport from './use-report';

const queryId = 'viewTrainingQuarterlyReport';

function ViewTrainingQuarterlyReport() {
  const { reportId } = useParams();
  const { report, loading } = useReport(reportId, queryId, true);

  return (
    <CSPage title={`AIMS.org | View Training Quarterly Report`}>
      <CSPageHeader
        title="View Training Quarterly Report"
        parents={[
          {
            label: 'Training',
            link: `/training`,
          },
          {
            label: 'Quarterly Reports',
            link: `/training-reports/quarterly`,
          },
        ]}
      />
      {report && (
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
          <ViewItems report={report} />
        </div>
      )}
    </CSPage>
  );
}

export default ViewTrainingQuarterlyReport;
