import { gql } from '@apollo/client';

export const createTrainingQuarterlyReportMutation = gql`
  mutation CreateTrainingQuarterlyReportForUser(
    $report: TrainingQuarterlyReportCreateInputForUser!
  ) {
    createTrainingQuarterlyReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateTrainingQuarterlyReportMutation = gql`
  mutation UpdateTrainingQuarterlyReportForUser(
    $report: TrainingQuarterlyReportUpdateInputForUser!
  ) {
    updateTrainingQuarterlyReportForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitTrainingQuarterlyReportMutation = gql`
  mutation SubmitTrainingQuarterlyReportForUser($reportId: ID!) {
    submitTrainingQuarterlyReportForUser(reportId: $reportId)
  }
`;

export const unsubmitTrainingQuarterlyReportMutation = gql`
  mutation unsubmitTrainingQuarterlyReportForUser($reportId: ID!) {
    unsubmitTrainingQuarterlyReportForUser(reportId: $reportId)
  }
`;
