import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { useRef, useState } from 'react';
import settings from '../../../settings';
import { ReportUserShortAttributes } from '../../reports/constants';

const query = gql`
  query AllTrainingQuarterlyReports(
    $first: Int
    $after: String
    $filters: TrainingQuarterlyReportFiltersForUser
    $sortBy: [SortBy]
  ) {
    allTrainingQuarterlyReportsForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          contact {
            _id
            fullName
          }
          regionCode
          regionName
          reportType
          isSubmitted
          isApproved
          submittedAt
          createdBy
          createdByUser ${ReportUserShortAttributes}
          submittedByUser ${ReportUserShortAttributes}
          updatedBy
          createdAt
          updatedAt
          updatedByUser ${ReportUserShortAttributes}
          status
          approval {
            status
            when
            approver {
              _id
              name
            }
          }
          events {
            _id
            city
            country
            host
            date
            numPeopleTrained
          }
          miracles {
            _id
          }
          prayerRequests {
            _id
          }
          canEdit
        }
      }
    }
  }
`;

const extract = (data) => data?.allTrainingQuarterlyReportsForUser;

export function useReports(filters, queryId) {
  const [sortedBy, setSortedBy] = useState('quarter.keyword');
  const [sortOrder, setSortOrder] = useState('DESC');

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const { error, loading, data, search, hasNextPage, next, refetch, reset } =
    useManyRemoteM1({
      query,
      extract,
      first: settings.querySize,
      filters,
      sortBy: sortBy.current,
      fetchPolicy: 'network-only',
      queryId,
    });
  return {
    error,
    loading,
    data,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  };
}
