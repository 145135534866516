import { gql, useMutation } from '@apollo/client';
import { Button, Spin, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CSPage from '../../components/CSPage';
import useProfile from '../../shared/use-profile';
import useQueryParams from '../../shared/use-query-params';
import CSPageHeader from '../../components/CSPageHeader';

const { Title, Text, Paragraph } = Typography;

const verifyEmailWithMagicLinkMutation = gql`
  mutation VerifyEmailWithMagicLink($code: String!) {
    verifyEmailWithMagicLink(code: $code)
  }
`;

function EmailVerified() {
  const profile = useProfile();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verifyEmailWithMagicLink] = useMutation(
    verifyEmailWithMagicLinkMutation,
  );

  const query = useQueryParams();
  const { c } = query;
  const dispatch = useDispatch();

  useEffect(() => {
    async function doAsyncStuff() {
      setError(undefined);
      setLoading(true);
      try {
        if (c) {
          await verifyEmailWithMagicLink({
            variables: {
              code: c,
            },
          });
          notification.success({
            message: 'Success',
            description: 'Email verified successfully',
          });
        } else {
          throw new Error('Bad URL');
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: err.message,
        });
      }
      setLoading(false);
    }
    doAsyncStuff();
  }, [verifyEmailWithMagicLink, c, dispatch]);

  return (
    <CSPage title="AIMS.org | Verify Email">
      <CSPageHeader title="Verify Email" />
      {profile && profile.emailVerified ? (
        <>
          <Title style={{ textAlign: 'center' }}>Perfect!</Title>
          <div
            style={{
              width: 600,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Paragraph
              style={{ fontSize: 15, textAlign: 'center' }}
            >{`You email address has been verified`}</Paragraph>
            <div
              style={{
                height: 200,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Link to="/my-profile">
                <Button type="primary" size="large">
                  My Profile
                </Button>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            height: 200,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to="/verify-my-email">
            <Button type="primary" loading={loading} size="large">
              Try Again
            </Button>
          </Link>
        </div>
      )}
    </CSPage>
  );
}

export default EmailVerified;
