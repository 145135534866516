import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Space,
  Tooltip,
  Upload,
  Form,
  Input,
  Typography,
  Progress,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  doFileUpload,
  generateUuid,
  toBase64,
} from '@aims/shared/shared/utils';
import filesize from 'filesize';
import ellipsis from 'text-ellipsis';
import { getFileIcon } from '@aims/shared/shared/icons';
import settings from '../../settings';
import {
  getAttachmentUploadUrlForUserMutation,
  saveAttachmentForUserMutation,
} from '../attachments/constants';

const { Text } = Typography;

export const AttachmentCard = ({
  thumbnailUrl,
  fileName,
  fileSize,
  onUploadDelete,
  deleted,
  progress,
  url,
  style = {},
}) => {
  const icon = useMemo(() => {
    return getFileIcon(fileName, { fontSize: 18 });
  }, [fileName]);

  return (
    <div
      className="upload-list"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: 64,
          border: `1px solid ${settings.colors.borderGray}`,
          padding: '8px 16px',
          ...style,
        }}
      >
        <Space style={{ height: '100%' }}>
          {progress !== undefined && (
            <Progress type="circle" percent={progress.toFixed(0)} width={48} />
          )}
          {progress === undefined && (
            <>
              {thumbnailUrl ? (
                <img className="upload-list-img" src={thumbnailUrl} />
              ) : (
                icon
              )}
            </>
          )}
          <div>
            {`${ellipsis(fileName, 20)} (${filesize(fileSize, {
              round: 1,
            })})`}
          </div>
        </Space>
      </div>
      <div>
        {!deleted && !onUploadDelete && url && (
          <Button
            type="text"
            target={(!deleted && !onUploadDelete && '_blank') || undefined}
            download={(!deleted && !onUploadDelete && fileName) || undefined}
            href={(!deleted && !onUploadDelete && url) || undefined}
          >
            <DownloadOutlined />
          </Button>
        )}
        {!deleted && onUploadDelete && (
          <Button
            icon={<DeleteOutlined />}
            type="text"
            onClick={onUploadDelete}
          />
        )}
        {deleted && (
          <div style={{ marginRight: 8 }}>
            <Text type="danger">Will be Deleted</Text>
          </div>
        )}
      </div>
      <style jsx>{`
        .upload-list-img {
          height: 48px;
        }
        .download {
          padding-right: 8px;
          padding-left: 8px;
          font-size: 16px;
        }
      `}</style>
    </div>
  );
};

function AttachmentFormItem({
  existingAttachments,
  parentId,
  parentType,
  retrieveAttachments,
  readOnly,
  disabled,
  name,
  form,
  fileLimit = 1,
}) {
  const _fileList = useRef(existingAttachments || []);
  const [fileList, _setFileList] = useState(_fileList.current);
  const setFileList = useCallback((value) => {
    _fileList.current = value;
    _setFileList(value);
  }, []);
  const [uploadLoading, setUploadLoading] = useState(false);
  const _uploadProgress = useRef({});
  const [uploadProgress, _setUploadProgress] = useState(
    _uploadProgress.current,
  );
  const setUploadProgress = useCallback((value) => {
    _uploadProgress.current = value;
    _setUploadProgress(value);
  }, []);
  const [error, setError] = useState(null);
  const [getAttachmentUploadUrl] = useMutation(
    getAttachmentUploadUrlForUserMutation,
  );
  const [saveAttachment] = useMutation(saveAttachmentForUserMutation);

  if (retrieveAttachments) {
    retrieveAttachments.current = () => {
      return fileList.map((f) => ({
        _id: f._id,
        filename: f.filename,
      }));
    };
  }

  const doUpload = useCallback(
    async (obj) => {
      console.log('onBefore', obj);
      try {
        setError(null);
        setUploadLoading(true);
        const attachmentId = generateUuid();
        const s3Key = `${parentType}:${parentId}:attachment:${attachmentId}`;

        let base64Url;
        if (obj.file.type.startsWith('image')) {
          base64Url = await toBase64(obj.file);
        }
        const newFileList = [
          ..._fileList.current,
          {
            _id: attachmentId,
            s3Key,
            size: obj.file.size,
            filename: obj.file.name,
            contentType: obj.file.type,
            base64Url,
          },
        ];
        setFileList(newFileList);

        let response;
        response = await getAttachmentUploadUrl({
          variables: {
            attachment: {
              _id: attachmentId,
              s3Key,
            },
          },
        });
        if (
          !response ||
          !response.data ||
          !response.data.getAttachmentUploadUrl
        ) {
          throw new Error('Bad upload URL');
        }

        setUploadProgress({
          ..._uploadProgress.current,
          [attachmentId]: 0,
        });
        await doFileUpload({
          url: response.data.getAttachmentUploadUrl.url,
          fields: response.data.getAttachmentUploadUrl.fields,
          file: obj.file,
          onProgress: (event) => {
            console.log('progress', event);
            setUploadProgress({
              ..._uploadProgress.current,
              [attachmentId]: (event.loaded / event.total) * 100,
            });
          },
        });
        setTimeout(() => {
          setUploadProgress({
            ..._uploadProgress.current,
            [attachmentId]: undefined,
          });
        }, 1000);

        response = await saveAttachment({
          variables: {
            attachment: {
              _id: attachmentId,
              filename: obj.file.name,
              s3Key,
              attachedTo: parentId,
              tags: ['attachment'],
              uploaded: true,
            },
          },
        });
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setUploadLoading(false);
    },
    [
      getAttachmentUploadUrl,
      parentType,
      parentId,
      saveAttachment,
      setUploadProgress,
      setFileList,
    ],
  );

  const onUploadDelete = useCallback(
    (file) => () => {
      const fileIndex = _fileList.current.findIndex((f) => f._id === file._id);
      if (fileIndex >= 0) {
        setFileList([
          ..._fileList.current.slice(0, fileIndex),
          ..._fileList.current.slice(fileIndex + 1),
        ]);
      }
    },
    [setFileList],
  );

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        [name]: fileList.map((f) => f._id),
      });
    }
  }, [form, name, fileList]);

  return (
    <div>
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              return (
                <Form.Item key={field.key} noStyle>
                  <Input type="hidden" />
                </Form.Item>
              );
            })}
          </>
        )}
      </Form.List>
      {fileList.map((f) => (
        <AttachmentCard
          key={f._id}
          thumbnailUrl={(f.thumbnail && f.thumbnail.url) || f.base64Url}
          fileName={f.filename}
          fileSize={f.size}
          onUploadDelete={!readOnly && onUploadDelete(f)}
          progress={uploadProgress[f._id]}
          url={f.url}
        />
      ))}
      {!readOnly && fileList.length < fileLimit && (
        <Upload
          className="profile-pic-upload"
          showUploadList={false}
          listType="picture"
          customRequest={doUpload}
        >
          {error && (
            <Tooltip title={error}>
              <Button danger icon={<ExclamationCircleOutlined />}>
                Failed
              </Button>
            </Tooltip>
          )}
          {!error && (
            <Button
              disabled={disabled || uploadLoading}
              icon={uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            >
              {uploadLoading ? 'Saving ...' : 'Attach'}
            </Button>
          )}
        </Upload>
      )}
    </div>
  );
}

export default AttachmentFormItem;
