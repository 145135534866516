import { EditOutlined } from '@ant-design/icons';
import { Button, Form, List, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import settings from '../../settings';
import useAccount from '../../shared/use-account';
import { Fonts } from './SelectFont';

const { Title, Paragraph, Text } = Typography;

function SingleSection({
  section,
  parties,
  attachments,
  backgroundColor,
  myParties,
  doInitial,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      _id: section._id,
      title: section.title,
      text: section.text,
    });
  }, [section, form]);

  const allowInitials = useMemo(() => {
    if (myParties && myParties.length > 0) {
      const initialedPartyIds = section.initials
        ? section.initials.map((i) => i.partyId)
        : [];
      return myParties.filter((p) => !initialedPartyIds.includes(p._id));
    }
    return [];
  }, [myParties, section]);
  return (
    <>
      <div className="line-heading">
        <Title level={3}>{section.title || 'No Title'}</Title>
      </div>
      <div className="line-answer" style={{ marginBottom: 12 }}>
        {!section.text && 'No Text'}
        {section.text &&
          section.text.split('\n').map((p, index) => {
            let edited = p;
            const refs = p.matchAll(/{{(.*)}}/g);
            let ref = refs.next();
            while (!ref.done) {
              const party =
                parties && parties.find((a) => a.ref === ref.value[1]);
              if (party) {
                if (party.contactName) {
                  edited = p.replace(
                    ref.value[0],
                    `Party: ${party.contactName}`,
                  );
                } else {
                  edited = p.replace(ref.value[0], `<Party: ${party.name}>`);
                }
              }
              const attachment =
                attachments && attachments.find((a) => a.ref === ref.value[1]);
              if (attachment) {
                edited = p.replace(
                  ref.value[0],
                  `Attachment: ${attachment.name}`,
                );
              }
              ref = refs.next();
            }
            return (
              <Paragraph className="line-answer-p" key={index}>
                {edited}
              </Paragraph>
            );
          })}
      </div>
      <div
        style={{
          color: settings.colors.primary,
          fontWeight: 600,
          marginBottom: 8,
        }}
      >
        Initials
      </div>
      <div style={{ fontSize: 16, color: settings.colors.textGray }}>
        {(!section.initials || section.initials.length === 0) &&
          allowInitials.length === 0 &&
          'Initials will go here'}
        {section.initials &&
          section.initials.length > 0 &&
          section.initials.map((i, index) => (
            <>
              <Text
                key={i.partyId}
                style={{
                  fontFamily: Fonts[i.fontName] && Fonts[i.fontName].fontFamily,
                  fontSize: 24,
                  marginRight: 16,
                }}
              >
                {i.initials}
              </Text>
            </>
          ))}
        {allowInitials.map((partyId) => (
          <Button
            key={partyId}
            onClick={() => doInitial(section, partyId)}
            icon={<EditOutlined />}
            type="dashed"
            style={{ minWidth: 100, marginRight: 8 }}
          >
            Initial
          </Button>
        ))}
      </div>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: left;
          background-color: ${backgroundColor};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
        }
      `}</style>
    </>
  );
}

function SectionsElement({ loanAgreement, queryId, InitialModal }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [sections, setSections] = useState([]);

  const account = useAccount();
  const myParties = useMemo(() => {
    if (loanAgreement && loanAgreement.parties) {
      return loanAgreement.parties.filter((p) => p.contactId === account._id);
    }
    return null;
  }, [loanAgreement, account]);

  useEffect(() => {
    setSections((loanAgreement && loanAgreement.sections) || []);
  }, [loanAgreement, form]);

  const [initialing, setInitialing] = useState();
  const handleInitial = useCallback(
    (section, partyId) => {
      setInitialing({
        section,
        party: partyId,
        loanAgreementId: loanAgreement._id,
      });
    },
    [loanAgreement],
  );
  const handleFinishInitial = useCallback(() => {
    setInitialing(undefined);
  }, []);
  const handleCancelInitial = useCallback(() => {
    setInitialing(undefined);
  }, []);

  return (
    <>
      <div className="line-heading">
        <Title level={2}>Sections</Title>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={sections}
        renderItem={(section, index) => {
          return (
            <List.Item>
              <SingleSection
                section={section}
                loading={loading}
                parties={loanAgreement && loanAgreement.parties}
                attachments={loanAgreement && loanAgreement.attachments}
                backgroundColor={
                  !loanAgreement || loanAgreement.isTemplate
                    ? settings.colors.borderGray
                    : settings.colors.ghost
                }
                myParties={myParties}
                doInitial={handleInitial}
              />
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 16 }}>
                {`Sections contain the body of this agreement.`}
              </Paragraph>
            </>
          ),
        }}
        rowKey="_id"
      />
      <InitialModal
        visible={initialing}
        onCancel={handleCancelInitial}
        onSuccess={handleFinishInitial}
        queryId={queryId}
      />
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: center;
          background-color: ${settings.colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default SectionsElement;
