import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import UserQuestions from '@aims/shared/users/questions';
import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, Form, Space, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import useProfile from '../../shared/use-profile';
import ChangePasswordModal from './ChangePasswordModal';
import { editProfileMutation } from './constants';
import ProfilePhotoFormItemJ24 from './profile-photo/ProfilePhotoFormItemJ24';

function EditProfilePage() {
  const profile = useProfile();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [changingPassword, setChangingPassword] = useState(null);

  const [editProfile] = useMutation(editProfileMutation);
  const [form] = Form.useForm();

  useEffect(() => {
    if (profile) {
      form.setFieldsValue(profile);
    }
  }, [profile, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      const { ...values } = _values;
      try {
        await editProfile({
          variables: {
            profile: {
              _id: profile._id,
              ...values,
              ...UserQuestions.phone.finish(values),
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Profile updated successfully',
        });
        history.push('/my-profile');
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setLoading(false);
    },
    [profile, editProfile, history, setLoading],
  );

  return (
    <>
      <CSPage title="AIMS.org | Edit Profile">
        <CSPageHeader
          title="Edit Profile"
          parents={[{ label: 'My Profile', link: '/my-profile' }]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="editProfile"
          form={form}
          style={{ maxWidth: 600, marginRight: 'auto', marginLeft: 'auto' }}
        >
          <ProfilePhotoFormItemJ24
            name="profilePhotoId"
            existing={
              profile && {
                _id: profile.profilePhotoId,
                thumbnailUrl: profile.primaryThumbnailUrl,
              }
            }
            styles={{ formItem: { display: 'flex', justifyContent: 'center' } }}
            parentType="user"
            parentId={profile?._id}
            tags={['User', 'Profile Photo']}
            saving={loading}
            setSaving={setLoading}
          />
          <UserQuestions.username.Display user={profile} />
          <UserQuestions.name.FormItem loading={loading} />
          <UserQuestions.email.FormItem loading={loading} />
          <UserQuestions.phone.FormItem loading={loading} />
          <ShowErrorsFormItem />
          <div style={{ height: 16 }} />
          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                style={{ paddingRight: 32, paddingLeft: 32 }}
              >
                Save
              </Button>
            </div>
          </Form.Item>
          <Divider />
          <div
            style={{ maxWidth: 600, display: 'flex', justifyContent: 'center' }}
          >
            <Button
              onClick={() => setChangingPassword(profile)}
              icon={<LockOutlined />}
            >
              Change Password
            </Button>
          </div>
        </Form>
        <div style={{ minHeight: 300 }} />
        <ChangePasswordModal
          user={changingPassword}
          onFinish={() => {
            setChangingPassword(undefined);
          }}
          onCancel={() => {
            setChangingPassword(undefined);
          }}
        />
      </CSPage>
    </>
  );
}

export default EditProfilePage;
