import { Select, Typography } from 'antd';
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeAccountAction } from '../redux-store/auth-store';
import settings from '../settings';
import useAccount from '../shared/use-account';

const { Title, Text } = Typography;

function ChooseAccount({ layout = 'horizontal' }) {
  const accounts = useSelector((store) => store.accounts, shallowEqual);

  const account = useAccount();

  const dispatch = useDispatch();
  const onAccountChange = useCallback(
    (accountId) => {
      dispatch(changeAccountAction(accountId));
    },
    [dispatch],
  );
  return (
    <>
      {accounts && accounts.length > 1 && (
        <div
          style={{
            display: layout === 'horizontal' && 'flex',
            textAlign: 'left',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: layout === 'horizontal' ? 16 : undefined,
              textAlign: layout === 'horizontal' ? 'center' : 'left',
              marginBottom: layout === 'horizontal' ? undefined : 4,
            }}
          >
            <Text style={{ color: settings.colors.primary }}>
              Choose Account
            </Text>
          </div>
          <Select
            value={account && account._id}
            onChange={onAccountChange}
            style={{ minWidth: 300 }}
          >
            {accounts.map((a) => {
              const _title = `${a.label} (${a.fullName})`;
              return (
                <Select.Option key={a._id} value={a._id} title={_title}>
                  {_title}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      )}
    </>
  );
}

export default ChooseAccount;
