import { Alert } from 'antd';
import React from 'react';

function RecentDonationsAlert() {
  return (
    <Alert
      message="Recent Donations"
      description="It may take AIMS a day or two to fully process your recent donation.
            Once we do, you'll have access to the connected Unreached People Groups and
            Holistic Projects on this dashboard. Thank you for your patience!"
      type="info"
      showIcon
    />
  );
}

export default RecentDonationsAlert;
