import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

const query = gql`
  query CanCreateTrainingQuarterlyReport {
    canCreateTrainingQuarterlyReport
  }
`;

function useCanCreateTrainingQuarterlyReport() {
  const { data, loading, error } = useQuery(query);
  const result = data?.canCreateTrainingQuarterlyReport;
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
  return { loading, result };
}

export default useCanCreateTrainingQuarterlyReport;
