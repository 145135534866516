import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

const query = gql`
  query CanCreateUpgQuarterlyReport($peopleGroupId: ID!) {
    canCreateUpgQuarterlyReport(peopleGroupId: $peopleGroupId)
  }
`;

function useCanCreateUpgQuarterlyReport(peopleGroupId) {
  const { data, loading, error } = useQuery(query, {
    variables: { peopleGroupId },
  });
  const result = data?.canCreateUpgQuarterlyReport;
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
  return { loading, result };
}

export default useCanCreateUpgQuarterlyReport;
