import ImageWithDefault from '@aims/shared/components/ImageWithDefault';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { Button, Card, List, Progress, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useDefaultProjectPhoto from '../../../holistic-projects/use-default-project-photo';
import settings from '../../../../settings';

const { Text, Title, Paragraph } = Typography;

function SelectProjectCard({ project, setShowModal }) {
  // const projectName = `${project.nameAcrossCountries} / ${project.region}`;
  const projectName = `${project.projectName}`;
  const projectType =
    HolisticProjectTypes[project.typeOfProject] &&
    HolisticProjectTypes[project.typeOfProject].label;
  const defaultPhoto = useDefaultProjectPhoto(project);
  const image = useMemo(() => {
    let img;
    if (project.primaryPhoto?.images) {
      img = project.primaryPhoto?.images.find((i) => i.sizeCode === 'SM')?.url;
    }
    if (!img && project.primaryPhoto?.url) {
      img = project.primaryPhoto.url;
    }
    if (!img) {
      img = project.primaryThumbnailUrl;
    }
    return img;
  }, [project]);
  return (
    <List.Item>
      <Card
        onClick={() => setShowModal({ project })}
        cover={
          <ImageWithDefault
            imageUrl={image}
            fallbackUrl={defaultPhoto}
            alt={projectName}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              aspectRatio: '1/1',
            }}
          />
        }
      >
        <Title
          level={5}
          style={{ marginBottom: 0, whiteSpace: 'normal', lineHeight: 1.3 }}
        >
          {projectName}
        </Title>
        {projectType && (
          <div>
            <Text
              style={{ width: 224, lineHeight: 1.1 }}
              ellipsis={{ tooltip: projectType }}
            >
              {projectType}
            </Text>
          </div>
        )}
        {project.numSupported && (
          <div>
            <Text style={{ width: 224, lineHeight: 1.1 }}>
              {`${project.numSupported} Supported ${
                project.numSupported > 1 ? 'Missionaries' : 'Missionary'
              }`}
            </Text>
          </div>
        )}
        <div style={{ marginBottom: 16, marginTop: 12 }}>
          <div>
            <Text style={{ fontSize: 18, fontWeight: 800, lineHeight: 1.1 }}>
              {displayMoney(getNumberValue(project.fundingBalance) / 10000)}
            </Text>{' '}
            <Text style={{ lineHeight: 1.1 }}>raised of</Text>{' '}
            <Text style={{ fontSize: 16, fontWeight: 600, lineHeight: 1.1 }}>
              {displayMoney(getNumberValue(project.fundingTarget) / 10000)}
            </Text>{' '}
            <Text style={{ lineHeight: 1.1 }}>goal</Text>
          </div>
          <Progress
            percent={Math.round(project.fundingPercent / 100)}
            showInfo={false}
            strokeLinecap="butt"
            strokeColor={settings.colors.holisticGreen}
            trailColor={settings.colors.white}
          />
        </div>
        {/* {project.region && (
                <div className="right">
                  <Text
                    style={{
                      color: settings.colors.primary,
                      whiteSpace: 'normal',
                    }}
                  >
                    {project.region}
                  </Text>
                </div>
              )} */}
        {/* <div className="right">
                  <Text
                    style={{ width: 224 }}
                    ellipsis={{ tooltip: project.desc }}
                  >
                    {project.desc}
                  </Text>
                </div> */}
        <div
          style={{
            marginTop: 16,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            type="dashed"
            style={{ marginRight: 16 }}
            onClick={() => setShowModal({ project })}
          >
            More Info
          </Button>
          <Link
            to={`/partner/sponsor-a-project/${project._id}${location.search}`}
          >
            <Button type="primary">Select</Button>
          </Link>
        </div>
      </Card>
      <style jsx global>{`
        .ant-progress-inner {
          border: 1px solid ${settings.colors.textBlack};
        }
      `}</style>
    </List.Item>
  );
}

export default SelectProjectCard;
