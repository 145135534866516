import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import useApprovedBy from '@aims/shared/reports/use-approved-by';
import useSubmittedBy from '@aims/shared/reports/use-submitted-by';
import { getNumberValue } from '@aims/shared/shared/utils';
import { EditOutlined, EyeOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Card, List, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

function EventReportListItem({ report, locale }) {
  const status = ReportApprovalStatuses[report.status];

  const submittedBy = useSubmittedBy(report);
  const approvedBy = useApprovedBy(report);

  const dateFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }),
    [locale],
  );
  const eventDate =
    report.eventDate && dateFormat.format(new Date(report.eventDate));

  return (
    <List.Item style={{ width: '100%', border: 'none' }}>
      <Card
        style={{ width: '100%' }}
        styles={{ body: { textAlign: 'left' } }}
        bordered
      >
        <div className="row1">
          <Title level={4}>
            <Space>
              {`${eventDate} / ${report.regionName}`}
              <MinusOutlined />
              <Text style={{ color: status.color }}>{status.label}</Text>
            </Space>
          </Title>
          <Space>
            <Link to={`/training-reports/event/view/${report._id}`}>
              <Button icon={<EyeOutlined />}>View</Button>
            </Link>
            {report.canEdit && (
              <Link to={`/training-reports/event/edit/${report._id}`}>
                <Button icon={<EditOutlined />}>Edit</Button>
              </Link>
            )}
          </Space>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Event Location',
                value:
                  report.eventAddress &&
                  `${report.eventAddress.label} (${report.eventAddress.city}, ${report.eventAddress.country})`,
              },
              {
                label: 'Event Date',
                value: eventDate,
              },
              {
                label: 'People Trained',
                value: getNumberValue(report.numPeopleTrained),
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
          <div style={{ marginRight: 16, marginBottom: 16 }}>
            {[
              {
                label: 'Trainers Certified',
                value: getNumberValue(report.trainersTrained?.length),
              },
              {
                label: 'UPG Adoption Commitments',
                value: report.numUpgAdoptions,
              },
              {
                label: 'New Ministry Partnerships',
                value: getNumberValue(report.newPartnerships?.length),
              },
            ].map((item) => (
              <div key={item.label}>
                <Text style={{ fontWeight: 600, marginRight: 4 }}>
                  {`${item.label}:`}
                </Text>
                <Text>{item.value}</Text>
              </div>
            ))}
          </div>
        </div>
        <div className="row3">
          <Text style={{ fontSize: 14 }}>{submittedBy}</Text>
        </div>
        {approvedBy && (
          <div className="row3">
            <Text style={{ fontSize: 14 }}>{approvedBy}</Text>
          </div>
        )}
      </Card>
      <style jsx>{`
        .row1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 16px;
        }
      `}</style>
    </List.Item>
  );
}

export default EventReportListItem;
