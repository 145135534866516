import { EditOutlined } from '@ant-design/icons';
import { Button, Card, List, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../settings';
import useAccount from '../../shared/use-account';
import { Fonts } from './SelectFont';
import { AgreementStatuses } from './constants';

const { Title, Paragraph, Text } = Typography;

function ReadOnlyParty({ party, isYou }) {
  return (
    <Card>
      <div style={{ fontSize: 14, color: settings.colors.textGray }}>
        {party.name}
      </div>
      <div style={{ fontSize: 18, color: settings.colors.primary }}>
        {party.contactName ? party.contactName : 'Not Specified'}
        {isYou ? ' (You)' : ''}
      </div>
    </Card>
  );
}

function SigningParty({ party, doSign, allowSign, locale }) {
  const signatureDate =
    party.date &&
    new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
    }).format(new Date(party.date));
  return (
    <Card bodyStyle={{ position: 'relative' }}>
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 14, color: settings.colors.textGray }}>
          {party.name}
        </div>
        <div style={{ fontSize: 18, color: settings.colors.primary }}>
          {party.contactName ? party.contactName : 'Not Specified'}
        </div>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              height: 60,
              flex: 3,
              borderBottom: `1px solid ${settings.colors.borderGray}`,
              display: 'flex',
              alignItems: 'center',
              marginRight: 8,
            }}
          >
            <Text style={{ marginRight: 16 }}>Signature:</Text>
            {!party.signature && allowSign && (
              <div style={{ flex: 1, textAlign: 'center' }}>
                <Button
                  onClick={() => doSign(party)}
                  icon={<EditOutlined />}
                  type="dashed"
                  style={{ minWidth: 100 }}
                >
                  Sign
                </Button>
              </div>
            )}
            {party.signature && (
              <div
                style={{ flex: 1, textAlign: 'center', alignSelf: 'flex-end' }}
              >
                <Text
                  style={{
                    fontFamily:
                      Fonts[party.fontName] && Fonts[party.fontName].fontFamily,
                    fontSize: 32,
                    marginRight: 16,
                    lineHeight: 1,
                  }}
                >
                  {party.signature}
                </Text>
              </div>
            )}
          </div>
          <div
            style={{
              height: 60,
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              borderBottom: `1px solid ${settings.colors.borderGray}`,
            }}
          >
            <Text style={{ marginRight: 16 }}>Date:</Text>
            {signatureDate && (
              <div
                style={{ flex: 1, textAlign: 'center', alignSelf: 'flex-end' }}
              >
                <Text
                  style={{
                    marginRight: 16,
                    fontSize: 24,
                    fontFamily:
                      Fonts[party.fontName] && Fonts[party.fontName].fontFamily,
                    lineHeight: 1,
                  }}
                >
                  {signatureDate}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

function PartiesElement({ loanAgreement, signing, queryId, SignatureModal }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [parties, setParties] = useState([]);

  const account = useAccount();
  const myPartyIDs = useMemo(() => {
    if (loanAgreement && loanAgreement.parties) {
      return loanAgreement.parties
        .filter((p) => p.contactId === account._id)
        .map((party) => party._id);
    }
    return null;
  }, [loanAgreement, account]);

  useEffect(() => {
    setParties((loanAgreement && loanAgreement.parties) || []);
  }, [loanAgreement]);

  const title = signing ? 'Signatures' : 'Parties';

  const [showSigning, setShowSigning] = useState();
  const handleSign = useCallback(
    (party) => {
      setShowSigning({
        party,
        loanAgreement: loanAgreement,
      });
    },
    [loanAgreement],
  );
  const handleFinishSigning = useCallback(() => {
    setShowSigning(undefined);
  }, []);
  const handleCancelSigning = useCallback(() => {
    setShowSigning(undefined);
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <>
      <div className="line-heading">
        <Title level={2}>{title}</Title>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={parties}
        renderItem={(party) => {
          return (
            <List.Item>
              {loanAgreement &&
                loanAgreement.status !== AgreementStatuses.PREPARING.key &&
                !signing && (
                  <ReadOnlyParty
                    party={party}
                    isYou={account._id === party.contactId}
                  />
                )}
              {loanAgreement &&
                loanAgreement.status !== AgreementStatuses.PREPARING.key &&
                signing && (
                  <SigningParty
                    party={party}
                    doSign={handleSign}
                    loading={loading}
                    error={error}
                    isYou={account._id === party.contactId}
                    allowSign={
                      loanAgreement &&
                      loanAgreement.status === AgreementStatuses.SIGNING.key &&
                      myPartyIDs &&
                      myPartyIDs.includes(party._id)
                    }
                    locale={locale}
                  />
                )}
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 16 }}>
                {`Parties describe the signatures required by this agreement`}
              </Paragraph>
            </>
          ),
        }}
        rowKey="_id"
      />
      {SignatureModal && (
        <SignatureModal
          visible={showSigning}
          onCancel={handleCancelSigning}
          onSuccess={handleFinishSigning}
          queryId={queryId}
        />
      )}
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: center;
          background-color: ${settings.colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default PartiesElement;
