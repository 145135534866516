import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import sharedSettings from '@aims/shared/sharedSettings';
import UserQuestions from '@aims/shared/users/questions';
import { GoogleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Space,
  Typography,
  notification,
} from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import longLogo from '../../assets/long-logo-sm.png';
import settings from '../../settings';
import { AuthContext } from '../../shared/auth-context';
import useQueryParams from '../../shared/use-query-params';
import useScript from '../../shared/use-script';
import { userInviteQuery } from './constants';
import AuthPageTemplate from './AuthPageTemplate';

const { Title, Text, Paragraph } = Typography;

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [form] = Form.useForm();
  const location = useLocation();

  const onFinish = async (values) => {
    setLoading(true);
    let error;
    try {
      error = await authContext.signUp(values);
    } catch (err) {
      error = err.message;
    }
    if (error) {
      notification.error({
        description: 'Error',
        message: error,
      });
      setLoading(false);
    }
  };

  const handleGoogleResponse = useCallback(
    async (response) => {
      // console.log('response', response);
      setLoading(true);
      let error;
      try {
        error = await authContext.signIntoUserWithGoogle(response.code);
      } catch (err) {
        error = err.message;
      }
      if (error) {
        console.error(error);
        notification.error({
          description: 'Error',
          message: error,
        });
        setLoading(false);
      }
    },
    [authContext],
  );

  const [googleClient, setGoogleClient] = useState();
  const [_, googleClientLoading] = useScript(
    'https://accounts.google.com/gsi/client',
    () => {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: settings.googleOAuthClientId,
        scope:
          'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        ux_mode: 'popup',
        callback: handleGoogleResponse,
      });
      setGoogleClient(client);
    },
  );

  const query = useQueryParams();
  const [userInvite, setUserInvite] = useState();
  const client = useApolloClient();
  const once = useRef(false);
  useEffect(() => {
    async function doSignInWithMagicLink(code) {
      setLoading(true);
      let error;
      try {
        error = await authContext.signInWithMagicLink(code);
      } catch (err) {
        notification.error({
          description: 'Error',
          message: err.message,
        });
      }
      if (error) {
        setLoading(false);
      }
    }
    async function doFetchInvite(code) {
      setLoading(true);
      try {
        const response = await client.query({
          query: userInviteQuery,
          fetchPolicy: 'network-only',
          variables: {
            _id: code,
          },
        });
        if (
          response &&
          response.data &&
          response.data.userInvite &&
          response.data.userInvite.contact
        ) {
          const contact = response.data.userInvite.contact;
          const email = (contact.emails || []).reduce((prev, curr) => {
            if (curr._id === contact.primaryEmailId) {
              return curr.email;
            }
            return prev;
          }, undefined);
          const phoneNumber = (contact.phoneNumbers || []).reduce(
            (prev, curr) => {
              if (curr._id === contact.primaryPhoneNumberId) {
                return curr.phone;
              }
              return prev;
            },
            undefined,
          );
          form.setFieldsValue({
            name: contact.fullName,
            email,
            phone: phoneNumber,
            inviteCode: response.data.userInvite._id,
          });
          setUserInvite(response.data.userInvite);
        }
      } catch (err) {
        console.error(err);
        notification.error({
          description: 'Error',
          message: err.message,
        });
      }
      setLoading(false);
    }
    const { c, i } = query;
    if (c && !once.current) {
      once.current = true;
      doSignInWithMagicLink(c);
    } else if (i && !once.current) {
      once.current = true;
      doFetchInvite(i);
    }
  }, [authContext, query, form, client]);

  useScript(
    `https://www.google.com/recaptcha/api.js?render=${settings.recaptchaPublicKey}`,
  );

  return (
    // <div className="page qr-gen">
    <AuthPageTemplate>
      <div style={{ position: 'fixed', top: 0, right: 0, paddingRight: 16 }}>
        <Link to={`/sign-in${location.search}`}>
          <Button type="link">Sign In</Button>
        </Link>
      </div>
      <div style={{ height: 56, marginBottom: 16 }}>
        <img
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
          src={longLogo}
          alt="AIMS Logo"
        />
      </div>
      <Card
        className="signin-form"
        style={{
          borderRadius: 8,
          boxShadow: `0px 0px 8px ${settings.colors.borderGray}`,
        }}
      >
        <Title
          level={2}
          style={{ marginTop: 16, marginBottom: 0, textAlign: 'center' }}
        >
          Create an Account
        </Title>
        {userInvite ? (
          <>
            <Paragraph
              style={{ textAlign: 'center', marginTop: 16 }}
            >{`Welcome ${
              userInvite.contact.fullName.split(' ')[0]
            }!`}</Paragraph>
            <Paragraph style={{ textAlign: 'center' }}>
              {`You have been invited to sign up and have been granted the following permissions: ${userInvite.roles
                .map((r) => r.label)
                .join(', ')}`}
            </Paragraph>
          </>
        ) : (
          <Paragraph style={{ textAlign: 'center' }}>
            Gain access to your personalized dashboard.
          </Paragraph>
        )}
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
        >
          <UserQuestions.username.FormItem
            loading={loading}
            form={form}
            extra="Something simple (your first name, email, or initials)"
          />
          {/* <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please enter your username' }]}
            extra="Something simple (your first name, email, or initials)"
          >
            <Input disabled={loading} />
          </Form.Item> */}
          <Form.Item name="inviteCode" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <UserQuestions.name.FormItem loading={loading} form={form} />
          <UserQuestions.email.FormItem loading={loading} form={form} />
          <UserQuestions.phone.FormItem loading={loading} form={form} />
          <UserQuestions.password.FormItem loading={loading} form={form} />
          <Form.Item
            name="emailOptIn"
            initialValue={true}
            valuePropName="checked"
          >
            <Checkbox disabled={loading}>
              {`Add impact to my inbox. Sign me up for the newsletter.`}
            </Checkbox>
          </Form.Item>
          <ShowErrorsFormItem />
          <Form.Item
            style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}
          >
            <Button loading={loading} type="primary" htmlType="submit">
              Sign Up
            </Button>
          </Form.Item>
          <Divider>Or</Divider>
          <Form.Item>
            <Space
              align="center"
              style={{
                flexWrap: 'wrap',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  googleClient.requestCode();
                }}
                icon={<GoogleOutlined />}
                style={{ marginBottom: 8 }}
                loading={googleClientLoading || loading}
                disabled={loading}
              >
                Sign up with Google
              </Button>
            </Space>
          </Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Link to={`/sign-in${location.search}`}>
              I already have an account.
            </Link>
          </div>
        </Form>
      </Card>
      <style jsx>{`
        .page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 10vh;
          padding-bottom: 10vh;
        }
        .subtitle {
          font-size: 24px;
        }
        .server-error {
          text-align: center;
        }
        .logo-img {
          height: 56px;
        }
        .logo {
          display: flex;
          justify-content: center;
        }
        @media screen and (max-width: 600px) {
          .page {
            padding-top: 32px;
            padding-top: 64px;
          }
        }
      `}</style>
      <style jsx global>{`
        .qr-gen-title {
          text-align: center;
        }
        .signin-form {
          width: 90vw;
          max-width: 500px;
          padding-left: 8px;
          padding-right: 8px;
        }
        @media screen and (max-width: 992px) {
        }
        @media screen and (max-width: 600px) {
        }
      `}</style>
    </AuthPageTemplate>
  );
};

export default SignUp;
