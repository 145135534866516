import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space, Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { updateLoanAgreementAction } from '../../redux-store/loan-agreements-store';
import { SelectFontStyles } from '../loan-agreements/SelectFont';
import ViewLoanAgreement from '../loan-agreements/ViewLoanAgreement';
import {
  extractLoanAgreement,
  projectLoanAgreementQuery,
} from '../loan-agreements/constants';

const { Title, Text, Paragraph } = Typography;

function ProjectLoanAgreementPage() {
  const { projectId, loanAgreementId } = useParams();
  const queryId = 'view-loan-agreement';

  const { loading, refetch } = useOneM1({
    variables: { _id: loanAgreementId },
    query: projectLoanAgreementQuery,
    extract: extractLoanAgreement,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateLoanAgreementAction,
    skip: !loanAgreementId,
    showError: true,
  });
  const loanAgreement = useSingleSomethingA10(
    'loanAgreements',
    loanAgreementId,
    queryId,
  );

  return (
    <>
      <CSPage title="AIMS.org | View Loan Agreement">
        <CSPageHeader
          title="View Loan Agreement"
          parents={[
            {
              label: 'My Projects',
              link: `/holistic-projects`,
            },
            {
              label:
                (loanAgreement &&
                  loanAgreement.frozenProjectDetails &&
                  loanAgreement.frozenProjectDetails.projectName) ||
                'Holistic Project',
              link: `/holistic-projects/${projectId}/loan-agreements`,
            },
          ]}
        />
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
          {loanAgreement && (
            <ViewLoanAgreement
              loanAgreement={loanAgreement}
              queryId={queryId}
            />
          )}
          {loading && (
            <Space direction="vertical">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <style jsx>{``}</style>
      <SelectFontStyles />
    </>
  );
}

export default ProjectLoanAgreementPage;
