import { generateUuid } from '@aims/shared/shared/utils';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Empty, List } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import useAccount from '../../../shared/use-account';
import ProposalListItem from './ProposalListItem';
import useCanCreateHolisticProposal from './use-can-create-report';
import { useReports } from './use-reports';

function ProposalList() {
  const account = useAccount();

  const {
    loading,
    data: reports,
    refetch,
  } = useReports(settings.querySize, {});

  const [numShown, setNumShown] = useState(settings.pageSize);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const { result: canCreateReports } = useCanCreateHolisticProposal();

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        {canCreateReports && (
          <Link to={`/holistic-proposals/edit/${generateUuid()}`}>
            <Button icon={<PlusOutlined />}>New Proposal</Button>
          </Link>
        )}
        <div style={{ flex: 1 }} />
        <Button icon={<RedoOutlined />} onClick={refetch}>
          Refresh List
        </Button>
      </div>
      <List
        dataSource={reports.slice(0, numShown)}
        rowKey="_id"
        renderItem={(item) => (
          <ProposalListItem
            report={item}
            locale={locale}
            canCreateReports={canCreateReports}
          />
        )}
        loading={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Proposals"
            />
          ),
        }}
        loadMore={
          reports.length > numShown && (
            <div className="load-more">
              <Button onClick={() => setNumShown(numShown + settings.pageSize)}>
                Load More
              </Button>
            </div>
          )
        }
      />
    </>
  );
}

export default ProposalList;
