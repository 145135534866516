import { Button, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import settings from '../settings';

const { Text } = Typography;

function VerifyEmailBanner() {
  return (
    <>
      <Link id="verifyMyEmail" to="/verify-my-email">
        <div className="banner">
          <div style={{ marginRight: 16 }}>
            <Text style={{ color: 'white' }}>
              Please verify your email address.
            </Text>
          </div>
          <Button size="middle" type="default" ghost>
            Verify Email
          </Button>
        </div>
      </Link>
      <style jsx>{`
        .banner {
          background-color: ${settings.colors.primary};
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;
        }
      `}</style>
    </>
  );
}

export default VerifyEmailBanner;
