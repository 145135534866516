import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ListCourses from './courses/ListCourses';
import ListTrainingFiles from './files/ListFiles';

const TabLabels = {
  courses: 'Courses',
  files: 'Files',
};

function Training() {
  const history = useHistory();
  const { tab } = useParams();

  return (
    <>
      <CSPage title={`AIMS.org | Training ${TabLabels[tab]}`}>
        <CSPageHeader
          titleComponent={null}
          title={TabLabels[tab]}
          parents={[
            {
              label: 'Training',
            },
          ]}
        />
        <Tabs
          defaultActiveKey="reports"
          activeKey={tab}
          onChange={(key) => history.push(`/training/${key}`)}
        >
          <Tabs.TabPane tab="Training Courses" key="courses">
            <ListCourses />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Training Files" key="files">
            <ListTrainingFiles />
          </Tabs.TabPane>
        </Tabs>
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default Training;
