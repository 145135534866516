import { gql, useMutation } from '@apollo/client';
import { Button, Card, Form, notification, Typography } from 'antd';
import UserQuestions from '@aims/shared/users/questions';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import longLogo from '../../assets/long-logo-sm.png';
import useProfile from '../../shared/use-profile';
import AuthPageTemplate from './AuthPageTemplate';

const { Title, Text, Paragraph } = Typography;

const interpretForgotPasswordLinkMutation = gql`
  mutation InterpretForgotPasswordLink($code: String!) {
    interpretForgotPasswordLink(code: $code) {
      _id
      username
      name
    }
  }
`;

const resetPasswordWithLinkMutation = gql`
  mutation ResetPasswordWithLink($code: String!, $password: String!) {
    resetPasswordWithLink(code: $code, password: $password)
  }
`;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const history = useHistory();
  const [interpretForgotPasswordLink] = useMutation(
    interpretForgotPasswordLinkMutation,
  );
  const [resetPasswordWithLink] = useMutation(resetPasswordWithLinkMutation);
  const [success, setSuccess] = useState(false);
  const { code } = useParams();
  const [user, setUser] = useState(undefined);
  const [form] = Form.useForm();

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      setServerError(null);
      try {
        const result = await interpretForgotPasswordLink({
          variables: {
            code,
          },
        });
        const _user =
          result && result.data && result.data.interpretForgotPasswordLink;
        if (!_user) {
          throw new Error('Broken link. Try again.');
        }
        setUser(_user);
      } catch (err) {
        console.error(err);
        setServerError(err.message);
      }
      setLoading(false);
    }
    if (code) {
      doAsyncStuff();
    } else {
      setServerError('Broken link. Try again.');
    }
  }, [code, interpretForgotPasswordLink]);

  const onFinish = async (values) => {
    setLoading(true);
    setServerError(null);
    try {
      const result = await resetPasswordWithLink({
        variables: {
          code,
          password: values.password,
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Password updated successfully',
      });
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setServerError(err.message);
    }
    setLoading(false);
  };

  const profile = useProfile();
  useEffect(() => {
    if (profile) {
      history.push('/home');
    }
  }, [profile, history]);

  return (
    <AuthPageTemplate>
      <div style={{ height: 56, marginBottom: 16 }}>
        <img
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
          src={longLogo}
          alt="AIMS Logo"
        />
      </div>
      <Card className="signin-form" bordered={false}>
        <Title level={2} style={{ marginTop: 16, textAlign: 'center' }}>
          Reset Password
        </Title>
        {success ? (
          <>
            <Paragraph style={{ textAlign: 'center' }}>
              {`Done.  Your password has been reset.  In case you forgot, your username is ${
                user && user.username
              }`}
            </Paragraph>
            <Form layout="vertical" name="basic">
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => history.push('/sign-in')}
                    style={{ marginRight: 8 }}
                    loading={loading}
                  >
                    Return to Sign In
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <Paragraph style={{ textAlign: 'center' }}>
              {loading && 'Loading ...'}
              {!loading &&
                user &&
                `Welcome back ${user.name}.  Reset your password by filling out the form below.`}
            </Paragraph>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              form={form}
            >
              <UserQuestions.password.FormItem
                loading={loading}
                fieldsToSet={{}}
                form={form}
              />
              <UserQuestions.confirmPassword.FormItem
                loading={loading}
                fieldsToSet={{}}
                form={form}
              />
              <Form.Item>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: 8,
                  }}
                >
                  <Button loading={loading} type="primary" htmlType="submit">
                    Reset Password
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => history.push('/sign-in')}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </div>
              </Form.Item>
              {serverError && (
                <div
                  className="server-error ant-form-item-has-error"
                  style={{ marginTop: 16 }}
                >
                  <div className="ant-form-item-explain">{serverError}</div>
                </div>
              )}
            </Form>
          </>
        )}
      </Card>
      <style jsx>{`
        .page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 10vh;
          padding-bottom: 10vh;
        }
        .subtitle {
          font-size: 24px;
        }
        .server-error {
          text-align: center;
        }
        .logo-img {
          height: 56px;
        }
        .logo {
          display: flex;
          justify-content: center;
          margin-bottom: 16px;
        }
        @media screen and (max-width: 600px) {
          .page {
            padding-top: 32px;
            padding-top: 64px;
          }
        }
      `}</style>
      <style jsx global>{`
        .qr-gen-title {
          text-align: center;
        }
        .signin-form {
          width: 32vw;
          padding-left: 32px;
          padding-right: 32px;
        }
        @media screen and (max-width: 992px) {
          .signin-form {
            width: 60vw;
          }
        }
        @media screen and (max-width: 600px) {
          .signin-form {
            width: calc(100vw - 32px);
            padding: 16px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      `}</style>
    </AuthPageTemplate>
  );
};

export default ResetPassword;
