import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { useRef, useState } from 'react';
import settings from '../../../settings';

export const query = gql`
  query AllTrainingFilesForUser(
    $first: Int
    $after: String
    $filters: TrainingFileFiltersForUser
    $sortBy: [SortBy]
  ) {
    allTrainingFilesForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          fileName
          desc
          courseId
          moduleId
          file {
            _id
            desc
            s3Key
            size
            filename
            contentType
            tags
            url
            uploaded
            processed
          }
          fileId
          language
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

const extract = (data) => data?.allTrainingFilesForUser;

function useTrainingFiles(filters, queryId = 'default', first = 10000) {
  const [sortedBy, setSortedBy] = useState('fileName.keyword');
  const [sortOrder, setSortOrder] = useState('DESC');

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const { error, loading, data, search, hasNextPage, next, refetch, reset } =
    useManyRemoteM1({
      query,
      extract,
      first,
      filters,
      sortBy: sortBy.current,
      fetchPolicy: 'cache-first',
      queryId,
    });
  return {
    error,
    loading,
    data,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  };
}

export default useTrainingFiles;
