import { gql, useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Space, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import longLogo from '../../assets/long-logo-sm.png';
import { AuthContext } from '../../shared/auth-context';
import useProfile from '../../shared/use-profile';
import AuthPageTemplate from './AuthPageTemplate';

const { Title, Text, Paragraph } = Typography;

const sendForgotPasswordLinkMutation = gql`
  mutation SendForgotPasswordLink($email: String!) {
    sendForgotPasswordLink(email: $email)
  }
`;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const history = useHistory();
  const [sendForgotPasswordLink] = useMutation(sendForgotPasswordLinkMutation);
  const [success, setSuccess] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    setServerError(null);
    try {
      await sendForgotPasswordLink({
        variables: {
          email: values.email,
        },
      });
      setSuccess(true);
    } catch (err) {
      console.error(err);
      setServerError(err.message);
    }
    setLoading(false);
  };

  const profile = useProfile();
  useEffect(() => {
    if (profile) {
      history.push('/home');
    }
  }, [profile, history]);

  return (
    <AuthPageTemplate>
      <div style={{ height: 56, marginBottom: 16 }}>
        <img
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
          src={longLogo}
          alt="AIMS Logo"
        />
      </div>
      <Card className="signin-form" bordered={false}>
        <Title level={2} style={{ marginTop: 16, textAlign: 'center' }}>
          Password Help
        </Title>
        {success ? (
          <>
            <Paragraph style={{ textAlign: 'center' }}>
              {`We've attempted to send you an email with a link to reset your password.
              If your email is associated with an active user, you should receive an email from use momentarily.`}
            </Paragraph>
            <Form layout="vertical" name="basic">
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => history.push('/sign-in')}
                    style={{ marginRight: 8 }}
                    loading={loading}
                  >
                    Return to Sign In
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <Paragraph style={{ textAlign: 'center' }}>
              {`Enter your email and we'll try and send you a link to reset your password.`}
            </Paragraph>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please enter your email' },
                  {
                    type: 'email',
                    message:
                      'Please enter a valid email (ex. aaron@gmail.com).',
                  },
                ]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button loading={loading} type="primary" htmlType="submit">
                      Send Link
                    </Button>
                  </div>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => history.push('/sign-in')}
                    style={{ marginRight: 8 }}
                    disabled={loading}
                  >
                    Go Back
                  </Button>
                </div>
              </Form.Item>
              {serverError && (
                <div
                  className="server-error ant-form-item-has-error"
                  style={{ marginTop: 16 }}
                >
                  <div className="ant-form-item-explain">{serverError}</div>
                </div>
              )}
            </Form>
          </>
        )}
      </Card>
      <style jsx>{`
        .page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 10vh;
          padding-bottom: 10vh;
        }
        .subtitle {
          font-size: 24px;
        }
        .server-error {
          text-align: center;
        }
        .logo-img {
          height: 56px;
        }
        .logo {
          display: flex;
          justify-content: center;
          margin-bottom: 16px;
        }
        @media screen and (max-width: 600px) {
          .page {
            padding-top: 32px;
            padding-top: 64px;
          }
        }
      `}</style>
      <style jsx global>{`
        .qr-gen-title {
          text-align: center;
        }
        .signin-form {
          width: 32vw;
          padding-left: 32px;
          padding-right: 32px;
        }
        @media screen and (max-width: 992px) {
          .signin-form {
            width: 60vw;
          }
        }
        @media screen and (max-width: 600px) {
          .signin-form {
            width: calc(100vw - 32px);
            padding: 16px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      `}</style>
    </AuthPageTemplate>
  );
};

export default ForgotPassword;
