import { Space, Typography } from 'antd';
import React from 'react';
import PrintLoanAgreementBtn from './PrintLoanAgreementBtn';

const { Title, Text, Paragraph } = Typography;

const ReviewButtons = () => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 200,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
          }}
        >
          <PrintLoanAgreementBtn />
        </Space>
      </div>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ReviewButtons;
