export const AdoptionLevels = {
  ADOPTER_PRAY: {
    value: 0,
    key: 'ADOPTER_PRAY',
    label: 'Prayer',
    longLabel: 'Prayer',
    defaultMonthlyAmount: 0,
  },
  ADOPTER_LV1: {
    value: 1,
    key: 'ADOPTER_LV1',
    label: 'Level 1',
    longLabel: 'Level 1 ($30 a month)',
    defaultMonthlyAmount: 30,
  },
  ADOPTER_LV2: {
    value: 2,
    key: 'ADOPTER_LV2',
    label: 'Level 2',
    longLabel: 'Level 2 ($100 a month)',
    defaultMonthlyAmount: 100,
  },
  ADOPTER_LV3: {
    value: 3,
    key: 'ADOPTER_LV3',
    label: 'Level 3',
    longLabel: 'Level 3 ($300 a month)',
    defaultMonthlyAmount: 300,
  },
};
