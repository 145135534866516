import { gql } from '@apollo/client';

export const createHolisticProposalMutation = gql`
  mutation CreateHolisticProposalForUser(
    $report: HolisticProposalCreateInputForUser!
  ) {
    createHolisticProposalForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateHolisticProposalMutation = gql`
  mutation UpdateHolisticProposalForUser(
    $report: HolisticProposalUpdateInputForUser!
  ) {
    updateHolisticProposalForUser(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitHolisticProposalMutation = gql`
  mutation SubmitHolisticProposalForUser($reportId: ID!) {
    submitHolisticProposalForUser(reportId: $reportId)
  }
`;

export const unsubmitHolisticProposalMutation = gql`
  mutation unsubmitHolisticProposalForUser($reportId: ID!) {
    unsubmitHolisticProposalForUser(reportId: $reportId)
  }
`;
