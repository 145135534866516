import { Card, Typography } from 'antd';
import React from 'react';
import settings from '../../settings';
import RecentMiracles from '../people-groups/RecentMiracles';
import RecentPrayerRequests from '../people-groups/RecentPrayerRequests';

const { Title, Text } = Typography;

const ProjectInfo = ({ projectId, project }) => {
  return (
    <div>
      <Title level={3}>Project Description</Title>
      <Card style={{ marginBottom: 32 }}>{project?.desc}</Card>
      <RecentPrayerRequests filters={{ holisticProjectId: projectId }} />
      <RecentMiracles filters={{ holisticProjectId: projectId }} />
      <style jsx>{`
        .info {
          background: white;
          border-radius: 4px;
          border: 1px solid ${settings.colors.borderGray};
          padding: 24px;
          margin-bottom: 32px;
          display: flex;
          flex-wrap: wrap;
          margin-left: -16px;
          margin-right: -16px;
        }
        .info-item {
          margin-bottom: 16px;
          margin-right: 16px;
          margin-left: 16px;
          min-width: calc(50% - 32px);
        }
      `}</style>
    </div>
  );
};

export default ProjectInfo;
