import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ReloadOutlined, UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space, Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ChooseAccount from '../../components/ChooseAccount';
import { updateMouAction } from '../../redux-store/mous-store';
import { SelectFontStyles } from '../loan-agreements/SelectFont';
import ReviewButtons from './ReviewButtons';
import ViewMou from './ViewMou';
import { extractMou, mouQuery } from './constants';

const { Title, Text, Paragraph } = Typography;

function DisplayError({ error }) {
  if (error && error.message.includes('Insufficient permissions')) {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Paragraph style={{ fontSize: 14 }}>
          {`It looks like you don't have permission to view this loan agreement.`}
        </Paragraph>
        <Paragraph style={{ fontSize: 14 }}>
          Try changing your account and refreshing the page.
        </Paragraph>
        <ChooseAccount layout="vertical" />
        <div style={{ marginTop: 16 }}>
          <Button onClick={() => location.reload()} icon={<ReloadOutlined />}>
            Refresh
          </Button>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Title type="danger" level={4}>
          Error
        </Title>
        <Text type="danger">{error.message}</Text>
      </div>
    );
  }
  return null;
}

function MouPage() {
  const { mouId } = useParams();
  const queryId = 'sign-mou';

  const { loading, error } = useOneM1({
    variables: { _id: mouId },
    query: mouQuery,
    extract: extractMou,
    queryId,
    updateAction: updateMouAction,
    skip: !mouId,
    showError: true,
  });
  const mou = useSingleSomethingA10('mous', mouId, queryId);

  return (
    <>
      <CSPage title="AIMS.org | Partnership Commitment Form">
        <CSPageHeader
          title="View Agreement"
          parents={[
            {
              label: `Partnership Commitment Forms`,
              link: `/mous`,
            },
          ]}
        />
        <DisplayError error={error} />
        <div className="container">
          {mou && <ViewMou mou={mou} queryId={queryId} />}
          {loading && (
            <Space direction="vertical">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <SelectFontStyles />
      {!error && <ReviewButtons />}
      <style jsx>{`
        .container {
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
        }
        @media print {
          .container {
            max-width: unset;
            margin-left: unset;
            margin-right: unset;
          }
        }
      `}</style>
      <style jsx global>{`
        @media print {
          * {
            visibility: hidden;
          }
          #mou {
            visibility: visible;
            box-shadow: none !important;
          }
          #mou * {
            visibility: visible;
          }
          main.ant-layout-content.page {
            margin: 0px !important;
            padding: 0px !important;
            width: unset !important;
          }
          #verifyMyEmail,
          #mainNavHeader,
          #csPageHeader {
            display: none !important;
          }
        }
      `}</style>
    </>
  );
}

export default MouPage;
