import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Empty, List, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import settings from '../../../settings';
import { useAllHolisticProjects } from '../../holistic-projects/use-all-holistic-projects';

const { Title, Text } = Typography;

function PeopleGroupProjects({ peopleGroupId }) {
  const { data: holisticProjects, loading } = useAllHolisticProjects({
    filters: { peopleGroupId },
    queryId: 'peopleGroupProjects',
  });

  return (
    <div>
      <List
        dataSource={holisticProjects}
        rowKey="_id"
        renderItem={(item) => (
          <div className="info">
            <div className="info-item top-actions">
              <div className="title">
                <Title level={3} style={{ marginBottom: 0, marginTop: 0 }}>
                  {item.projectName}
                </Title>
                {item.peopleGroups &&
                  item.peopleGroups.map((pg, index) => (
                    <Text
                      key={index}
                      style={{
                        color: settings.colors.darkGray,
                        display: 'block',
                      }}
                    >
                      {pg.nameAcrossCountries} - {pg.country}
                    </Text>
                  ))}
              </div>
              <Link to={`/holistic-projects/${item._id}/info`}>
                <Button icon={<EyeOutlined />}>View</Button>
              </Link>
            </div>
            <div className="info-item">
              <Title
                level={5}
                style={{
                  display: 'inline-block',
                  marginRight: 8,
                  marginBottom: 0,
                }}
              >
                Project Type: {HolisticProjectTypes[item.typeOfProject].label}
              </Title>
            </div>
            <div className="info-item">
              <Title
                level={5}
                style={{
                  display: 'inline-block',
                  marginRight: 8,
                  marginBottom: 0,
                }}
              >
                Description:
              </Title>
              {ellipsis(item.desc, 750)}
            </div>
          </div>
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Holistic Projects"
            />
          ),
        }}
      />
      <style jsx>{`
        .info {
          background: white;
          border-radius: 4px;
          padding: 24px;
          margin-bottom: 32px;
          display: flex;
          flex-wrap: wrap;
          margin-left: -16px;
          margin-right: -16px;
        }
        .info-item {
          margin-bottom: 16px;
          margin-right: 16px;
          margin-left: 16px;
          min-width: calc(100% - 32px);
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
        }
      `}</style>
    </div>
  );
}

export default PeopleGroupProjects;
