import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import UserQuestions from '@aims/shared/users/questions';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, notification, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';
import { editProfileMutation } from './constants';

const { Title, Text, Paragraph } = Typography;

const ChangePasswordModal = ({ user, onFinish, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [updateUser] = useMutation(editProfileMutation);

  const handleFinish = useCallback(
    async (_values) => {
      setLoading(true);
      const { confirm, password } = _values;
      try {
        await updateUser({
          variables: {
            profile: {
              _id: user._id,
              password,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Password updated successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setLoading(false);
    },
    [user, updateUser, onFinish, setLoading],
  );
  return (
    <Modal
      title={null}
      open={!!user}
      closable={false}
      destroyOnClose={true}
      maskClosable={!loading}
      footer={null}
    >
      <Title level={3} style={{ textAlign: 'center' }}>
        Change My Password
      </Title>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <UserQuestions.password.FormItem loading={loading} />
        <UserQuestions.confirmPassword.FormItem loading={loading} />
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              key="cancel"
              onClick={onCancel}
              htmlType="button"
              type="text"
              disabled={loading}
              icon={<ArrowLeftOutlined />}
              style={{ marginRight: 16 }}
            >
              Cancel
            </Button>
            <Button
              key="send"
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Change Password
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
