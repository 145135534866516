import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportPeopleGroupAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../../reports/constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';

const UpgQuarterlyReportFullAttributes = gql`
  {
    _id
    quarter
    regionCode
    regionName
    contactId
    contact {
      _id
      fullName
    }
    reportType
    status
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    isSubmitted
    isApproved
    submittedAt
    submittedBy
    submittedByUser ${ReportUserShortAttributes}
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    notes
    createdBy
    createdByUser ${ReportUserShortAttributes}
    updatedBy
    updatedByUser ${ReportUserShortAttributes}
    createdAt
    updatedAt
    deletedBy
    deletedAt
    peopleGroupId
    peopleGroup ${ReportPeopleGroupAttributes}
    onBehalfOfName
    onBehalfOfId
    receivedFinancialSupport
    receivedForHimself
    receivedForHolistic
    useOfHolisticFundsDesc
    receivedTotal
    hProjects {
      projectId
      status
      results
    }
    churchesPlanted {
      villageName
      country
      numChurches
      numBelievers
    }
    numChurchesPlanted
    numHeardForFirstTime
    numSalvations
    numWaterBaptisms
    miracles {
      _id
      desc
    }
    prayerRequests {
      _id
      desc
    }
  }
`;

const query = gql`
  query UpgQuarterlyReportForUser($_id: ID!) {
    upgQuarterlyReportForUser(_id: $_id) ${UpgQuarterlyReportFullAttributes}
  }
`;

const extract = (data) => data?.upgQuarterlyReportForUser;

const UpgQuarterlyReportUpdateAttributes = gql`
{
  crud
  id
  new ${UpgQuarterlyReportFullAttributes}
}
`;

export const upgQuarterlyReportUpdatesSubscription = gql`
  subscription UpgQuarterlyReportUpdatesForUser($_id: ID!) {
    upgQuarterlyReportUpdatesForUser(_id: $_id) ${UpgQuarterlyReportUpdateAttributes}
  }
`;

function useReport(_id, queryId = 'useUpgQuarterlyReport', showError = true) {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    queryId,
    showError,
  });
  const report = useSingleSomethingA10('reports', _id, queryId);

  return {
    report,
    loading,
    refetch,
  };
}

export default useReport;
