import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, Typography } from 'antd';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import client from './apollo-client';
import AppLoading from './components/AppLoading';
import AuthRouter from './pages/auth/AuthRouter';
import MainRouter from './pages/main/MainRouter';
import { AuthContextProvider } from './shared/auth-context';
import { useWindowDimensions } from './shared/window-dimensions';

const { Text } = Typography;

const App = () => {
  useWindowDimensions();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#336195',
        },
        components: {
          Table: {
            cellPaddingInlineMD: 12,
            headerBorderRadius: 2,
          },
          Form: {
            itemMarginBottom: 12,
          },
          Input: {
            borderRadius: 2,
            controlHeight: 40,
          },
          InputNumber: {
            borderRadius: 2,
            controlHeight: 40,
          },
          Select: {
            borderRadius: 2,
            controlHeight: 40,
          },
          Button: {
            controlHeight: 40,
          },
          Typography: {
            titleMarginTop: 0,
          },
          Radio: {
            controlHeight: 40,
          },
        },
      }}
    >
      <AuthContextProvider>
        {(context) => (
          <>
            {context.state.isLoading ? (
              <AppLoading />
            ) : (
              <ApolloProvider client={client}>
                <Router>
                  <React.Suspense fallback={<AppLoading />}>
                    {context.state.user ? <MainRouter /> : <AuthRouter />}
                  </React.Suspense>
                </Router>
              </ApolloProvider>
            )}
          </>
        )}
      </AuthContextProvider>
    </ConfigProvider>
  );
};

export default App;
