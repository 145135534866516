import { PrinterOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';

const { Title, Text, Paragraph } = Typography;

const PrintLoanAgreementBtn = ({ title = 'Print Agreement' }) => {
  return (
    <>
      <Button icon={<PrinterOutlined />} onClick={() => window.print()}>
        {title}
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default PrintLoanAgreementBtn;
