import PhotoCircle from '@aims/shared/components/PhotoCircle';
import sharedSettings from '@aims/shared/sharedSettings';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, List, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import settings from '../../../settings';

const { Text, Title } = Typography;

const CourseCard = ({ course }) => {
  return (
    <List.Item style={{ width: '100%', border: 'none' }}>
      <Card bordered={false}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            <PhotoCircle
              photoUrl={course.primaryThumbnailUrl}
              styles={{
                container: { height: 80, width: 80, marginRight: 24 },
                placeholder: {
                  backgroundColor: sharedSettings.colors.primary,
                },
              }}
              name={course.courseName}
            />
            <div>
              <Title
                level={4}
                style={{ whiteSpace: 'break-spaces', marginBottom: 4 }}
              >
                {course.courseName}
              </Title>
              {course.desc && <Text>{ellipsis(course.desc, 100)}</Text>}
            </div>
          </div>
          <div style={{ flex: 1 }} />
          <div>
            <Space>
              <div style={{ marginRight: 16 }}>
                <Text style={{ color: settings.colors.textGray }}>{`${
                  course.numFiles
                } File${course.numFiles == 1 ? '' : 's'}`}</Text>
              </div>
              <Tooltip title="View">
                <Link to={`/training/courses/view/${course._id}`}>
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          </div>
        </div>
      </Card>
    </List.Item>
  );
};

export default CourseCard;
