import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { signMouMutation } from './constants';
import { updateMouAction } from '../../redux-store/mous-store';
import SelectFont from '../loan-agreements/SelectFont';

const { Title, Text, Paragraph } = Typography;

const SignMouModal = ({ visible, queryId, onSuccess, onCancel }) => {
  const { party, loanAgreement: mou } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [signMou] = useMutation(signMouMutation);
  const dispatch = useDispatch();

  useEffect(() => {
    setError(null);
  }, [visible]);

  const handleFinish = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        const result = await signMou({
          variables: {
            signature: {
              mouId: mou._id,
              signature: {
                partyId: party._id,
                signature: values.signature,
                fontName: values.fontName,
                date: new Date().toISOString(),
              },
            },
          },
        });
        dispatch(updateMouAction(result.data.signMouForUser.mou, queryId));
        notification.success({
          message: 'Success',
          description: 'Partnership Commitment Form Signed',
        });
        localStorage.setItem('signatureFont', values.fontName);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [setError, dispatch, queryId, signMou, mou, onSuccess, party],
  );

  return (
    <>
      <Modal
        header={null}
        footer={null}
        open={!!visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={onCancel}
        styles={{ body: { paddingTop: 48 } }}
      >
        {visible && (
          <>
            <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
              Sign Partnership Commitment Form
            </Title>
            <Form onFinish={handleFinish} layout="vertical">
              <Form.Item
                label="Font"
                name="fontName"
                rules={[{ required: true, message: 'This field is required ' }]}
                initialValue={localStorage.getItem('signatureFont')}
              >
                <SelectFont disabled={loading} />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.fontName !== curValues.fontName
                }
                noStyle
              >
                {({ getFieldValue }) => (
                  <Form.Item
                    label="Signature"
                    name="signature"
                    extra="Type your name here"
                    rules={[
                      { required: true, message: 'This field is required ' },
                    ]}
                  >
                    <Input
                      style={{
                        fontFamily: getFieldValue('fontName') || undefined,
                        fontSize: getFieldValue('fontName') ? 24 : undefined,
                      }}
                      placeholder="Your signature here"
                      disabled={loading}
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item>
                <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
                  {`By signing this agreement you are declaring that you are ${party.contactName} and that you have read and
                  agree with everything in this document.`}
                </Paragraph>
                <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
                  {`You can not undo this action.`}
                </Paragraph>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  style={{
                    marginRight: -8,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={onCancel}
                    htmlType="button"
                    disabled={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Sign Agreement
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {error && (
              <div style={{ textAlign: 'center', marginTop: 16 }}>
                <Text type="danger">{error}</Text>
              </div>
            )}
          </>
        )}
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default SignMouModal;
