import { LoadingOutlined } from '@ant-design/icons';
import { Steps, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import useQueryParams from '../../../shared/use-query-params';
import useResponsiveMode from '../../../shared/use-reponsive-mode';
import { AdoptionLevels } from '@aims/shared/adoption/constants';
import useProfile from '../../../shared/use-profile';
import Error404 from '../../../components/Error404';
import { generateUuid } from '../../../shared/utils';
import queryString from 'query-string';
import moment from 'moment';
import useAccount from '../../../shared/use-account';
import CatalogSteps from './CatalogSteps';
import CatalogBannerImage from './CatalogBannerImage';

const { Text, Title, Paragraph } = Typography;

function AdoptAUpgRedirectToPP() {
  const { pgId } = useParams();
  const queryParams = useQueryParams();
  const responsive = useResponsiveMode();
  const [error, setError] = useState();
  const profile = useProfile();
  const account = useAccount();

  const handleAdopt = useCallback(
    (values) => {
      // console.log('values', values);
      const level = AdoptionLevels[values.level];
      const recurring = values.recurring === 'recurring';
      let amount;
      if (recurring) {
        amount = level.defaultMonthlyAmount;
      } else {
        amount = level.defaultMonthlyAmount * 12;
      }
      const startDate = values.startDate && moment(values.startDate);
      const notes = JSON.stringify({
        t: recurring ? 'AdoptionRecurring' : 'AdoptionOneTime',
        aid: values.adoptionId || generateUuid(),
        uid: profile?._id,
        cid: account && account._id,
        pid: pgId === '__aims__' ? undefined : pgId,
        l: values.level,
        ma: level.defaultMonthlyAmount,
        cta: !!values.commitToAdopt,
      });
      const params = {
        a: amount,
        al: true,
        r: recurring ? 'monthly' : 'no',
        rcv: false,
        rsd: recurring ? startDate.format('YYYY-MM-DD') : undefined,
        ret: recurring ? 'date' : undefined,
        red: recurring
          ? startDate.add(1, 'year').format('YYYY-MM-DD')
          : undefined,
        fnd: 'Sponsor a Native Missionary',
        fndv: 'hide',
        ufn: profile?.name.split(/\s/)[0],
        uln: profile?.name.split(/\s/).slice(1).join(' '),
        ue: profile?.email,
        up: profile?.phone,
        ru: 'adopt-a-pg-success',
        nt: notes,
      };
      const link = `${settings.pushpayGivingUrl}?${queryString.stringify(
        params,
      )}`;
      location.href = link;
    },
    [profile, pgId, account],
  );

  useEffect(() => {
    if (queryParams) {
      const { level, recurring, startDate } = queryParams;
      let err;
      if (!Object.keys(AdoptionLevels).includes(level)) {
        err = 'Level is required';
      } else if (!recurring) {
        err = 'Recurring is required';
      } else if (recurring === 'recurring' && !startDate) {
        err = 'Start date is required';
      }
      if (err) {
        setError(err);
        return;
      }
      handleAdopt(queryParams);
    }
  }, [queryParams, handleAdopt]);

  const step = 2;

  if (error) {
    return <Error404 />;
  }

  return (
    <CSPage title="AIMS.org | Adopt a People Group">
      <CSPageHeader
        title="Adopt a People Group"
        parents={[
          {
            label: 'Partner with Us',
            link: `/partner`,
          },
        ]}
      />
      <CatalogBannerImage />
      <CatalogSteps step={3} title="Payment" />
      <Title
        level={2}
        style={{
          marginTop: responsive === 'mobile' ? 0 : 24,
          marginBottom: 8,
          textAlign: 'center',
        }}
      >
        Payment
      </Title>
      <div style={{ textAlign: 'center' }}>
        <div style={{ paddingTop: 32, marginBottom: 16 }}>
          <LoadingOutlined
            style={{ color: settings.colors.primary, fontSize: 64 }}
          />
        </div>
        <Paragraph style={{ fontSize: 16 }}>
          You will be redirected. One moment ...
        </Paragraph>
      </div>
      <style jsx>{``}</style>
    </CSPage>
  );
}

export default AdoptAUpgRedirectToPP;
