import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  ConfigProvider,
  Divider,
  Form,
  Typography,
} from 'antd';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { updateHolisticProjectAction } from '../../../../redux-store/holistic-projects-store';
import settings from '../../../../settings';
import useQueryParams from '../../../../shared/use-query-params';
import HolisticSteps from '../HolisticSteps';
import {
  extractPublicHolisticProject,
  publicHolisticProjectQuery,
} from '../constants';
import ProjectBanner from '../step-2-details/ProjectBanner';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';

const { Title, Paragraph, Text } = Typography;

const queryId = 'sponsorAProject';

function SponsorAProjectPeoples({ step, title, projectId }) {
  const { loading: projectLoading, refetch } = useOneM1({
    variables: { _id: projectId },
    query: publicHolisticProjectQuery,
    extract: extractPublicHolisticProject,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !projectId,
    showError: true,
  });
  const project = useSingleSomethingA10('holisticProjects', projectId, queryId);

  const peopleGroups = (project && project.peopleGroups) || [];

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const queryParams = useQueryParams();

  const handleFinish = useCallback(
    (values) => {
      setLoading(true);
      const link = `/partner/sponsor-a-project/${projectId}/redirect?${queryString.stringify(
        {
          ...values,
          ...queryParams,
        },
      )}`;
      history.push(link);
    },
    [projectId, history, queryParams],
  );
  return (
    <>
      <ProjectBanner project={project} loading={projectLoading} />
      <HolisticSteps step={step} title={title} />
      <div
        className="container"
        style={{ display: 'flex', marginRight: -16, marginLeft: -16 }}
      >
        <div style={{ marginLeft: 16, marginRight: 16 }} className="left">
          <Title level={3} style={{ textAlign: 'center' }}>
            Thank you for committing to give!
          </Title>
          <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>
            We know you&apos;re not just giving finances — you&apos;re sowing
            into the Kingdom. Will you take this one step further and{' '}
            <Text style={{ fontSize: 16, fontWeight: 800 }}>
              ADOPT (commit to pray regularly for)
            </Text>{' '}
            the Unreached People Group(s) impacted by this project?
          </Paragraph>
          <Title level={3} style={{ textAlign: 'center' }}>
            {peopleGroups.length > 1
              ? 'Unreached People Groups that will be impacted'
              : 'Unreached People Group that will be impacted'}
          </Title>

          <div className="pg-circles">
            {peopleGroups.map((pg) => (
              <div key={pg._id}>
                <div className="pg-circle">
                  <div className="pg-circle-initial">
                    {pg.nameAcrossCountries[0]}
                  </div>
                  <img
                    className="pg-photo"
                    src={pg.primaryThumbnailUrl}
                    alt={pg.nameAcrossCountries}
                  />
                </div>
                <div className="pg-text">{pg.nameAcrossCountries}</div>
              </div>
            ))}
          </div>
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          style={{ textAlign: 'center' }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                background: settings.colors.white,
                padding: '24px 32px',
                paddingBottom: 16,
                marginBottom: 32,
                width: 330,
                marginLeft: 16,
                marginRight: 16,
              }}
            >
              <Title level={3} style={{ textAlign: 'center' }}>
                Confirm Donation Amount
              </Title>
              <div style={{ marginBottom: 24 }}>
                <Text
                  style={{
                    fontWeight: 800,
                    fontSize: 24,
                    color: settings.colors.holisticGreen,
                  }}
                >
                  {displayMoney(getNumberValue(queryParams?.amount))}
                </Text>
              </div>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: settings.colors.holisticGreen,
                  },
                }}
              >
                <Form.Item
                  name="commitToAdopt"
                  initialValue={true}
                  valuePropName="checked"
                  style={{ marginBottom: 24 }}
                >
                  <Checkbox
                    disabled={loading}
                    style={{ fontSize: 16, letterSpacing: 1.25 }}
                  >
                    {peopleGroups.length > 1
                      ? `Yes, I will ADOPT these Unreached People Groups.`
                      : `Yes, I will ADOPT this Unreached People Group.`}
                  </Checkbox>
                </Form.Item>
              </ConfigProvider>
              <Form.Item>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ width: '100%' }}
                  >
                    Proceed to Payment
                  </Button>
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      {/* <Divider /> */}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link
          to={`/partner/sponsor-a-project/${projectId}${location.search}`}
          style={{ marginLeft: -12 }}
        >
          <Button type="dashed" icon={<ArrowLeftOutlined />} disabled={loading}>
            Back
          </Button>
        </Link>
      </div>
      <style jsx>{`
        .pg-circle {
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${settings.colors.holisticGreen};
          height: 160px;
          width: 160px;
          margin-right: 12px;
          margin-left: 12px;
          margin-bottom: 8px;
          border: 3px solid ${settings.colors.holisticLightGreen};
        }
        .pg-circles {
          display: flex;
          justify-content: center;
          margin-right: -12px;
          margin-left: -12px;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
        .pg-circle-initial {
          color: white;
          font-size: 24px;
        }
        .pg-photo {
          object-fit: cover;
          position: absolute;
          height: 160px;
          width: 160px;
          top: 0;
          left: 0;
        }
        .pg-text {
          font-size: 18px;
          font-weight: 500;
          text-align: center;
        }
        .container {
          flex-wrap: nowrap;
        }
        @media screen and (max-width: 1300px) {
          .container {
            flex-wrap: wrap;
            justify-content: center;
          }
          .left {
            margin-bottom: 24px;
          }
        }
        @media screen and (max-width: 600px) {
        }
      `}</style>
    </>
  );
}

export default SponsorAProjectPeoples;
