import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import {
  ReportApprovalStatuses,
  ReportTypes,
} from '@aims/shared/reports/constants';
import TrainingQuarterlyReportItems from '@aims/shared/reports/training-quarterly/TrainingQuarterlyReportItems';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useProfile from '../../../shared/use-profile';
import DeleteReportButton from './DeleteReportButton';
import {
  createTrainingQuarterlyReportMutation,
  submitTrainingQuarterlyReportMutation,
  unsubmitTrainingQuarterlyReportMutation,
  updateTrainingQuarterlyReportMutation,
} from './contants';
import useReport from './use-report';

const { Title, Text } = Typography;

const queryId = 'editTrainingQuarterlyReport';

function EditTrainingQuarterlyReport() {
  const { reportId } = useParams();
  const profile = useProfile();

  const {
    report,
    loading: loading1,
    refetch,
  } = useReport(reportId, queryId, false);

  const [form] = Form.useForm();
  const finishSubForm = useRef();
  const shouldSubmitReport = useRef(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [updateReport] = useMutation(updateTrainingQuarterlyReportMutation);
  const [createReport] = useMutation(createTrainingQuarterlyReportMutation);
  const [submitReport] = useMutation(submitTrainingQuarterlyReportMutation);
  const [unsubmitReport] = useMutation(unsubmitTrainingQuarterlyReportMutation);
  const history = useHistory();

  const onSubmit = useCallback(
    async (__values) => {
      let msg;
      if (shouldSubmitReport.current === 'submit') {
        msg = 'Submitting Report ...';
      } else if (shouldSubmitReport.current === 'unsubmit') {
        msg = 'Unsubmitting Report ...';
      } else {
        msg = 'Saving Draft ...';
      }
      setSaveLoading(msg);
      const { ..._values } = __values;
      try {
        const reportDetails = finishSubForm.current(_values);
        if (report && shouldSubmitReport.current === 'unsubmit') {
          await unsubmitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report unsubmitted successfully',
          });
        }
        if (report) {
          await updateReport({
            variables: {
              report: {
                _id: reportId,
                reportType: ReportTypes.TRAIN_QUARTERLY.key,
                contactId: profile.contactId,
                ...reportDetails,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report updated successfully',
          });
        } else {
          await createReport({
            variables: {
              report: {
                _id: reportId,
                contactId: profile.contactId,
                reportType: ReportTypes.TRAIN_QUARTERLY.key,
                ...reportDetails,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report created successfully',
          });
        }
        if (shouldSubmitReport.current === 'submit') {
          await submitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report submitted successfully',
          });
          history.push(`/training-reports/quarterly/view/${reportId}`);
        } else {
          refetch();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report.',
        });
      }
      setSaveLoading(false);
    },
    [
      reportId,
      createReport,
      history,
      profile,
      report,
      submitReport,
      unsubmitReport,
      updateReport,
      refetch,
    ],
  );

  const title = 'Training Quarterly Report';

  const loading = loading1 || saveLoading;
  console.log('loading', loading1, saveLoading, loading);
  const showDelete = report && !report.submittedAt;

  return (
    <CSPage title={`AIMS.org | ${title}`}>
      <CSPageHeader
        titleComponent={null}
        title={title}
        parents={[
          {
            label: 'Training',
            link: `/training`,
          },
          {
            label: 'Quarterly Reports',
            link: `/training-reports/quarterly`,
          },
        ]}
      />
      <Form
        layout="vertical"
        onFinish={onSubmit}
        id="EditReportForm"
        form={form}
        style={{
          maxWidth: 800,
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        {loading1 ? (
          <>
            <Title level={3} style={{ textAlign: 'center' }}>
              {title}
            </Title>
            <Skeleton active title paragraph />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title paragraph />
            <Form.Item>
              <Input disabled />
            </Form.Item>
            <Skeleton active title paragraph />
          </>
        ) : (
          <>
            <TrainingQuarterlyReportItems
              _id={reportId}
              report={report}
              loading={loading}
              form={form}
              finishSubForm={finishSubForm}
              fieldsToSet={{}}
              showTitle
            />
          </>
        )}
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <Space
            style={{
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {(!report ||
              report?.status === ReportApprovalStatuses.DRAFT.key) && (
              <>
                <Button
                  key="draft"
                  type="dashed"
                  loading={loading}
                  htmlType="button"
                  onClick={() => {
                    shouldSubmitReport.current = '';
                    form.submit();
                  }}
                >
                  Save Draft
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  htmlType="button"
                  onClick={() => {
                    shouldSubmitReport.current = 'submit';
                    form.submit();
                  }}
                >
                  Submit Report
                </Button>
              </>
            )}
            {report?.status === ReportApprovalStatuses.PENDING.key && (
              <Button
                key="unsubmit"
                type="dashed"
                loading={loading}
                htmlType="button"
                onClick={() => {
                  shouldSubmitReport.current = 'unsubmit';
                  form.submit();
                }}
              >
                Unsubmit Report
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
      {showDelete && <DeleteReportButton report={report} />}
      <Modal open={saveLoading} footer={null} closable={false} width={300}>
        <div style={{ textAlign: 'center', padding: 24 }}>
          <LoadingOutlined style={{ fontSize: 32, marginBottom: 16 }} />
          <Title level={4}>{saveLoading}</Title>
        </div>
      </Modal>
    </CSPage>
  );
}

export default EditTrainingQuarterlyReport;
