import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import bannerImgLg from '../../../assets/UPGCatalogBanner-lg.jpg';
import bannerImgMd from '../../../assets/UPGCatalogBanner-md.jpg';
import bannerImgSm from '../../../assets/UPGCatalogBannerZoomed-sm.jpg';
import settings from '../../../settings';
import { useWindowDimensions } from '../../../shared/window-dimensions';

const { Text, Title, Paragraph } = Typography;

function CatalogBannerImage() {
  const dims = useWindowDimensions();
  let mobile = false;
  if (dims && dims.width <= 600) {
    mobile = true;
  }
  return (
    <>
      {mobile ? (
        <img className="banner-img" src={bannerImgSm} />
      ) : (
        <img
          className="banner-img"
          src={bannerImgLg}
          srcSet={`${bannerImgSm} 1000w,
        ${bannerImgMd} 2000w,
        ${bannerImgLg} 3000w`}
          sizes={`(max-width: ${settings.screenSizes.md600}px) 1000w,
        (max-width: ${settings.screenSizes.lg992}px) 2000w,
            3000w`}
        />
      )}
      <style jsx>{`
        .banner-img {
          width: 100%;
          margin-bottom: 32px;
          max-width: 3000px;
          margin-right: auto;
          margin-left: auto;
        }
      `}</style>
    </>
  );
}

export default CatalogBannerImage;
