import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import { updateHolisticProjectAction } from '../../redux-store/holistic-projects-store';

const query = gql`
  query HolisticProjectForCoord($_id: ID!) {
    holisticProjectForCoord(_id: $_id) {
      _id
      projectNo
      applicationId
      regionCode
      region
      regionalCoordId
      regionalCoordinator {
        _id
        fullName
        contactType
        primaryThumbnailUrl
      }
      peopleGroupIds
      peopleGroups {
        _id
        population
        continentCode
        continent
        region
        regionCode
        countryCode
        country
        nameAcrossCountries
        primaryLanguage
        officialLanguage
        primaryReligion
        religionSubdivision
        primaryThumbnailUrl
        updatedAt
      }
      projectName
      typeOfProject
      desc
      stage
      loanTotalX4
      loanTerm
      loanStartDate
      supportedFieldWorkers {
        contactId
        contact {
          _id
          fullName
          contactType
          primaryThumbnailUrl
        }
        totalReceived
      }
      numSupported
      responsiblePersons {
        contactId
        contact {
          _id
          fullName
          contactType
          primaryThumbnailUrl
        }
        role
      }
      tags {
        key
        color
      }
      photoIds
      primaryPhotoId
      primaryThumbnailUrl
      cumulativeStats {
        numMiraclesReported
        hasReceivedFunds
        loanPaymentsMade
        cumulativeExpenses
        cumulativeRevenue
        latestExchangeRate
        latestCurrency
        numProgressReports
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

const extract = (data) => data.holisticProjectForCoord;

function useHolisticProjectForCoord(
  _id,
  queryId = 'useHolisticProjectForCoord',
) {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !_id,
    showError: true,
  });
  const data = useSingleSomethingA10('holisticProjects', _id, queryId);

  return {
    data,
    loading,
    refetch,
  };
}

export default useHolisticProjectForCoord;
