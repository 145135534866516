const settings = {
  smallPage: 5,
  pageSize: 25,
  querySize: 100,
  cacheTimeout: 300000,
  publicUrl: process.env.REACT_APP_PUBLIC_URL || 'https://aims.org',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8003/graphql/',
  apiWsUrl: process.env.REACT_APP_API_WS_URL || 'ws://localhost:8003/graphql/',
  webUrl: process.env.REACT_APP_WEB_URL || 'http://localhost:3001/',
  googleOAuthRedirect:
    process.env.REACT_APP_GOOGLE_REDIRECT_URL || 'http://localhost:3001/home/',
  googleOAuthClientId:
    '338173280-2f6je3pjjevo3v2rv7h47ah982414ojv.apps.googleusercontent.com',
  recaptchaPublicKey: '6Lfb6t4eAAAAACVn60WFWxgPk9UpO6oZlg2zar0q',
  pushpayGivingUrl:
    process.env.REACT_APP_PUSHPAY_GIVING_URL ||
    'https://sandbox.pushpay.io/g/sandboxaimscs',
  screenSizes: {
    xl1200: 1200,
    lg992: 992,
    md600: 600,
    sm400: 400,
  },
  timeFormat: 'h:mm a',
  colors: {
    white: '#FFFFFF',
    textBlack: '#333333',
    primary: '#336195',
    warning: '#E8BD4E',
    // ghost: '#eef1f2',
    ghost: '#efefef',
    gray: '#E5E5E5',
    error: '#ff4d4f',
    red: '#f5222d',
    // borderGray: '#d9d9d9',
    // borderGray: '#d7dfe0',
    borderGray: '#cccccc',
    backgroundGray: '#dddddd',
    textGray: '#838485',
    contactColor: '#006d75',
    householdColor: '#cf1322',
    organizationColor: '#d4380d',
    timestampColor: '#c2c2c2',
    success: '#52c41a',
    darkGray: '#95a8ad',
    // yellow: '#eebc10',
    yellow: '#e29919',
    blue: '#0050b3',
    holisticGreen: '#6b9370',
    // holisticLightGreen: '#bbcab8',
    holisticLightGreen: '#97b49b',
    holisticText: '#4b4b4b',
    holisticDarkGreen: '#4b684e',
    holisticGray: '#c3c3c3',
  },
  tagColors: [
    '#cf1322',
    '#d4380d',
    '#d46b08',
    '#d48806',
    '#d4b106',
    '#7cb305',
    '#389e0d',
    '#08979c',
    '#096dd9',
    '#1d39c4',
    '#531dab',
    '#c41d7f',
    '#8c8c8c',
  ],
};

export default settings;
