import { Card, Form, Typography } from 'antd';
import React, { useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../../../components/CSPage';
import CSPageHeader from '../../../../components/CSPageHeader';
import SelectLanguage from '../../SelectLanguage';
import useTrainingFiles from '../../files/use-training-files';
import useTrainingCourse from '../use-training-course';
import ModuleItem from './ModuleItem';
import TrainingCourseTitle from './TrainingCourseTitle';
import LanguagesFormItem from '../../training-languages/LanguagesFormItem';

const { Paragraph, Title } = Typography;

const queryId = 'viewTrainingCourse';

function ViewTrainingCourse() {
  const { _id } = useParams();
  const { data: course, loading: loading1 } = useTrainingCourse(_id);

  const filters = useRef({ courseId: _id, language: 'english' });
  const {
    data: trainingFiles,
    loading: loading2,
    refetch,
  } = useTrainingFiles(filters.current, queryId);

  const languages = useMemo(() => {
    if (trainingFiles) {
      return Object.keys(
        trainingFiles.reduce((prev, curr) => {
          prev[curr.language] = curr.language;
          return prev;
        }, {}),
      );
    }
    return [];
  }, [trainingFiles]);

  const loading = loading1 || loading2;

  const onFormChange = useCallback(
    (changed) => {
      filters.current = {
        ...filters.current,
        ...changed,
      };
      refetch();
    },
    [refetch],
  );

  return (
    <>
      <CSPage title={`AIMS.org | ${course?.courseName || 'Training Course'}`}>
        <CSPageHeader
          titleComponent={null}
          title={course?.courseName || 'Training Course'}
          parents={[
            {
              label: 'Training',
              link: `/training/courses`,
            },
            {
              label: 'Courses',
              link: `/training/courses`,
            },
          ]}
        />
        <TrainingCourseTitle course={course} />
        {course?.desc && (
          <div style={{ marginBottom: 16 }}>
            <Title level={3} style={{ marginTop: 0 }}>
              Description
            </Title>
            <Card>
              <Paragraph>{course.desc}</Paragraph>
            </Card>
          </div>
        )}
        <div style={{ marginBottom: 24 }}>
          <Form
            layout="vertical"
            onValuesChange={onFormChange}
            initialValues={filters.current}
          >
            <LanguagesFormItem
              name="language"
              label="Filter Files by Language"
            />
          </Form>
        </div>
        {course?.modules?.map((m) => (
          <ModuleItem
            key={m._id}
            module={m}
            files={trainingFiles}
            loading={loading}
          />
        ))}
      </CSPage>
    </>
  );
}

export default ViewTrainingCourse;
