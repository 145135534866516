import settings from './settings';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { ajax } from 'rxjs/ajax';
import { tap, map, catchError, mergeAll } from 'rxjs/operators';
import { of, Subject, merge } from 'rxjs';

const httpLink = createUploadLink({
  uri: settings.apiUrl,
  fetch: async (url, options) => {
    if (options.onProgress) {
      console.log('***** fetch *****');
      console.log(url, options);
      // const resp = await fetch(url, options);
      // console.log('response', resp);
      // return resp;
      const progressSubscriber = new Subject();
      const request$ = ajax({
        url,
        ...options,
        progressSubscriber,
      });
      progressSubscriber.subscribe((event) => options.onProgress(event));
      return request$
        .pipe(
          tap((response) => console.log(response)),
          map((response) => {
            return {
              ...response,
              text: async () => JSON.stringify(response.response),
            };
          }),
          // catchError((error) => {
          //   console.error('in fetch', error);
          //   return of({
          //     isSuccess: false,
          //     message: error.response ? error.response.detail : error.message,
          //   });
          // }),
        )
        .toPromise();
    }
    return fetch(url, options);
  },
});

function getConnectionParams() {
  const token = localStorage.getItem('authToken');
  const contactId = localStorage.getItem('contactId');
  return {
    authToken: `Bearer ${token}`,
    contactId,
  };
}

const wsLink = new WebSocketLink({
  uri: settings.apiWsUrl,
  options: {
    reconnect: true,
    connectionParams: getConnectionParams,
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('authToken');
  const contactId = localStorage.getItem('contactId');
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
        Contact: contactId,
      },
    });
  }
  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  concat(authMiddleware, httpLink),
);

class ApolloClientWS extends ApolloClient {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  close() {
    return wsLink.subscriptionClient.close();
  }
}

const client = new ApolloClientWS({
  cache: new InMemoryCache(),
  link: splitLink,
});

export default client;
