import { ViewPgQuarterlyReport } from '@aims/shared/reports';
import { Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import usePeopleGroup from '../use-people-group';
import useReport from './use-report';

const { Title, Text } = Typography;

function ViewQuarterlyReport() {
  const { peopleGroupId, reportId } = useParams();

  const { data: peopleGroup, loading: loading1 } = usePeopleGroup(
    peopleGroupId,
    'viewQuarterlyReport',
  );

  const { nameAcrossCountries, country } = peopleGroup || {};
  const peopleGroupName = `${nameAcrossCountries} - ${country}`;

  const { report, loading: loading2 } = useReport(
    reportId,
    'viewQuarterlyReport',
  );

  return (
    <CSPage title="AIMS.org | View Quarterly People Group Report">
      <CSPageHeader
        titleComponent={null}
        title="View Quarterly Report"
        parents={[
          {
            label: 'Unreached People Groups',
            link: `/upgs`,
          },
          {
            label: peopleGroupName,
            link: `/upgs/${peopleGroupId}/reports`,
          },
        ]}
      />
      {report && peopleGroup && (
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
          <ViewPgQuarterlyReport report={report} peopleGroup={peopleGroup} />
        </div>
      )}
    </CSPage>
  );
}

export default ViewQuarterlyReport;
