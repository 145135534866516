import { EditOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Empty, List } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import { generateUuid } from '../../../shared/utils';
import QuarterlyReportListItem from './QuarterlyReportListItem';
import { useReports } from './use-reports';

function ListTrainingQuarterlyReports() {
  const {
    loading,
    data: reports,
    refetch,
  } = useReports({}, 'listTrainingQuarterlyReports');

  const [numShown, setNumShown] = useState(settings.pageSize);
  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <Link to={`/training-reports/quarterly/edit/${generateUuid()}`}>
          <Button icon={<EditOutlined />}>New Quarterly Training Report</Button>
        </Link>
        <div style={{ flex: 1 }} />
        <Button icon={<RedoOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <List
        dataSource={reports.slice(0, numShown)}
        rowKey="_id"
        renderItem={(item) => (
          <QuarterlyReportListItem report={item} locale={locale} />
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No quarterly reports"
            />
          ),
        }}
        loadMore={
          reports.length > numShown && (
            <div className="load-more">
              <Button onClick={() => setNumShown(numShown + settings.pageSize)}>
                Load More
              </Button>
            </div>
          )
        }
      />
    </>
  );
}

export default ListTrainingQuarterlyReports;
