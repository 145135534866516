import React, { useEffect, useState } from 'react';

function ImageWithDefault({ imageUrl, fallbackUrl, alt, style }) {
  const [src, setSrc] = useState(imageUrl);

  useEffect(() => {
    if (imageUrl) {
      setSrc(imageUrl);
    } else {
      setSrc(fallbackUrl);
    }
  }, [imageUrl, fallbackUrl]);
  const onError = () => {
    setSrc(fallbackUrl);
  };
  return <img src={src} alt={alt} style={style} onError={onError} />;
}

export default ImageWithDefault;
