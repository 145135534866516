import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Typography } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import settings from '../../../settings';
import useQueryParams from '../../../shared/use-query-params';
import useResponsiveMode from '../../../shared/use-reponsive-mode';
import CatalogSteps from './CatalogSteps';

const { Text, Title, Paragraph } = Typography;

function AdoptAUpgAdoptToo({ pgId, step, title }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const responsive = useResponsiveMode();

  const queryParams = useQueryParams();
  const history = useHistory();

  const handleAdopt = useCallback(
    (values) => {
      const link = `/partner/adopt-a-upg/${pgId}/redirect?${queryString.stringify(
        {
          ...values,
          ...queryParams,
        },
      )}`;
      history.push(link);
    },
    [pgId, history, queryParams],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <div>
      <CatalogSteps step={step} title={title} />
      <Title level={2} style={{ textAlign: 'center' }}>
        Thank you for committing to give!
      </Title>
      <Paragraph
        style={{
          marginTop: '1.2em',
          fontSize: 16,
          textAlign: 'center',
          letterSpacing: 1.25,
        }}
      >
        Will you allow God to deepen your heart connection to this Unreached
        People Group by committing to pray for them regularly? AIMS calls
        this...
      </Paragraph>
      <Paragraph
        style={{
          fontSize: 16,
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: 1.25,
        }}
      >
        <Text style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
          Adopting
        </Text>{' '}
        your Unreached People Group
      </Paragraph>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleAdopt}
        style={{
          maxWidth: 600,
          marginRight: 'auto',
          marginLeft: 'auto',
          textAlign: 'center',
        }}
      >
        <Form.Item
          name="commitToAdopt"
          initialValue={true}
          valuePropName="checked"
        >
          <Checkbox
            disabled={loading}
            style={{ fontSize: 16, letterSpacing: 1.25 }}
          >
            {`Yes, I will ADOPT my Unreached People Group.`}
          </Checkbox>
        </Form.Item>
        <Divider />
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link
              to={`/partner/adopt-a-upg/${pgId}`}
              style={{ marginLeft: -12 }}
            >
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>
            <Button
              type="primary"
              htmlType="submit"
              icon={<ArrowRightOutlined />}
            >
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
      <style jsx>{`
        .adoption-levels {
          display: flex;
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
          min-width: 600px;
          padding-left: 16px;
          padding-right: 16px;
        }
        .adoption-levels-item {
          flex: 1;
        }
        .adoption-levels-item:not(:last-child) {
          margin-right: 32px;
        }
        .pg-card {
          // border-radius: 8px;
          display: flex;
          padding: 24px;
          margin-bottom: 24px;
          background: white;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 3px 0 rgb(0 0 0 / 60%);
        }
        .pg-photo {
          margin-right: 24px;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .pg-card {
            flex-direction: column;
          }
          .right {
            text-align: center;
          }
          .pg-photo {
            margin-bottom: 16px;
            margin-right: 0px;
          }
        }
      `}</style>
    </div>
  );
}

export default AdoptAUpgAdoptToo;
