import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Empty,
  List,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import settings from '../../../../settings';
import { getFileIcon } from '@aims/shared/shared/icons';
import { titleCase } from '../../../../shared/utils';
import useResponsiveMode from '../../../../shared/use-reponsive-mode';

const { Text, Title } = Typography;

const FileCard = ({ file }) => {
  const [toggleDesc, setToggle] = useState(false);

  const icon = useMemo(() => {
    if (file.file) {
      return getFileIcon(file.file.filename, { fontSize: 18 });
    }
  }, [file]);

  const handleDownload = () => {
    if (file.file) {
      const link = document.createElement('a');
      link.download = file.file.filename;
      link.href = file.file.url;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <List.Item>
      <Card bodyStyle={{ padding: 16 }}>
        <div className="fileInfo">
          <div>
            <div style={{ marginBottom: 4 }}>
              <Text style={{ color: settings.colors.textGray }}>
                {titleCase(file.language)}
              </Text>
            </div>
            <Space>
              {icon}
              <Text
                style={{ color: settings.colors.primary, fontWeight: 'bold' }}
              >
                {file.fileName}
              </Text>
            </Space>
          </div>
          <div className="actions">
            <Tooltip>
              <Button
                style={{ border: 'none' }}
                onClick={handleDownload}
                icon={<DownloadOutlined />}
              ></Button>
            </Tooltip>
          </div>
        </div>
        {!toggleDesc && file.desc && file.desc.length > 50 ? (
          <Text>
            {ellipsis(file.desc, 50)}{' '}
            <span
              className="toggle"
              onClick={() => {
                setToggle(!toggleDesc);
              }}
            >
              Read more
            </span>
          </Text>
        ) : (
          <Text>{file.desc}</Text>
        )}
        {toggleDesc && (
          <Text>
            {file.desc}{' '}
            <span
              className="toggle"
              onClick={() => {
                setToggle(!toggleDesc);
              }}
            >
              Read Less
            </span>
          </Text>
        )}

        <style jsx>{`
          .fileInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
          }
          .toggle {
            cursor: pointer;
            font-weight: 600;
          }
        `}</style>
      </Card>
    </List.Item>
  );
};

const ModuleItem = ({ module, files, loading }) => {
  const moduleFiles = useMemo(() => {
    return files.filter((f) => {
      if (f.moduleId === module._id) return f;
    });
  }, [files, module]);

  const { _id } = useParams();

  const responsiveMode = useResponsiveMode();

  return (
    <div>
      <Divider>
        <Title
          style={{
            whiteSpace: responsiveMode === 'mobile' ? 'normal' : undefined,
          }}
          level={3}
        >
          Module: {module.name}
        </Title>
      </Divider>
      {moduleFiles && (
        <List
          dataSource={moduleFiles}
          renderItem={(item) => <FileCard file={item} />}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Files"
              />
            ),
          }}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
        />
      )}
      <style jsx>{`
        .top {
          display: flex;
          justify-content: space-between;
        }
      `}</style>
    </div>
  );
};

export default ModuleItem;
