import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Form, Input, List, Skeleton } from 'antd';
import React, { useCallback } from 'react';
import settings from '../../../settings';
import CourseCard from './CourseCard';
import useTrainingCourses from './use-training-courses';

function ListCourses() {
  const {
    loading,
    data: courses,
    refetch,
    search,
  } = useTrainingCourses(settings.pageSize, {});

  const [form] = Form.useForm();
  const onSearchChange = useCallback(
    (changed) => {
      if (changed && changed.search !== undefined) {
        search(changed.search);
      }
    },
    [search],
  );

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onSearchChange}
        >
          <Form.Item name="search">
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        </Form>
        <div style={{ flex: 1 }} />
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      {loading && !courses.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <List
          className="courseList"
          dataSource={courses}
          grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
          rowKey="_id"
          pagination={
            courses.length > settings.pageSize
              ? {
                  defaultCurrent: 1,
                  pageSize: settings.pageSize,
                  total: courses.length,
                }
              : false
          }
          renderItem={(course) => <CourseCard course={course} />}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No courses"
              />
            ),
          }}
        />
      )}
    </div>
  );
}

export default ListCourses;
