import PhotoCircle from '@aims/shared/components/PhotoCircle';
import sharedSettings from '@aims/shared/sharedSettings';
import { Skeleton, Typography } from 'antd';
import React from 'react';
import settings from '../../../../settings';

const { Title, Text } = Typography;

function TrainingCourseTitle({ course }) {
  return (
    <div style={{ marginBottom: 24 }}>
      {course ? (
        <div className="title">
          <PhotoCircle
            photoUrl={course.primaryThumbnailUrl}
            styles={{
              container: { height: 160, width: 160, marginRight: 32 },
              placeholder: {
                backgroundColor: sharedSettings.colors.primary,
                fontSize: 24,
              },
            }}
            name={course.courseName}
          />
          <div className="right">
            <Text>Training Course</Text>
            <Title
              style={{
                marginBottom: 0,
              }}
            >
              {course.courseName}
            </Title>
          </div>
        </div>
      ) : (
        <Skeleton avatar active />
      )}
      <style jsx>{`
        .title {
          display: flex;
          flex-wrap: wrap;
        }
        .right {
          flex: 1;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .right {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

export default TrainingCourseTitle;
