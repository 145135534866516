import React from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import AllProjectsList from './AllProjectsList';

function AllHolisticProjectsPage() {
  return (
    <>
      <CSPage title="AIMS.org | Holistic Projects">
        <CSPageHeader title="Holistic Projects" />
        <AllProjectsList />
      </CSPage>
    </>
  );
}

export default AllHolisticProjectsPage;
