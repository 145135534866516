import { Button, Empty, List, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import settings from '../../settings';
import ViewPhotoModal from './ViewPhotoModal';
import useHolisticProjectPhotos from './use-holistic-project-photos';

function PhotoCard({ photo, setShowViewPhotoModel }) {
  return (
    <div>
      <Button
        onClick={() => setShowViewPhotoModel(photo)}
        style={{ padding: 0, height: 'unset', width: '100%' }}
      >
        <div className="container">
          <div className="card-default">
            <div className="card-default-letter">
              {photo.name && photo.name[0].toUpperCase()}
            </div>
          </div>
          <div
            className="card-image"
            style={{ backgroundImage: `url("${photo.photoUrl}")` }}
          />
        </div>
      </Button>
      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          position: relative;
        }
        .card-default {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          background-color: ${settings.colors.darkGray};
          display: flex;
          justify-content: center;
        }
        .card-default-letter {
          height: 100%;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          color: white;
        }
        .card-image {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
      `}</style>
    </div>
  );
}

function ProjectPhotos({ project }) {
  const [showViewPhotoModel, setShowViewPhotoModel] = useState(null);
  const { _id, tab } = useParams();
  const { loading, data: rawPhotos } = useHolisticProjectPhotos(
    _id,
    'projectPhotos',
  );

  const photos = useMemo(() => {
    if (rawPhotos) {
      const _photos = [];

      rawPhotos.forEach((p) => {
        let photoUrl = (p.thumbnail && p.thumbnail.url) || p.url;
        _photos.push({
          _id: p._id,
          isPrimary: project.primaryPhotoId === p._id,
          photoUrl,
          originalUrl: p.url,
          name: project.projectName,
        });
      });

      return _photos;
    }
    return [];
  }, [project, rawPhotos]);

  return (
    <div className="container">
      <List
        dataSource={photos}
        renderItem={(item) => (
          <List.Item>
            <PhotoCard
              photo={item}
              loading={loading}
              setShowViewPhotoModel={setShowViewPhotoModel}
            />
          </List.Item>
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Photos"
            />
          ),
        }}
        grid={{ column: 4, gutter: 16 }}
      />
      <ViewPhotoModal
        visible={showViewPhotoModel}
        setVisible={setShowViewPhotoModel}
      />
    </div>
  );
}

export default ProjectPhotos;
