import PhotoCircle from '@aims/shared/components/PhotoCircle';
import {
  BibleStatusLabels,
  PeopleGroupRoles,
} from '@aims/shared/people-groups/constants';
import { List, Typography } from 'antd';
import humanize from 'humanize-number';
import React, { useMemo } from 'react';
import settings from '../../settings';

const { Text, Title } = Typography;

function PeopleGroupTitle({ peopleGroup, name, roles }) {
  const generalStats = useMemo(() => {
    if (peopleGroup) {
      return [
        {
          label: 'Location',
          value: `${peopleGroup.continent} / ${peopleGroup.region} / ${peopleGroup.country}`,
        },
        {
          label: 'View on Map',
          component: (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${peopleGroup.latlon}`}
              // href={`http://maps.google.com/maps?t=k&q=loc:${peopleGroup.latlon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View on Map
            </a>
          ),
        },
        {
          label: 'Population',
          value: humanize(peopleGroup.population),
        },
        {
          label: 'Primary Religion',
          value: peopleGroup.primaryReligion,
        },
        {
          label: 'Language',
          value: peopleGroup.primaryLanguage,
        },
        {
          label: 'JP Scale',
          value: peopleGroup.jpScale,
        },
        {
          label: 'Bible Status',
          value: BibleStatusLabels[peopleGroup.bibleStatus]
            ? BibleStatusLabels[peopleGroup.bibleStatus].label
            : 'Unknown',
        },
        {
          label: 'View More Info on This People Group',
          component: (
            <a
              href={peopleGroup.jpUrl}
              // href={`http://maps.google.com/maps?t=k&q=loc:${peopleGroup.latlon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View More Info on This People Group
            </a>
          ),
        },
      ];
    }
    return undefined;
  }, [peopleGroup]);
  return (
    <div className="title">
      <PhotoCircle
        photoUrl={peopleGroup?.primaryThumbnailUrl}
        name={name}
        styles={{
          container: { height: 200, width: 200, marginRight: 32 },
          placeholder: {
            backgroundColor: settings.colors.yellow,
            fontSize: 32,
          },
        }}
      />
      <div className="right">
        {roles?.map((role, index) => (
          <Title key={index} level={4} style={{ margin: 0 }}>
            {PeopleGroupRoles[role]}
          </Title>
        ))}
        <Title level={1} style={{ marginBottom: 0, marginTop: 0 }}>
          {name}
        </Title>
        <div style={{ marginBottom: 16 }}>
          <Text style={{ color: settings.colors.darkGray, fontSize: 18 }}>
            {peopleGroup && peopleGroup._id}
          </Text>
        </div>
        <div>
          {generalStats && (
            <List
              dataSource={generalStats}
              grid={{
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 3,
                xxl: 3,
                gutter: 8,
              }}
              rowKey="label"
              renderItem={(item) => (
                <List.Item>
                  {item.component ? (
                    item.component
                  ) : (
                    <Text>{`${item.label}: ${item.value}`}</Text>
                  )}
                </List.Item>
              )}
            />
          )}
        </div>
      </div>
      <style jsx>{`
        .title {
          display: flex;
          flex-wrap: wrap;
          padding-top: 16px;
        }
        .right {
          flex: 1;
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .title {
            padding-top: 0px;
          }
          .left {
            padding-right: 0px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 32px;
          }
          .right {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

export default PeopleGroupTitle;
