import { AdoptionLevels } from '@aims/shared/adoption/constants';
import { displayMoney } from '@aims/shared/shared/utils';
import { CheckCircleFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import MyPhotoCircle from '../../../components/MyPhotoCircle';
import settings from '../../../settings';
import useProfile from '../../../shared/use-profile';
import useQueryParams from '../../../shared/use-query-params';
import {
  completeOnlineAdoptionMutation,
  PaymentFrequencyTypes,
} from './constants';
import WeWillMatch from './WeWillMatch';

const { Title, Text, Paragraph } = Typography;

function AdoptAPgSuccess() {
  const { paymentToken } = useQueryParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [completeOnlineAdoption] = useMutation(completeOnlineAdoptionMutation);
  const dispatch = useDispatch();
  const [result, setResult] = useState();
  const [doConfetti, setDoConfetti] = useState();
  const profile = useProfile();

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      setError(null);
      try {
        if (paymentToken) {
          const _result = await completeOnlineAdoption({
            variables: {
              paymentId: paymentToken,
            },
          });
          setResult(_result.data.completeOnlineAdoption);
          setTimeout(() => {
            setDoConfetti(true);
          }, 250);
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    }
    doAsyncStuff();
  }, [profile, paymentToken, completeOnlineAdoption, dispatch]);

  const locale = useSelector((store) => store.locale, shallowEqual);

  const yourDonation = useMemo(() => {
    if (result) {
      if (result.recurringPayment) {
        const frequencyLabel =
          PaymentFrequencyTypes[result.recurringPayment.frequency] &&
          PaymentFrequencyTypes[result.recurringPayment.frequency].label;
        return `${displayMoney(
          result.recurringPayment.usdCentsAmount / 100,
        )} ${frequencyLabel} starting ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'full',
        }).format(new Date(result.recurringPayment.startDate))}`;
      } else if (result.payment) {
        return `One-time ${displayMoney(result.payment.usdCentsAmount / 100)}`;
      }
    }
  }, [result, locale]);

  const pgInfo = useMemo(() => {
    if (result && result.peopleGroup) {
      const { peopleGroup } = result;
      return {
        pgName: `${peopleGroup.nameAcrossCountries} / ${peopleGroup.region}`,
        lang: `Language: ${peopleGroup.primaryLanguage}`,
        religion: `Religion: ${peopleGroup.primaryReligion}`,
        pop: `${Math.round(peopleGroup.population).toLocaleString(
          locale,
        )} People`,
        numAdoptions: `${peopleGroup.numAdoptionsAvailable} ${
          peopleGroup.numAdoptionsAvailable > 1 ? 'Missionaries' : 'Missionary'
        } Available`,
        suggestedLevel:
          peopleGroup.suggestedAdoptionLevel &&
          AdoptionLevels[peopleGroup.suggestedAdoptionLevel].label,
      };
    }
    return undefined;
  }, [result, locale]);

  return (
    <CSPage title="AIMS.org | Sponsor a Native Missionary Success">
      <CSPageHeader
        title="Success"
        parents={[
          { label: 'Partner', link: `/partner` },
          {
            label: 'Sponsor a Native Missionary',
            link: `/partner/adopt-a-upg`,
          },
        ]}
      />
      {loading && (
        <div>
          <Text>{`We're processing your donation.  One moment ...`}</Text>
        </div>
      )}
      {error && (
        <div style={{ textAlign: 'center' }}>
          <Title type="danger" level={4}>
            Error
          </Title>
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Confetti
          active={doConfetti}
          config={{
            angle: 90,
            spread: 360,
            startVelocity: 40,
            elementCount: 70,
            dragFriction: 0.12,
            duration: 3000,
            stagger: 3,
            width: '10px',
            height: '10px',
            perspective: '500px',
            colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
          }}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          height: 1000,
          margin: 'auto',
          maxWidth: 1000,
        }}
      >
        {!loading && !error && (
          <>
            <Title style={{ marginBottom: 32 }}>
              Thank you for partnering with us!
            </Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  background: 'white',
                  borderRadius: 16,
                  boxShadow: `0px 0px 8px ${settings.colors.borderGray}`,
                  flex: 1,
                  marginRight: 16,
                  marginLeft: 16,
                }}
                className="pg-card"
              >
                {pgInfo ? (
                  <>
                    <div className="pg-photo">
                      <MyPhotoCircle
                        imageUrl={
                          result.peopleGroup &&
                          result.peopleGroup.primaryThumbnailUrl
                        }
                        initials={pgInfo && pgInfo.pgName}
                        diameter={160}
                        color={settings.colors.yellow}
                        textStyle={{ color: 'white' }}
                      />
                    </div>
                    <div className="right">
                      <Text
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          color: settings.colors.primary,
                          whiteSpace: 'normal',
                        }}
                      >
                        {pgInfo && pgInfo.pgName}
                      </Text>
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text
                        style={{
                          fontSize: 18,
                          color: settings.colors.textGray,
                        }}
                      >
                        {AdoptionLevels[result.adoption.level] &&
                          AdoptionLevels[result.adoption.level].label}
                      </Text>
                    </div>
                    <div className="right">
                      <Text
                        style={{ width: 224 }}
                        ellipsis={{ tooltip: pgInfo.pop }}
                      >
                        {pgInfo.pop}
                      </Text>
                    </div>
                    <div className="right">
                      <Text
                        style={{ width: 224 }}
                        ellipsis={{ tooltip: pgInfo.lang }}
                      >
                        {pgInfo.lang}
                      </Text>
                    </div>
                    <div className="right">
                      <Text
                        style={{ width: 224 }}
                        ellipsis={{ tooltip: pgInfo.religion }}
                      >
                        {pgInfo.religion}
                      </Text>
                    </div>
                  </>
                ) : (
                  <WeWillMatch />
                )}
              </div>
              <div
                style={{
                  flex: 2,
                  marginRight: 16,
                  marginLeft: 16,
                  textAlign: 'left',
                }}
              >
                <div
                  style={{
                    background: 'white',
                    borderRadius: 16,
                    boxShadow: `0px 0px 8px ${settings.colors.borderGray}`,
                    display: 'block',
                  }}
                  className="pg-card"
                >
                  <Title level={2} style={{ marginBottom: 4 }}>
                    <CheckCircleFilled
                      style={{
                        marginRight: 16,
                        color: settings.colors.success,
                        fontSize: 36,
                      }}
                    />
                    Donation Successful
                  </Title>
                  <Paragraph style={{ fontSize: 14 }}>
                    Thank you. Your donation was successful and is being
                    processed.
                  </Paragraph>
                  <Title level={3} style={{ marginBottom: 4 }}>
                    Your Gift
                  </Title>
                  <Paragraph style={{ fontSize: 14 }}>{yourDonation}</Paragraph>
                </div>
                <Title
                  level={3}
                  style={{ marginTop: 32, marginBottom: 4 }}
                >{`What's next?`}</Title>
                <Paragraph style={{ fontSize: 14 }}>
                  An email containing more details about your Unreached People
                  Group will be sent shortly.
                </Paragraph>
                <Paragraph style={{ fontSize: 14, marginBottom: 32 }}>
                  Through your AIMS.ORG account, you have gained access to an
                  online dashboard where you can view quarterly reports and
                  prayer requests from the native missionary.
                </Paragraph>
                <div>
                  <Link
                    to={
                      result && result.userInvite
                        ? `/sign-up?i=${result.userInvite._id}`
                        : '/upgs'
                    }
                  >
                    <Button
                      style={{
                        width: '100%',
                        fontSize: 20,
                        textTransform: 'uppercase',
                        padding: 0,
                      }}
                      type="primary"
                    >
                      Continue to My Dashboard
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div style={{ height: 24 }} />
          </>
        )}
      </div>
      <style jsx>{`
        .pg-photo {
          margin-bottom: 16px;
        }
        .pg-card {
          padding: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .right {
          text-align: center;
          font-size: 14px;
          margin-bottom: 4px;
        }
      `}</style>
    </CSPage>
  );
}

export default AdoptAPgSuccess;
