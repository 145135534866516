import { Space, Typography } from 'antd';
import React from 'react';
import MyPhotoCircle from '../../../components/MyPhotoCircle';
import pgPhoto1 from '../../../assets/peopleGroup7.jpg';
import pgPhoto2 from '../../../assets/peopleGroup6.jpg';
import pgPhoto3 from '../../../assets/peopleGroup3.jpg';
import pgPhoto4 from '../../../assets/peopleGroup4.jpg';
import pgPhoto5 from '../../../assets/peopleGroup1.jpg';
import settings from '../../../settings';

const { Title, Paragraph } = Typography;

function WeWillMatch() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Space style={{ marginBottom: 12 }}>
        <MyPhotoCircle
          imageUrl={pgPhoto1}
          initials="PG"
          diameter={48}
          color={settings.colors.yellow}
          textStyle={{ color: 'white' }}
        />
        <MyPhotoCircle
          imageUrl={pgPhoto2}
          initials="PG"
          diameter={48}
          color={settings.colors.yellow}
          textStyle={{ color: 'white' }}
        />
        <MyPhotoCircle
          imageUrl={pgPhoto3}
          initials="PG"
          diameter={48}
          color={settings.colors.yellow}
          textStyle={{ color: 'white' }}
        />
        <MyPhotoCircle
          imageUrl={pgPhoto4}
          initials="PG"
          diameter={48}
          color={settings.colors.yellow}
          textStyle={{ color: 'white' }}
        />
        <MyPhotoCircle
          imageUrl={pgPhoto5}
          initials="PG"
          diameter={48}
          color={settings.colors.yellow}
          textStyle={{ color: 'white' }}
        />
      </Space>
      <Paragraph
        style={{ fontSize: 14, maxWidth: 400 }}
      >{`AIMS will designate your giving towards an Unreached People Group with the greatest strategic impact.`}</Paragraph>
    </div>
  );
}

export default WeWillMatch;
