import ImageWithDefault from '@aims/shared/components/ImageWithDefault';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { useApolloClient } from '@apollo/client';
import { Button, Modal, Progress, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import settings from '../../../../settings';
import useDefaultProjectPhoto from '../../../holistic-projects/use-default-project-photo';
import { publicHolisticProjectQuery } from '../constants';

const { Title, Text, Paragraph } = Typography;

function SelectAProjectModal({ visible, setVisible }) {
  const { project } = visible || {};
  const projectType =
    project &&
    HolisticProjectTypes[project.typeOfProject] &&
    HolisticProjectTypes[project.typeOfProject].label;
  const defaultPhoto = useDefaultProjectPhoto(project);

  const apolloClient = useApolloClient();
  const [projectDetails, setProjectDetails] = useState();
  useEffect(() => {
    async function doAsyncStuff(p) {
      try {
        const result = await apolloClient.query({
          query: publicHolisticProjectQuery,
          variables: {
            _id: p._id,
          },
          fetchPolicy: 'cache-first',
        });
        if (!(result && result.data && result.data.holisticProjectForPublic)) {
          throw new Error('Bad publicHolisticProjectQuery result');
        }
        setProjectDetails(result.data.holisticProjectForPublic);
      } catch (err) {
        console.error(err);
      }
    }

    if (project) {
      doAsyncStuff(project);
    }
  }, [project, apolloClient]);

  const images = useMemo(() => {
    if (
      projectDetails &&
      projectDetails.primaryPhoto &&
      projectDetails.primaryPhoto.images
    ) {
      const imgLg = projectDetails.primaryPhoto.images.find(
        (p) => p.sizeCode == 'LG',
      );
      const imgMd = projectDetails.primaryPhoto.images.find(
        (p) => p.sizeCode == 'MD',
      );
      const imgSm = projectDetails.primaryPhoto.images.find(
        (p) => p.sizeCode == 'SM',
      );
      const imgThumb = projectDetails.primaryPhoto.images.find(
        (p) => p.sizeCode == 'THUMB',
      );

      return {
        bannerImgLg:
          imgLg?.url || projectDetails.primaryPhoto.url || imgThumb?.url,
        bannerImgMd:
          imgMd?.url || projectDetails.primaryPhoto.url || imgThumb?.url,
        bannerImgSm:
          imgSm?.url || projectDetails.primaryPhoto.url || imgThumb?.url,
      };
    }
    return undefined;
  }, [projectDetails]);
  let imageUrl = project && project.primaryThumbnailUrl;
  if (images && images.bannerImgMd) {
    imageUrl = images.bannerImgMd;
  }

  return (
    <Modal
      header={null}
      footer={null}
      visible={!!visible}
      closable={false}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={() => {
        setVisible(undefined);
      }}
      styles={{ content: { padding: 0 } }}
    >
      {project && (
        <>
          <ImageWithDefault
            imageUrl={imageUrl}
            fallbackUrl={defaultPhoto}
            alt={project.projectName}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: 300,
              aspectRatio: '1/1',
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
            }}
          />
          <div style={{ padding: 32, textAlign: 'center' }}>
            <Title level={3} style={{ marginBottom: 0 }}>
              {project.projectName}
            </Title>
            {projectType && (
              <div className="right">
                <Text
                  style={{ width: 224 }}
                  ellipsis={{ tooltip: projectType }}
                >
                  {projectType}
                </Text>
              </div>
            )}
            <div style={{ marginBottom: 16, marginTop: 16 }}>
              <div>
                <Text style={{ fontSize: 22, fontWeight: 800 }}>
                  {displayMoney(getNumberValue(project.fundingBalance) / 10000)}
                </Text>{' '}
                <Text style={{ fontSize: 16 }}>raised of</Text>{' '}
                <Text style={{ fontSize: 18, fontWeight: 600 }}>
                  {displayMoney(getNumberValue(project.fundingTarget) / 10000)}
                </Text>{' '}
                <Text style={{ fontSize: 16 }}>goal</Text>{' '}
              </div>
              <Progress
                percent={Math.round(project.fundingPercent / 100)}
                strokeColor={settings.colors.holisticGreen}
                strokeLinecap="butt"
                trailColor="white"
                strokeWidth={16}
                showInfo={false}
              />
            </div>
            {project.region && (
              <div className="right">
                <Text
                  style={{
                    color: settings.colors.holisticText,
                    whiteSpace: 'normal',
                  }}
                >
                  {project.region}
                </Text>
              </div>
            )}
            {project.numSupported && (
              <div className="right" style={{ marginBottom: 8 }}>
                <Text>
                  {`${project.numSupported} Supported ${
                    project.numSupported > 1 ? 'Missionaries' : 'Missionary'
                  }`}
                </Text>
              </div>
            )}
            <Paragraph>{project.desc}</Paragraph>
            <div
              style={{
                marginTop: 32,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                type="dashed"
                style={{ marginRight: 16 }}
                onClick={() => setVisible(undefined)}
              >
                Close
              </Button>
              <Link
                to={`/partner/sponsor-a-project/${project._id}${location.search}`}
              >
                <Button type="primary">Select</Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

export default SelectAProjectModal;
