import { Layout } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import settings from '../settings';

function CSPage({ children, containerStyle, title = 'AIMS.org' }) {
  return (
    <Layout.Content className="page">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container" style={containerStyle}>
        {children}
      </div>
      <style jsx>{`
        .container {
          // max-width: 800px;
        }
      `}</style>
      <style global jsx>{`
        .page {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: stretch;
          flex-direction: column;
          position: relative;
          // height: 100vh;
          padding-top: 16px;
          padding-bottom: 64px;
          margin-left: auto;
          margin-right: auto;
          width: 80vw;
        }
        @media screen and (max-width: ${settings.screenSizes.lg992}px) {
          .page {
            // height: calc(100vh - 72px);
            padding-top: 16px;
            padding-bottom: 64px;
            width: 90vw;
          }
        }
        @media screen and (max-width: ${settings.screenSizes.md600}px) {
          .page {
            padding-top: 16px;
            padding-bottom: 32px;
            padding-left: 16px;
            padding-right: 16px;
            width: 100vw;
          }
        }
      `}</style>
    </Layout.Content>
  );
}

export default CSPage;
